<template>
    <div class="header-height lore-page">
        <div class="lore-container padded-container">
            <div class="lore-title">
                <div>
                    Lore is about stories, the
                </div>
                <div>
                    <span @compositionend="setContextWidth()" class="lore-title-span">
                        purest form of exploration
                    </span>
                </div>
            </div>
            <div class="lore-context d-flex">
                <div class="lore-context-title">
                    Context
                </div>
                <div class="lore-context-text">
                    Lore is a different approach to industrial design. By placing storytelling at the forefront, it
                    involves
                    exploring possibilities through plot-lines and bringing them to life through design skills. By
                    prioritising story and creativity, lore offers a fresh perspective for solving design problems
                    by
                    using
                    a narrative-driven approach
                </div>
            </div>
            <div class="attempt-cont d-flex" :class="{ addHeight: showAttemptInfo }">
                <div class="attempt-stack zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont spc-margin" v-for="(item, index) in carui_link" :key="index">
                            <img @click="nextImg(true, 'carui')" class="carui-img click-cursor-dark"
                                :src="require(`../assets/lore/${item.link}.png`)" :alt="item.description">
                        </div>
                    </div>
                </div>
                <div class="attempt-content">
                    <div class="attempt-title section-title">
                        <span class="attempt-title-span">an attempt to solve for LV4 autonomous car take-over moment.</span>
                    </div>
                    <div @click="showAttemptInfo = !showAttemptInfo; setAttemptWidth()"
                        class="attempt-readmore readmore relative">
                        <span v-if=" showAttemptInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showAttemptInfo } " class="add-sign attempt-add absolute"
                            src="../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showAttemptInfo " class="attempt-additional-info additional-info">
                            <div class="para-spacer">
                                In our Interaction Design Foundations class, we collaborated with industry experts,
                                including Daniel Naccumalli (ex-Nio), Zane Amiralis (Mercedes Adv Concepts), and Rachad
                                Yousef (Brightdrop), to investigate LV4 autonomous car interaction.
                            </div>
                            <div class="m-5-top">
                                Our group primarily focused on the critical autonomous-to-manual switch interaction. In our
                                attempt to solve this challenge, we designed an immersive, tactile experience that uses the
                                car's speakers, dashboard, and seat haptics to alert and engage the user for a safe handoff
                                and vice versa.
                            </div>
                            <div class="m-5-top">
                                Moreover, we explored how the immersive UX system would work during unique edge cases, such
                                as the car's behavior when internet connectivity is lost or when encountering rogue vehicles
                                on the road during autonomous mode.
                            </div>
                        </div>
                    </Transition>
                    <div class="attempt-details content-details">
                        <div class="attempt-item content-item">
                            Interaction Design
                        </div>
                        <div class="attempt-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="attempt-item content-item m-2-top">
                            Fall 2022
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mid-journey" :class=" { addHeight: showMidInfo } ">
                <div class="mid-j-content">
                    <div class="mid-j-title section-title">
                        <span class="mid-title-span">mid-journey AI prompts experiments.</span>
                    </div>
                    <div @click=" showMidInfo = !showMidInfo; setMidWidth() " class="mid-j-readmore readmore relative">
                        <span v-if=" showMidInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showMidInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showMidInfo " class="additional-info mid-additional-info">
                            <div class="para-spacer">
                                Recently, I took some free time to explore the prompt syntax of AI systems by dabbling
                                between Dalle-E and MidJourney to assess my prompt skills. Seeing patterns and models emerge
                                in how these AI models work was fascinating.
                                <div class="m-5-top">
                                    As we transition towards a world where AI collaboration is the norm, investing in our
                                    communication and linguistic skills is becoming increasingly critical. Unlike humans,
                                    each
                                    model has its unique prompt processing context; this means that it's essential to get
                                    our
                                    point across with fewer words and in a way tailored to the specific model's processing
                                    capabilities.
                                </div>
                                <div class="m-5-top">
                                    I'm excited to continue exploring the potential of prompt syntax and its role in AI
                                    collaboration..
                                </div>
                                <div class="m-5-top">
                                    prompts used for this project :
                                </div>
                                <div class="m-5-top">
                                    1. synthesizer, with a screen, minimalistic design, no
                                    buttons, designed by Marc newson , 3 point lighting, front view, product photography,
                                    white
                                    background
                                </div>
                                <div class="m-5-top">
                                    2. HTC vive inspired Augmented reality headset , rounded edges, minimalistic design,
                                    Scandinavian style, off white, 3 point lighting, front view, product photography, white
                                    background
                                </div>
                                <div class="m-5-top">
                                    3-6. Squircle shaped drone, minimalistic design, Scandinavian style, off white, 3 point
                                    lighting, front view, product photography, white background
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <div class="mid-j-details content-details">
                        <div class="mid-j-item content-item">
                            AI prompting
                        </div>
                        <div class="mid-j-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="mid-j-item content-item m-2-top">
                            Fall 2023
                        </div>
                    </div>
                </div>
                <div class="mid-j-img zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont spc-margin" v-for="(  item, index  ) in   midjourLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'midjour') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="midjour-img click-cursor-dark" :src=" require(`../assets/lore/${item.link}.png`) "
                                :alt=" item.description ">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 
            <div class="m-8-top m-8-bottom p-8-top p-8-bottom">

            </div> -->
        </div>
        <div class="indian-summer">
            <div class="three-img-cont relative">
                <img class="indian-img parallax-in" src="@/assets/lore/indian_summer.png" alt="indian summer">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 1-3:
                </div>
                <div class="m-1-top">
                    Making process of Indian summer AF1 shoes
                </div>
                <!-- <div class="text-center three-4-cont relative parallax-in summer-shoe">
                    <img class="three-4 text-center relative" src="@/assets/lore/AJ_SS_4.png" alt="indian_summer">
                    <div class="text-left summer-shoe-caption content-details absolute">
                        <div class="m-2-top">
                            Fig 4:
                        </div>
                        <div class="m-1-top">
                            Indian summer AF1 shoes @ shoe surgeon workshop
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="summer-shoe-container relative">
                <div class="summer-shoe-imgs text-center parallax-in">
                    <img class="summer-shoe-img" src="@/assets/lore/AJ_SS_4.png" alt="EEG">
                    <div class="summer-shoe-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 4:
                        </div>
                        <div class="m-2-top">
                            Indian summer AF1 shoes @ shoe surgeon workshop
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="phone-container relative">
                <div class="phone-imgs text-center parallax-in">
                    <div v-show=" appleDevice ">
                        <img class="phone-img" src="@/assets/lore/AI_Journal_1.png" alt="AI Journel">
                        <img class="phone-img" src="@/assets/lore/AI_Journal_2.png" alt="AI Journel">
                        <img class="phone-img" src="@/assets/lore/AI_Journal_3.png" alt="AI Journel">
                    </div>
                    <div v-show=" !appleDevice ">
                        <video controls="false" autoplay muted loop webkit-playsinline playsinline class="phone">
                            <source src="../assets/lore/AI_journal_A.mp4" type="video/mp4" />
                        </video>
                        <video controls="false" autoplay muted loop webkit-playsinline playsinline class="phone phone2">
                            <source src="../assets/lore/AI_journal_B.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div class="phone-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 5:
                        </div>
                        <div class="m-2-top">
                            A NPL (natural processing language) powered journal
                        </div>
                    </div>
                </div>
            </div>
            <div class="hardware-container relative">
                <div class="hardware-imgs text-center parallax-in">
                    <img class="hardware-img" src="@/assets/lore/AI_hardware.png" alt="AI Hardware">
                    <div class="hardware-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 6-7:
                        </div>
                        <div class="m-2-top">
                            A NPL (natural processing language) powered hardware
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex domestic-floor" :class=" { addHeight: showDomesticFloorInfo } ">
                <div class="domestic-floor-img zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont spc-margin" v-for="(  item, index  ) in   domesticLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'domestic') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="domestic-img click-cursor-dark"
                                :src=" require(`../assets/lore/${item.link}.png`) " :alt=" item.description ">
                        </div>
                    </div>
                </div>
                <div class="domestic-floor-content">
                    <div class="domestic-floor-title section-title">
                        <div>
                            <span class="domestic-title-span">Bauhaus inspired domestic</span>
                        </div>
                        <div>
                            floor lamp
                        </div>
                    </div>
                    <div @click=" showDomesticFloorInfo = !showDomesticFloorInfo; setDomesticWidth() "
                        class="domestic-floor-readmore readmore relative">
                        <span v-if=" showDomesticFloorInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showDomesticFloorInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showDomesticFloorInfo " class="additional-info domestic-additional-info">
                            <div class="para-spacer">
                                I have always had a strong affinity towards primitives, and I recently had the opportunity
                                to explore this interest further. Specifically, I attempted to design a floor lamp that
                                established a dynamic and negative space between these primitive shapes.
                                <div class="m-5-top">
                                    The lamp's design utilizes the inherent qualities of primitives to create a striking
                                    aesthetic that draws the viewer's eye. By leveraging the negative space between these
                                    shapes, the lamp achieves an organic and intentional balance.
                                </div>
                                <div class="m-5-top">
                                    I am thrilled with the outcome of this project and am eager to continue exploring the
                                    creative possibilities of primitive shapes.
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <div class="domestic-floor-details content-details">
                        <div class="domestic-floor-item content-item">
                            Industrial Design
                        </div>
                        <div class="domestic-floor-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="domestic-floor-item content-item m-2-top">
                            Fall 2018
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fashion-cont">
            <div class="fasion-img-cont relative">
                <img class="fashion-img parallax-in" src="@/assets/lore/fashion.png" alt="fashion">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 8-11:
                </div>
                <div class="m-1-top">
                    Fashion illustrations exploring Telugu type, patterns and and street wear\
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="vinyl-container relative">
                <div class="vinyl-imgs text-center parallax-in">
                    <img class="vinyl-img" src="@/assets/lore/vinyl.png" alt="AI vinyl">
                    <div class="vinyl-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 12-13:
                        </div>
                        <div class="m-2-top">
                            teenage engineering inspired vinyl record player
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="audio-cont">
            <div class="audio-img-cont relative">
                <img class="audio-img parallax-in" src="@/assets/lore/audio.png" alt="audio">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 14-15:
                </div>
                <div class="m-1-top">
                    Unselected client work for a audio conglomerate
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="d-flex flex-col router-floor" :class=" { addHeight: showRouterFloorInfo } ">
                <div class="router-floor-img zoom-effect">
                    <div class="img-stack-container relative zoom-effect">
                        <div class="absolute abs-cont spc-margin" v-for="(  item, index  ) in   routerLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'router') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="router-img click-cursor-dark" :src=" require(`../assets/lore/${item.link}.png`) "
                                :alt=" item.description ">
                        </div>
                    </div>
                </div>
                <div class="router-floor-content">
                    <div class="router-floor-title section-title">
                        <span class="router-title-span">Intelligent internet router driven by digital interaction.</span>
                    </div>
                    <div @click=" showRouterFloorInfo = !showRouterFloorInfo "
                        class="router-floor-readmore readmore relative">
                        <span v-if=" showRouterFloorInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showRouterFloorInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showRouterFloorInfo " class="additional-info">
                            <div class="para-spacer">
                                In our Interaction Design Foundations class, we were tasked with designing an object around
                                the theme of "feedback." As a designer, I was drawn to creating an internet router that
                                could provide users valuable feedback on their internet usage.
                                <div class="m-5-top">
                                    Inspired by the principles of calm technology outlined in the book "Beyond Calculation:
                                    Next
                                    Fifty Years in Computing," I designed a router that tracks your internet activity and
                                    provides feedback on your cognitive load. With this router, users can better understand
                                    how
                                    internet usage affects their mental state and overall well-being.
                                </div>
                                <div class="m-5-top">
                                    One of the unique features of the router is its ability to offer historical feedback
                                    about
                                    the data value generated from a user's browsing activity. This feature can help users
                                    become
                                    more mindful of their online habits and make more informed decisions about their
                                    internet
                                    use.
                                </div>
                                <div class="m-5-top">
                                    To ensure a seamless user experience, I incorporated a physical interaction component
                                    that
                                    supports a pay-as-you-go feature. The router's interface is intuitive and user-friendly,
                                    allowing users to manage their internet usage and receive valuable feedback quickly.
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <div class="router-floor-details content-details">
                        <div class="router-floor-item content-item">
                            Industrial Design
                        </div>
                        <div class="router-floor-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="router-floor-item content-item m-2-top">
                            Fall 2022
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="brain-cont">
            <div class="brain-img-cont relative">
                <img class="brain-img parallax-in" src="@/assets/lore/brain.png" alt="brain">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 16-17:
                </div>
                <div class="m-1-top">
                    Brain computer interface controlled nootropic pumps
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="ecg-container relative">
                <div class="ecg-imgs text-center parallax-in">
                    <img class="ecg-img" src="@/assets/lore/EEG_1.png" alt="EEG">
                    <div class="ecg-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 18-19:
                        </div>
                        <div class="m-2-top">
                            EEG head band concept
                        </div>
                    </div>
                </div>
            </div>
            <div class="news-container relative">
                <div class="news-imgs text-center parallax-in">
                    <img class="news-img" src="@/assets/lore/Redesign_Option21.png" alt="News Outlet">
                    <div class="news-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 20:
                        </div>
                        <div class="m-2-top">
                            Drudge Report news outlet website landing page redesign
                        </div>
                    </div>
                </div>
            </div>
            <div class="floor-lamp-container relative">
                <div class="floor-lamp-imgs text-center parallax-in">
                    <img class="floor-lamp-img" src="@/assets/lore/lamp_head_1.png" alt="floor lamp">
                    <div class="floor-lamp-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 21:
                        </div>
                        <div class="m-2-top">
                            Detachable floor lamp head
                        </div>
                    </div>
                </div>
            </div>
            <div class="posters-container relative">
                <div class="posters-imgs text-center parallax-in">
                    <img class="posters-img" src="@/assets/lore/posters.png" alt="posters">
                    <div class="posters-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 22:
                        </div>
                        <div class="m-2-top">
                            Posters for design talks and events
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex drone-floor" :class=" { addHeight: showDroneInfo } ">
                <div class="drone-content">
                    <div class="drone-title section-title">
                        <span class="drone-title-span">companion drone for enhanced surveillance</span>
                    </div>
                    <div @click=" showDroneInfo = !showDroneInfo; setDroneWidth() "
                        class="drone-readmore readmore relative">
                        <span v-if=" showDroneInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showDroneInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showDroneInfo " class="additional-info drone-additional-info">
                            <div class="para-spacer">
                                I developed a theoretical concept called the "Companion Drone" to give users access to
                                unseen information in their daily lives through a robot companion.
                                <div class="m-5-top">
                                    This project was completed during my college studies, where I gained experience
                                    rendering
                                    and executing CMF for devices. I built upon Andrés Belisario's previous version of the
                                    drone
                                    to refine its design and functionality.
                                </div>
                                <div class="m-5-top">
                                    The Companion Drone is a sophisticated device that integrates seamlessly into users'
                                    routines, thanks to its advanced sensors and AI capabilities. The Companion Drone
                                    provides
                                    users with valuable insights and information by gathering and analyzing data from its
                                    surroundings.
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <div class="drone-details content-details">
                        <div class="drone-item content-item">
                            Product Visualisation
                        </div>
                        <div class="drone-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="drone-item content-item m-2-top">
                            Fall 2020
                        </div>
                    </div>
                </div>
                <div class="drone-floor-img zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont" v-for="(  item, index  ) in   droneLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'drone') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="drone-img click-cursor-dark" :src=" require(`../assets/lore/${item.link}.png`) "
                                :alt=" item.description ">
                        </div>
                    </div>
                </div>
            </div>
            <div class="watch-container relative">
                <div class="watch-imgs text-center parallax-in">
                    <img class="watch-img" src="@/assets/lore/Watch_AI.png" alt="Watch">
                    <div class="watch-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 23:
                        </div>
                        <div class="m-2-top">
                            Phygital watch face variations
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-flex ssd-cont" :class=" { addHeight: showSSDInfo } ">
                <div class="ssd-cont-img zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont" v-for="(  item, index  ) in   ssdLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'ssd') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="ssd-img click-cursor-dark" :src=" require(`../assets/lore/${item.link}.png`) "
                                :alt=" item.description ">
                        </div>
                    </div>
                </div>
                <div class="ssd-content">
                    <div class="ssd-title section-title">
                        <span class="ssd-title-span">swappable SSD tiles for easy sharing.</span>
                    </div>
                    <div @click=" showSSDInfo = !showSSDInfo; setSsdWidth() " class="ssd-readmore readmore relative">
                        <span v-if=" showSSDInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showSSDInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showSSDInfo " class="additional-info ssd-additional-info">
                            <div class="para-spacer">
                                Seagate Swap, is a fun and modern twist on an old childhood tradition. Instead of pen
                                drives, we now use modular SSD tiles that are sharable and connect to your computer through
                                a larger base. It's easy to use and adds a touch of nostalgia to your tech experience.
                            </div>
                        </div>
                    </Transition>
                    <div class="ssd-details content-details">
                        <div class="ssd-item content-item">
                            Product Visualisation
                        </div>
                        <div class="ssd-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="ssd-item content-item m-2-top">
                            Fall 2020
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex display-cont" :class=" { addHeight: showDisplayInfo } ">
                <div class="display-content">
                    <div class="display-title section-title">
                        <span class="display-title-span">professional display form exploration</span>
                    </div>
                    <div @click=" showDisplayInfo = !showDisplayInfo; setDisplayWidth() "
                        class="display-readmore readmore relative">
                        <span v-if=" showDisplayInfo ">close</span><span v-else>read more</span><img
                            :class=" { openInfo: showDisplayInfo } " class="add-sign absolute" src="../assets/add.svg"
                            alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show=" showDisplayInfo " class="additional-info display-additional-info">
                            <div class="para-spacer">
                                Just a cool sketch I wanted to explore with CAD na d keyshot, nothing much here :)
                            </div>
                        </div>
                    </Transition>
                    <div class="display-details content-details">
                        <div class="display-item content-item">
                            Product Visualisation
                        </div>
                        <div class="display-item content-item m-2-top">
                            State: Concept
                        </div>
                        <div class="display-item content-item m-2-top">
                            Fall 2020
                        </div>
                    </div>
                </div>
                <div class="display-cont-img zoom-effect">
                    <div class="img-stack-container relative">
                        <div class="absolute abs-cont spc-margin" v-for="(  item, index  ) in   displayLinks  " :key=" index ">
                            <img @click=" nextImg(true, 'display') "
                                :class=" { extraMargin1: index == 1 }, { extraMargin2: index == 2 } "
                                class="display-img click-cursor-dark" :src=" require(`../assets/lore/${item.link}.png`) "
                                :alt=" item.description ">
                        </div>
                    </div>
                </div>
            </div>

            <div class="medical-container relative">
                <div class="medical-imgs text-center parallax-in">
                    <img class="medical-img" src="@/assets/lore/ATNMUS_1.png" alt="medical">
                    <div class="medical-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 24:
                        </div>
                        <div class="m-2-top">
                            autonomous medical surgery room
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="food-cont">
            <div class="food-img-cont relative">
                <img class="food-img parallax-in" src="@/assets/lore/food.png" alt="food">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 25-28:
                </div>
                <div class="m-1-top">
                    branding for lab grown meat, 2019
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="grill-container relative">
                <div class="grill-imgs text-center parallax-in">
                    <img class="grill-img" src="@/assets/lore/grill.png" alt="plant based grill">
                    <div class="grill-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 29:
                        </div>
                        <div class="m-2-top">
                            Plant-based meat outdoor grill
                        </div>
                    </div>
                </div>
            </div>

            <div class="peeler-container relative">
                <div class="peeler-imgs text-center parallax-in">
                    <img class="peeler-img" src="@/assets/lore/peeler_1.png" alt="peeler">
                    <div class="peeler-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 30:
                        </div>
                        <div class="m-2-top">
                            vegetable peeler
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mars-cont">
            <div class="mars-img-cont relative">
                <img class="mars-img parallax-in" src="@/assets/lore/mars.png" alt="mars">
            </div>
            <div class="lore-container padded-container text-left content-details">
                <div class="m-2-top">
                    Fig 31-33:
                </div>
                <div class="m-1-top">
                    fictional objects for mars mission
                </div>
            </div>
        </div>
        <div class="lore-container padded-container">
            <div class="privacy-container relative">
                <div class="privacy-imgs text-center parallax-in">
                    <img class="privacy-img" src="@/assets/lore/privacy.png" alt="privacy">
                    <div class="privacy-img-content content-details text-left absolute">
                        <div class="m-2-top">
                            Fig 34:
                        </div>
                        <div class="m-2-top">
                            exploring privacy and intercation for smart devices
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fin-cont padded-container">
            <div class="lore-footer-content">
                <div class="footer-icon">
                    <img src="@/assets/lore/fin.png" alt="fin">
                </div>
                <div class="footer-title">
                    fin.
                </div>
            </div>
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design') ">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk') ">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore') ">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Transition name="fade">
        <div @click=" scrollToTop() " v-if=" showScrollTop " class="scroll-top">
            <img class="scroll-top-img" src="@/assets/scrollup.png" alt="scroll up">
        </div>
    </Transition>
</template>

<script>



export default {
    name: 'LoreView',
    components: {},
    data: function () {
        return {
            carui_link: [
                {
                    link: 'car_UI_1',
                },
                {
                    link: 'car_UI_2',
                },
                {
                    link: 'car_UI_3',
                },
                {
                    link: 'car_UI_4',
                },
                {
                    link: 'car_UI_5',
                },
            ],
            midjourLinks: [
                {
                    link: 'midjourney_1'
                },
                {
                    link: 'midjourney_2'
                },
                {
                    link: 'midjourney_3'
                },
                {
                    link: 'midjourney_4'
                },
                {
                    link: 'midjourney_5'
                },
            ],
            domesticLinks: [
                {
                    link: 'Floor_Lamp_1'
                },
                {
                    link: 'Floor_Lamp_2'
                },
                {
                    link: 'Floor_Lamp_3'
                },
            ],
            routerLinks: [
                {
                    link: 'internet-compass_Page_2'
                },
                {
                    link: 'internet-compass_Page_3'
                },
                {
                    link: 'internet-compass_Page_4'
                },
                {
                    link: 'internet-compass_Page_5'
                },
                {
                    link: 'internet-compass_Page_6'
                },
                {
                    link: 'internet-compass_Page_7'
                },
                {
                    link: 'internet-compass_Page_8'
                },
            ],
            droneLinks: [
                {
                    link: 'drone_1'
                },
                {
                    link: 'drone_2'
                },
                {
                    link: 'drone_3'
                }
            ],
            ssdLinks: [
                {
                    link: 'SSD_1'
                },
                {
                    link: 'SSD_2'
                },
                {
                    link: 'SSD_3'
                },
                {
                    link: 'SSD_4'
                }
            ],
            displayLinks: [
                {
                    link: 'PowerDisplay_1'
                },
                {
                    link: 'PowerDisplay_2'
                },
                {
                    link: 'PowerDisplay_3'
                }
            ],
            showAttemptInfo: false,
            showMidInfo: false,
            showWrapper: true,
            showDomesticFloorInfo: false,
            showRouterFloorInfo: false,
            showDroneInfo: false,
            showSSDInfo: false,
            showDisplayInfo: false,
            showScrollTop: false,
            parallaxElements: [],
            displayWidth: 0,
            appleDevice: false
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
        var images = document.querySelectorAll('.spc-margin img');
        for (var i = 0; i < images.length; i++) {
            // var marginValue = Math.floor(Math.random() * 50);
            var marginValue = i * -5;
            var marginTopValue = i * -5;
            // images[i].style.marginLeft = marginValue + 'px';
            images[i].style.marginTop = marginTopValue + 'px';
            // images[i].style.left = i*2 + 'px';
            // images[i].style.position = 'relative';
        }
        this.displayWidth = window.innerWidth;
        window.addEventListener('resize', this.calculateDisplayWidth);
        setTimeout(() => {
            this.setContextWidth();
        }, 100);


        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            this.appleDevice = true;
        } else {
            this.appleDevice = false;
        }
    },
    beforeUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.calculateDisplayWidth);
    },
    methods: {
        setAttemptWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('attempt-additional-info')[0].style.width = document.getElementsByClassName('attempt-title-span')[0].offsetWidth + 'px';
            }
        },
        setMidWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('mid-additional-info')[0].style.width = document.getElementsByClassName('mid-title-span')[0].offsetWidth + 'px';
            }
        },
        setDomesticWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('domestic-additional-info')[0].style.width = document.getElementsByClassName('domestic-title-span')[0].offsetWidth + 'px';
            }
        },
        setRouterWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('router-additional-info')[0].style.width = document.getElementsByClassName('router-title-span')[0].offsetWidth + 'px';
            }
        },
        setDroneWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('drone-additional-info')[0].style.width = document.getElementsByClassName('drone-title-span')[0].offsetWidth + 'px';
            }
        },
        setSsdWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('ssd-additional-info')[0].style.width = document.getElementsByClassName('ssd-title-span')[0].offsetWidth + 'px';
            }
        },
        setDisplayWidth() {
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('display-additional-info')[0].style.width = document.getElementsByClassName('display-title-span')[0].offsetWidth + 'px';
            }
        },
        setContextWidth() {
            // console.log(this.displayWidth, window.innerWidth, document.getElementsByClassName('lore-title-span')[0].offsetWidth)
            if (this.displayWidth > 1200) {
                document.getElementsByClassName('lore-context')[0].style.width = window.innerWidth - window.innerWidth / 4 + 'px';
            } else {
                document.getElementsByClassName('lore-context')[0].style.width = '100%';
            }
            if (this.displayWidth >= 1200) {
                document.getElementsByClassName('lore-context-text')[0].style.width = (window.innerWidth - window.innerWidth / 2.1) + 'px'
            } else {
                document.getElementsByClassName('lore-context-text')[0].style.width = '100%'
            }

        },
        calculateDisplayWidth() {
            this.displayWidth = window.innerWidth;
            this.setContextWidth();
        },
        nextImg(move, name) {
            if (name == 'carui') {
                if (move) {
                    this.carui_link.unshift(this.carui_link.pop());
                } else {
                    this.carui_link.push(this.carui_link.shift());
                }
            } else if (name == 'midjour') {
                if (move) {
                    this.midjourLinks.unshift(this.midjourLinks.pop());
                } else {
                    this.midjourLinks.push(this.midjourLinks.shift());
                }
            } else if (name == 'domestic') {
                if (move) {
                    this.domesticLinks.unshift(this.domesticLinks.pop());
                } else {
                    this.domesticLinks.push(this.domesticLinks.shift());
                }
            } else if (name == 'router') {
                if (move) {
                    this.routerLinks.unshift(this.routerLinks.pop());
                } else {
                    this.routerLinks.push(this.routerLinks.shift());
                }
            } else if (name == 'drone') {
                if (move) {
                    this.droneLinks.unshift(this.droneLinks.pop());
                } else {
                    this.droneLinks.push(this.droneLinks.shift());
                }
            } else if (name == 'ssd') {
                if (move) {
                    this.ssdLinks.unshift(this.ssdLinks.pop());
                } else {
                    this.ssdLinks.push(this.ssdLinks.shift());
                }
            } else if (name == 'display') {
                if (move) {
                    this.displayLinks.unshift(this.displayLinks.pop());
                } else {
                    this.displayLinks.push(this.displayLinks.shift());
                }
            }

        },
        // disableWrapper() {
        //     // document.getElementsByClassName('wrapper-class')[0].classList.add('translate');
        //     this.showWrapper = false;
        // },
        // disableWrapperQuick() {
        //     this.showWrapper = true;
        // },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
}
</script>
<style lang=less>
@import "@/styles/colors.less";

// .wrapper-class {
//     width: 100%;
//     height: 100vh;
//     background: #3B86C8;
//     top: 0;
//     left: 0;
//     z-index: 1000;
//     transition: all 0.3s ease-out;
//     overflow: hidden;
// }

// .translate {
//     transform: translateY(-200%);
//     transition: all 0.3s ease-out;
// }
.lore-page {
    background: #F1F1F1;
}

@media only screen and (max-width: 389px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.zoom-effect {
    transition: all 0.3s cubic-bezier(1, 0, 0.25, 1);
    @media only screen and (min-width: 992px) {
        &:hover {
            transform: scale(103%);
        }
    }
}

.lore-container {
    font-family: "Suisse International";
}

.lore-title {
    font-family: "Suisse International Condensed";
    font-weight: 700;
    font-size: 45px;
    line-height: 44px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #121212;
    text-align: left;
    margin-top: 90px;

    @media only screen and (max-width: 389px) {
        font-size: 40px;
        line-height: 39px;
    }

    @media only screen and (min-width: 992px) {
        font-size: 74px;
        line-height: 72px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 80px;
        line-height: 78px;
    }

    @media only screen and (min-width: 1300px) {
        font-size: 90px;
        line-height: 88px;
    }

    @media only screen and (min-width: 1550px) {
        // font-size: 110px;
        font-size: 110px;
        line-height: 109px;
        margin-top: 160px;
        // width: 81rem;
    }
}

.lore-context {
    flex-direction: column;
    text-align: left;
    margin-top: 45px;

    @media only screen and (min-width: 1200px) {
        margin-top: 90px;
        flex-direction: row;
        justify-content: space-between;
    }

    .lore-context-title {
        font-family: "Suisse International Mono";
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.005em;
        color: #767471;

        @media only screen and (min-width: 992px) {
            font-size: 16px;
            line-height: 21px;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 16px;
            line-height: 21px;
            // width: 50%;
        }
    }

    .lore-context-text {
        // font-family: "Suisse International Light";
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.005em;
        color: #000000;
        width: 100%;

        // padding-right: 180px;
        @media only screen and (min-width: 992px) {
            font-size: 24px;
            line-height: 33px;
            margin-top: 10px;
        }

        @media only screen and (min-width: 1200px) {
            // width: 721px;
            // margin-left: 160px;
            font-size: 24px;
            line-height: 33px;
            margin-top: 0;
            // padding-right: 160px;
        }

        @media only screen and (min-width: 1400px) {
            // width: 721px;
            // margin-left: 500px;
            font-size: 24px;
            line-height: 33px;
            // padding-right: 160px;
        }
    }
}

.readmore {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-top: 27px;
    color: #000000;
    width: 100px;
    background: #8D8B8733;
    padding: 7.5px 34px 7.5px 18px;
    border-radius: 20px;
    transition: all 0.3s ease;

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
        line-height: 26px;
        margin-top: 54px;
        width: 100px;
        padding: 7.5px 64px 7.5px 18px;
    }
}

.section-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #121211;

    @media only screen and (min-width: 1200px) {
        font-size: 45px;
        line-height: 58px;
    }
}

.content-details {
    font-family: "Suisse International Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #000000;
    margin-top: 54px;

    @media only screen and (min-width: 1200px) {
        font-size: 16px;
        line-height: 21px;
    }
}

.add-sign {
    height: 18px;
    width: 18px;
    top: 9.5px;
    right: 22px;
    transition: all 0.3s ease-in;

    @media only screen and (min-width: 1200px) {
        height: 21px;
        width: 21px;
        top: 10px;
        right: 30px;
    }
}

.additional-info {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #000000;

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
        line-height: 29px;
    }
}

.para-spacer {
    margin-top: 30px;

    @media only screen and (min-width: 1200px) {
        margin-top: 60px;
    }
}

.openInfo {
    transform: rotate(45deg);
}

.extraMargin1 {
    margin: 10px 0 0 10px;
}

.extraMargin2 {
    margin: 20px 0 0 20px;
}

// .parallax-in:nth-of-type(2n + 1) {
//     margin-right: 2.5%;
// }

// .parallax-in:nth-of-type(2n) {
//     margin-left: 2.5%;
// }

.attempt-cont {
    margin-top: 190px;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        margin-top: 220px;
        height: 600px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 293px;
        height: 600px;
        flex-direction: row;
    }

    .attempt-stack {
        width: 100%;
        height: 300px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            height: 600px;
        }

        @media only screen and (min-width: 1200px) {
            width: 55vw;
            height: 600px;
        }
    }

    .attempt-content {
        // width: 90vw;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            width: 30%;
            padding-left: 0;
            margin-left: 60px;
            justify-content: initial;
            margin-top: 120px;
        }

        @media only screen and (min-width: 1200px) {
            width: 30%;
            margin-left: 60px;
            padding-left: 0;
            justify-content: initial;
            margin-top: 60px;
        }
    }

    .abs-cont {
        left: 50%;
        transform: translate(-50%, 0);

        @media only screen and (min-width: 768px) {
            left: 50%;
            transform: translate(-50%, 0);
        }

        @media only screen and (min-width: 992px) {
            left: auto;
            transform: none;
        }
    }

    .carui-img {
        object-fit: contain;
        vertical-align: middle;
        height: 300px;
        width: calc(100vw - 54px);

        @media only screen and (min-width: 768px) {
            width: 100vw;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            height: 600px;
            width: 100%;
        }

        @media only screen and (min-width: 1200px) {
            height: 600px;
            width: 55vw;
            // min-width: 55vw;
        }
    }
}

.abs-cont {
    left: 50%;
    transform: translate(-50%, 0);

    @media only screen and (min-width: 768px) {
        left: 50%;
        transform: translate(-50%, 0);
    }

    @media only screen and (min-width: 992px) {
        left: auto;
        transform: none;
    }
}

.mid-journey {
    margin-top: 120px;
    // height: 580px;
    flex-direction: column-reverse;

    @media only screen and (min-width: 992px) {
        margin-top: 320px;
        // height: 580px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 420px;
        height: 580px;
        flex-direction: row;
    }

    .mid-j-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            display: block;
            width: 50%;
            text-align: left;
            padding-left: 0;
            margin-top: 0;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            text-align: left;
            padding-left: 0;
            margin-top: 0;
        }
    }

    .mid-j-img {
        width: 100%;
        height: 380px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            margin-top: 40px;
            margin-left: 50px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
        }

        .midjour-img {
            object-fit: contain;
            vertical-align: middle;
            height: 380px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 768px) {
                width: 100vw;
                height: 380px;
            }

            @media only screen and (min-width: 992px) {
                height: 400px;
                width: 400px;
            }

            @media only screen and (min-width: 1200px) {
                height: 540px;
                width: 540px;
            }
        }
    }
}

.addHeight {
    height: fit-content;
}

.indian-summer {
    margin-top: 200px;

    @media only screen and (min-width: 768px) {
        margin-top: 200px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 300px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 400px;
    }

    .three-img-cont {
        width: 100%;

        .indian-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }

    .summer-shoe {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;
    }

    .summer-shoe-caption {
        position: absolute;
        left: 0;
        margin-top: 10px;

        @media only screen and (min-width: 992px) {
            left: 200px;
            margin-top: 30px;
        }
    }

    .three-4-cont {
        margin-top: 100px;

        @media only screen and (min-width: 768px) {
            margin-top: 120px;
        }

        @media only screen and (min-width: 992px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 230px;
        }
    }

    .three-4 {
        width: 100%;
        object-fit: contain;

        @media only screen and (min-width: 1400px) {
            width: 1080px;
        }
    }
}

.summer-shoe-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 350px;
    }

    .summer-shoe-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .summer-shoe-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 610px;
            }

            @media only screen and (min-width: 1200px) {
                width: 850px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1080px;
            }
        }
    }

    .summer-shoe-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.phone-container {
    margin-top: 260px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 260px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 460px;
    }

    .phone-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .phone {
            height: 327px;
            width: 327px;

            @media only screen and (max-width: 370px) {
                height: 227px;
                width: 227px;
            }

            @media only screen and (min-width: 992px) {
                height: 327px;
                width: 327px;
            }

            @media only screen and (min-width: 1200px) {
                height: 427px;
                width: 427px;
            }

            @media only screen and (min-width: 1600px) {
                height: 627px;
                width: 627px;
            }
        }

        .phone2 {
            margin-left: 0;
            margin-top: 22px;

            @media only screen and (min-width: 768px) {
                margin-left: 42px;
                margin-top: 0;
            }
        }

        .phone-img {
            width: 318px;
        }
    }

    .phone-content {
        bottom: -100px;
        left: 0;
    }
}

.hardware-container {
    margin-top: 260px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 260px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 460px;
    }

    .hardware-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .hardware-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 610px;
            }

            @media only screen and (min-width: 1200px) {
                width: 810px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1110px;
            }
        }
    }

    .hardware-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.domestic-floor {
    margin-top: 356px;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 356px;
        height: 580px;
        flex-direction: row;
    }

    .domestic-floor-content {

        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            display: block;
            width: 50%;
            text-align: left;
            padding-left: 0;
            margin-top: 40px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            text-align: left;
            padding-left: 0;
            margin-top: 40px;
        }
    }

    .domestic-floor-img {
        width: 100%;
        height: 380px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            margin-top: 40px;
            margin-left: 50px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
        }

        .domestic-img {
            object-fit: contain;
            vertical-align: middle;
            height: 380px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 768px) {
                width: 100vw;
                height: 380px;
            }

            @media only screen and (min-width: 992px) {
                height: 400px;
                width: 400px;
            }

            @media only screen and (min-width: 1200px) {
                height: 580px;
                width: 440px;
            }

            @media only screen and (min-width: 1400px) {
                height: 580px;
                width: 580px;
            }
        }
    }
}

.fashion-cont {
    margin-top: 100px;

    @media only screen and (min-width: 768px) {
        margin-top: 200px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 300px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 500px;
    }

    .fasion-img-cont {
        width: 100%;

        .fashion-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }
}

.vinyl-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 260px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 520px;
    }

    .vinyl-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .vinyl-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 610px;
            }

            @media only screen and (min-width: 1200px) {
                width: 810px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1110px;
            }
        }
    }

    .vinyl-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.audio-cont {
    margin-top: 300px;

    @media only screen and (min-width: 768px) {
        margin-top: 300px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 300px;
        margin-bottom: 400px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 300px;
    }

    .audio-img-cont {
        width: 100%;

        .audio-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }
}

.router-floor {
    margin-top: 190px;

    .router-floor-content {
        text-align: left;
        margin-top: 40px;
        width: calc(100vw - 54px);
        margin-left: auto;
        margin-right: auto;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            margin-top: 0;
            width: 710px;
            // margin-left: 90px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 0px;
            width: 910px;
            // margin-left: -10px;
        }

        @media only screen and (min-width: 1400px) {
            margin-top: 120px;
            width: 1110px;
            // margin-left: 125px;

        }

        .router-floor-title {
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 55%;
            }
        }
    }

    .router-floor-img {
        height: 250px;
        width: 100%;

        @media only screen and (min-width: 768px) {
            height: 350px;
            width: 100%;
        }

        @media only screen and (min-width: 992px) {
            height: 450px;
            width: 100%;
        }

        @media only screen and (min-width: 1200px) {
            height: 530px;
            width: 100%;
        }

        .abs-cont {
            left: 50%;
            transform: translate(-50%, 0);
        }

        .router-img {
            object-fit: contain;
            height: 380px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 992px) {
                height: 450px;
                width: 710px;
            }

            @media only screen and (min-width: 1200px) {
                height: 530px;
                width: 910px;
            }

            @media only screen and (min-width: 1400px) {
                height: 630px;
                width: 1110px;
            }
        }
    }
}

.brain-cont {
    margin-top: 100px;

    @media only screen and (min-width: 768px) {
        margin-top: 200px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 200px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 300px;
    }

    .brain-img-cont {
        width: 100%;

        .brain-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }
}

.ecg-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 350px;
    }

    .ecg-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .ecg-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 510px;
            }

            @media only screen and (min-width: 1200px) {
                width: 710px;
            }

            @media only screen and (min-width: 1400px) {
                width: 864px;
            }
        }
    }

    .ecg-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.news-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 350px;
    }

    .news-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .news-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 610px;
            }

            @media only screen and (min-width: 1200px) {
                width: 810px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1120px;
            }
        }
    }

    .news-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.floor-lamp-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 400px;
    }

    .floor-lamp-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .floor-lamp-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 510px;
            }

            @media only screen and (min-width: 1200px) {
                width: 710px;
            }

            @media only screen and (min-width: 1400px) {
                width: 854px;
            }
        }
    }

    .floor-lamp-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.posters-container {
    margin-top: 250px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 400px;
    }

    .posters-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .posters-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 610px;
            }

            @media only screen and (min-width: 1200px) {
                width: 810px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1120px;
            }
        }
    }

    .posters-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.drone-floor {
    margin-top: 350px;
    flex-direction: column-reverse;

    @media only screen and (min-width: 992px) {
        margin-top: 200px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 357px;
        height: 580px;
    }

    .drone-content {
        width: 100%;
        text-align: left;
        margin-top: 40px;
        // margin-left: auto;
        // margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            display: block;
            width: 50%;
            padding-left: 0;
            margin-top: 40px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            margin-top: 0;
        }
    }

    .drone-floor-img {
        width: 100%;
        height: 350px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            margin-top: 40px;
            margin-left: 50px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
        }

        .abs-cont {
            left: 50%;
            transform: translate(-50%, 0);
        }

        .drone-img {
            object-fit: contain;
            vertical-align: middle;
            height: 380px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 768px) {
                width: calc(100vw - 54px);
                height: 380px;
            }

            @media only screen and (min-width: 992px) {
                width: 500px;
            }

            @media only screen and (min-width: 1200px) {
                height: 500px;
                width: 570px;
            }

            @media only screen and (min-width: 1400px) {
                height: 650px;
                width: 870px;
            }
        }
    }
}

.watch-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 500px;
    }

    .watch-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .watch-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 510px;
            }

            @media only screen and (min-width: 1200px) {
                width: 710px;
            }

            @media only screen and (min-width: 1400px) {
                width: 864px;
            }
        }
    }

    .watch-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.ssd-cont {
    margin-top: 420px;
    margin-top: 350px;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        margin-top: 200px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 357px;
        height: 580px;
    }

    .ssd-content {
        width: 100%;
        text-align: left;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            display: block;
            width: 50%;
            padding-left: 0;
            margin-top: 40px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            margin-top: 50px;
            margin-left: 140px;
        }
    }

    // .abs-cont {
    //     left: 50%;
    //     transform: translate(-50%, 0);
    // }

    .ssd-cont-img {
        width: 100%;
        height: 230px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            margin-top: 40px;
            margin-left: 50px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            margin-top: 0;
            margin-left: 0;
        }

        .ssd-img {
            object-fit: contain;
            vertical-align: middle;
            height: 230px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 768px) {
                width: calc(100vw - 54px);
                height: 380px;
            }

            @media only screen and (min-width: 992px) {
                width: 380px;
                height: 380px;
            }

            @media only screen and (min-width: 1200px) {
                height: 650px;
                width: 550px;
            }
        }
    }
}

.display-cont {
    margin-top: 420px;
    margin-top: 350px;
    flex-direction: column-reverse;

    @media only screen and (min-width: 992px) {
        margin-top: 200px;
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 357px;
        height: 580px;
    }

    .display-content {
        width: 100%;
        text-align: left;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            padding-left: 15px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 819px) {
            padding-left: 35px;
            margin-top: 30px;
        }

        @media only screen and (min-width: 992px) {
            display: block;
            width: 50%;
            padding-left: 0;
            margin-top: 40px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            // margin-top: 60px;
        }
    }

    .abs-cont {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .display-cont-img {
        width: 100%;
        height: 230px;

        @media only screen and (min-width: 768px) {
            width: 100%;
            height: 380px;
        }

        @media only screen and (min-width: 992px) {
            width: 50%;
            margin-top: 40px;
            margin-left: 50px;
        }

        @media only screen and (min-width: 1200px) {
            width: 50%;
            margin-top: 0;
            margin-left: 0;
        }

        .display-img {
            object-fit: contain;
            vertical-align: middle;
            height: 230px;
            width: calc(100vw - 54px);

            @media only screen and (min-width: 768px) {
                width: calc(100vw - 54px);
                height: 380px;
            }

            @media only screen and (min-width: 992px) {
                width: 380px;
                height: 500px;
            }

            @media only screen and (min-width: 1200px) {
                height: 650px;
                width: 550px;
            }
        }
    }
}

.medical-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 400px;
    }

    .medical-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .medical-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 520px;
            }

            @media only screen and (min-width: 1200px) {
                width: 720px;
            }

            @media only screen and (min-width: 1400px) {
                width: 974px;
            }
        }
    }

    .medical-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.food-cont {
    margin-top: 320px;

    @media only screen and (min-width: 768px) {
        margin-top: 320px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 320px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 320px;
    }

    .food-img-cont {
        width: 100%;

        .food-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }

    .three-4-cont {
        margin-top: 230px;
    }

    .three-4 {
        width: 1080px;
        object-fit: contain;
    }
}

.grill-container {
    margin-top: 120px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 220px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 320px;
    }

    .grill-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .grill-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 620px;
            }

            @media only screen and (min-width: 1200px) {
                width: 820px;
            }

            @media only screen and (min-width: 1400px) {
                width: 1152px;
            }
        }
    }

    .grill-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.peeler-container {
    margin-top: 170px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 170px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 278px;
    }

    .peeler-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .peeler-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 620px;
            }

            @media only screen and (min-width: 1200px) {
                width: 732px;
            }

            @media only screen and (min-width: 1400px) {
                width: 732px;
            }
        }
    }

    .peeler-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.mars-cont {
    margin-top: 320px;

    .mars-img-cont {
        width: 100%;

        .mars-img {
            width: 100%;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.8s all ease-out;
            object-fit: contain;
        }
    }

    .three-4-cont {
        margin-top: 230px;
    }

    .three-4 {
        width: 1080px;
        object-fit: contain;
    }
}

.privacy-container {
    margin-top: 120px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 992px) {
        margin-top: 220px;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 320px;
    }

    .privacy-imgs {
        transform: translateY(10%);
        opacity: 0;
        transition: 0.8s all ease-out;

        .privacy-img {
            object-fit: contain;
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 620px;
            }

            @media only screen and (min-width: 1200px) {
                width: 732px;
            }

            @media only screen and (min-width: 1400px) {
                width: 883px;
            }
        }
    }

    .privacy-img-content {
        // bottom: 0px;
        bottom: -100px;
        left: 0px;
    }
}

.fin-cont {
    margin-top: 250px;
    // transition: all 2s ease-in-out;

    @media only screen and (min-width: 992px) {
        margin-top: 250px;
    }

    .lore-footer-content {
        text-align: center;
        transition: all 2s ease-in-out;
        // align-items: baseline;

        .footer-icon img {
            width: 70px;
            height: 50px;
            margin-right: 15px;
            margin-bottom: 15px;

            @media only screen and (min-width: 768px) {
                width: 90px;
                height: 70px;
                margin-right: 15px;
            }

            @media only screen and (min-width: 992px) {
                width: 120px;
                height: 90px;
                margin-right: 25px;
                margin-bottom: 15px;
            }

            @media only screen and (min-width: 1200px) {
                width: 140px;
                height: 100px;
                margin-right: 25px;
                margin-bottom: 15px;
            }
        }

        .footer-title {
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 54px;
            line-height: 53px;
            text-transform: uppercase;
            // transition: all 2s ease-in-out;

            @media only screen and (min-width: 768px) {
                font-size: 70px;
                line-height: 69px;
            }

            @media only screen and (min-width: 992px) {
                font-size: 100px;
                line-height: 99px;
            }

            @media only screen and (min-width: 1200px) {
                font-size: 110px;
                line-height: 109px;
            }
        }
    }

    .common-footer {
        margin-top: 175px;
        // transition: all 2s ease-in-out;

        @media only screen and (min-width: 992px) {
            margin-top: 220px;
        }

        .footer-nav {
            justify-content: center;
            align-items: center;

            .nav-links {
                font-family: 'Suisse International';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.005em;
                color: #A7A49F;
                border-radius: 34px;
                background: #eceae6;
                padding: 4px 0;
                width: 97px;
                user-select: none;
                cursor: pointer;

                @media only screen and (min-width: 992px) {
                    font-size: 20px;
                    line-height: 26px;
                    width: 117px;
                }
            }

            .separator {
                margin: 0 21px;

                @media only screen and (min-width: 992px) {
                    margin: 0 41px;
                }

                & img {
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }

    .footer-info {
        margin-top: 50px;
        padding-bottom: 50px;
        font-family: 'Suisse International Mono';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.005em;
        color: #A7A49F;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // transition: all 2s ease-in-out;

        @media only screen and (min-width: 992px) {
            flex-direction: row;
            font-size: 12px;
            line-height: 15px;
        }

        & div {
            padding: 0;

            @media only screen and (min-width: 992px) {
                padding: 0 1px;
            }
        }

        .copyright {
            width: 10px;
            height: 10px;
        }
    }
}</style>