<template>
	<div class="folk-bg opaque-all">
		<div class="padded-container folk-page header-height">
			<div class="folk-title content-grid">
				<div class="label">
					My Name
				</div>
				<div class="title-content">
					<div class="title-name">
						<div>Beeda Gokul</div>
						<div>Rishwanth Yadav</div>
					</div>
					<div class="social-links">
						<a class="resume-link" target="_blank" rel="noopener noreferrer"
							href="/GokulBeeda_Resume_2023.pdf">RESUME</a>
						<a class="m-5-left" target="_blank" rel="noopener noreferrer"
							href="https://www.instagram.com/designerbeedagokul/">INSTAGRAM</a>
						<a class="m-5-left" target="_blank" rel="noopener noreferrer"
							href="https://www.linkedin.com/in/gokul-beeda-917b29127">LINKEDIN</a>
						<a class="m-5-left" href="mailto:beeda.gokul@gmail.com">EMAIL</a>
						<a class="m-5-left" target="_blank" rel="noopener noreferrer"
							href="https://open.spotify.com/show/4cGz7bdP7HTo8aoLO3SYCr?si=4d0d793039b9402d&nd=1">PODCAST</a>
						<a class="m-5-left" target="_blank" rel="noopener noreferrer"
							href="https://substack.com/profile/73479579-gokul-beeda?utm_source=substack_profile">BLOG</a>
					</div>
				</div>
			</div>
			<div class="photo-album-container content-grid">
				<div class="label photo-caption flex-col">
					<div class="pic-no">
						Fig {{ albumLinks[5].num }}:
					</div>
					<div class="pic-caption">
						{{ albumLinks[5].description }}
					</div>
					<div class="pic-nav d-flex pointer">
						<div @click="nextImg(false)">
							<img class="rotate-180 m-4-right arrow-icon" src="../assets/arrow.svg" alt="arrow">
						</div>
						<div @click="nextImg(false)" class="pointer">
							Prev
						</div>
						<span class="m-2-left m-2-right pointer">/</span>
						<div @click="nextImg(true)">
							Next
						</div>
						<div @click="nextImg(true)">
							<img class="m-4-left pointer arrow-icon" src="../assets/arrow.svg" alt="arrow">
						</div>
					</div>
				</div>
				<div class="photo-album relative click-cursor-white">
					<div>
						<div class="each-photo absolute" v-for="(item, index) in albumLinks" :key="index">
							<span class="helper"></span><img @click="nextImg(true)" class="m-auto p-img"
								:src="require(`../assets/folk/${item.link}.png`)" :alt="item.description">
						</div>
					</div>

				</div>
			</div>
			<div class="mobile-social-links">
				<a class="resume-link" target="_blank" rel="noopener noreferrer"
					href="/GokulBeeda_Resume_2023.pdf">RESUME</a>
				<a class="" target="_blank" rel="noopener noreferrer"
					href="https://www.instagram.com/designerbeedagokul/">INSTAGRAM</a>
				<a class="" target="_blank" rel="noopener noreferrer"
					href="https://www.linkedin.com/in/gokul-beeda-917b29127">LINKEDIN</a>
				<a class="" href="mailto:beeda.gokul@gmail.com">EMAIL</a>
				<a class="" target="_blank" rel="noopener noreferrer"
					href="https://open.spotify.com/show/4cGz7bdP7HTo8aoLO3SYCr?si=4d0d793039b9402d&nd=1">PODCAST</a>
				<a class="" target="_blank" rel="noopener noreferrer"
					href="https://substack.com/profile/73479579-gokul-beeda?utm_source=substack_profile">BLOG</a>
			</div>
			<div class="what-i-do content-grid parallax-in">
				<div class="label">
					What I do well
				</div>
				<div class="work-content text-left">
					<div class="work-text">
						My design practice is rooted in the belief that the best designs emerge from a nuanced understanding
						of human behaviour.
					</div>
					<div class="work-sub-text">
						<div class="work-para">
							At the heart of my work lies a deep understanding of humans and their ever-changing behaviour.
							To create great design, I believe one must be in tune with people's desires and discomforts at
							the grassroot level by observing their habits, listening to their feedback, and keeping up with
							emerging trends. My interest lies in building experiences for abstract and emerging technologies
							that are not only visually stunning but also deeply resonate with the human experience and feel
							like second nature to people.
						</div>
						<div class="work-para m-6-top">
							I draw skills from various disciplines, such as industrial design, UI/UX, design strategy, and
							UX research. By combining these elements thoughtfully and deliberately, I aim to create products
							and services that meet people's needs and bring them joy.
						</div>
					</div>
				</div>
			</div>
			<div class="scroll-marquee">
				<div class="scroll-one d-flex">
					<div class="scrollone d-flex">
						<img class="scroll-icon" src="@/assets/scroll/love.png" alt="love"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/sun.png" alt="sun"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/man.png" alt="man"> LORE
						<img class="scroll-icon" src="@/assets/scroll/pen.png" alt="pen"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/globe.png" alt="globe"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/peace.png" alt="peace"> LORE


						<img class="scroll-icon" src="@/assets/scroll/love.png" alt="love"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/sun.png" alt="sun"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/man.png" alt="man"> LORE
						<img class="scroll-icon" src="@/assets/scroll/pen.png" alt="pen"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/globe.png" alt="globe"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/peace.png" alt="peace"> LORE
					</div>
				</div>
				<div class="scroll-one d-flex">
					<div class="scroll-two d-flex">
						FOLK <img class="scroll-icon" src="@/assets/scroll/flower.png" alt="flower"> LORE <img
							class="scroll-icon" src="@/assets/scroll/telugu.png" alt="telugu"> DESIGN
						<img class="scroll-icon" src="@/assets/scroll/globe.png" alt="globe"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/peace.png" alt="peace"> LORE <img class="scroll-icon"
							src="@/assets/scroll/love.png" alt="love"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/sun.png" alt="sun">


						FOLK <img class="scroll-icon" src="@/assets/scroll/flower.png" alt="flower"> LORE <img
							class="scroll-icon" src="@/assets/scroll/telugu.png" alt="telugu"> DESIGN
						<img class="scroll-icon" src="@/assets/scroll/globe.png" alt="globe"> FOLK <img class="scroll-icon"
							src="@/assets/scroll/peace.png" alt="peace"> LORE <img class="scroll-icon"
							src="@/assets/scroll/love.png" alt="love"> DESIGN <img class="scroll-icon"
							src="@/assets/scroll/sun.png" alt="sun">
					</div>

				</div>
			</div>
			<div class="what-i-do why-design content-grid parallax-in">
				<div class="label">
					Why design folk lore?
				</div>
				<div class="work-content text-left">
					<div class="work-text">
						“Folklore embodies our identity as humans.”
					</div>
					<div class="work-sub-text">
						<div class="work-para">
							Folklore embodies our identity as humans. Throughout history, folklores were used to convey the
							customs, superstitions, songs, behaviours, proverbs, and observances of our culture. Nowadays,
							we harness these elements to craft captivating experiences and design artifacts that reflect the
							evolving trends of our world
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="middle-title parallax-in">
					<div>
						folk is about
					</div>
					<div>
						the people who
					</div>
					<div>
						impacted my craft <img class="folk-pen" src="@/assets/folk/pen.png" alt="pen">
					</div>
				</div>
			</div>
			<div class="folks-cont content-grid parallax-in">
				<div class="label">
					Folk(s)
				</div>
				<div class="folkst-content text-left">
					<div class="work-sub-text">
						My entire design practice is built on the wisdom I've learned from the masters who've come before.
						They walked the path and shared their stories which shaped my approach and world view.
						I'm happy to share a few of my favourite folklore's with you. Hope these offer some design
						enlightenment. Enjoy :)
					</div>
				</div>
			</div>
		</div>
		<div class="padded-container years border-top parallax-in">
			<div class="years-content-grid">
				<div class="years-label">
					2023
				</div>
				<div class="year-content text-left">
					<div class="years-title">
						“to shape something original you need to go beyond tools and material”
					</div>
					<div class="years-subtitle">
						Pierluigi Dalla Rosa at Humane & CCA, SF
					</div>
					<Transition name="slide-down">
						<div v-if="showInfoOne" class="years-info">
							“the reason interaction designers need to learn coding is because to shape something original
							you need to go beyond tools and material like figma at look at the source ie., code”
						</div>
					</Transition>
				</div>
				<div class="desktop-expand years-expand-icon">
					<img @click="showInfoOne = !showInfoOne" :class="{ openInfo: showInfoOne }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
			<div class="mobile-expand">
				<div class="years-expand-icon">
					<img @click="showInfoOne = !showInfoOne" :class="{ openInfo: showInfoOne }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
		</div>
		<div class="padded-container years parallax-in">
			<div class="years-content-grid">
				<div class="years-label">
					2022
				</div>
				<div class="year-content text-left">
					<div class="years-title">
						“There was interaction design before interaction designers.”
					</div>
					<div class="years-subtitle">
						Rich Hanks VP at TACTILE, Boston
					</div>
					<Transition name="slide-down">
						<div v-if="showInfoTwo" class="years-info">
							“There was interaction design before interaction designers. so there is no reason Industrial
							designers cant do both Industrial and interaction design”
						</div>
					</Transition>
				</div>
				<div class="desktop-expand years-expand-icon">
					<img @click="showInfoTwo = !showInfoTwo" :class="{ openInfo: showInfoTwo }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
			<div class="mobile-expand">
				<div class="years-expand-icon">
					<img @click="showInfoTwo = !showInfoTwo" :class="{ openInfo: showInfoTwo }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
		</div>
		<div class="padded-container years parallax-in">
			<div class="years-content-grid">
				<div class="years-label">
					2021
				</div>
				<div class="year-content text-left">
					<div class="years-title">
						“Design around customer’s mindsets not personas.”
					</div>
					<div class="years-subtitle">
						Margaret Mazz at Kohler Design, WI
					</div>
					<Transition name="slide-down">
						<div v-if="showInfoThree" class="years-info">
							“Design around customer’s mindsets not personas. a persona limits your customer to a age,
							demographic etc. but a mindset and motives could be the same for 60 yr old or 16 yr old
							customer”
						</div>
					</Transition>
				</div>
				<div class="desktop-expand years-expand-icon">
					<img @click="showInfoThree = !showInfoThree" :class="{ openInfo: showInfoThree }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
			<div class="mobile-expand">
				<div class="years-expand-icon">
					<img @click="showInfoThree = !showInfoThree" :class="{ openInfo: showInfoThree }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
		</div>
		<div class="padded-container years parallax-in">
			<div class="years-content-grid">
				<div class="years-label">
					2021
				</div>
				<div class="year-content text-left">
					<div class="years-title">
						“draw ideas, not sketches.”
					</div>
					<div class="years-subtitle">
						Connor Pelletier-Sutton, Kelly Cluster at Knack Design & Advance Design
					</div>
					<Transition name="slide-down">
						<div v-if="showInfoFour" class="years-info">
							“Sketching in a design process is to draw ideas, not sketches.”
						</div>
					</Transition>
				</div>
				<div class="desktop-expand years-expand-icon">
					<img @click="showInfoFour = !showInfoFour" :class="{ openInfo: showInfoFour }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
			<div class="mobile-expand">
				<div class="years-expand-icon">
					<img @click="showInfoFour = !showInfoFour" :class="{ openInfo: showInfoFour }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
		</div>
		<div class="padded-container years border-bottom parallax-in">
			<div class="years-content-grid">
				<div class="years-label">
					2020
				</div>
				<div class="year-content text-left">
					<div class="years-title">
						“look for parallel experiences and emotions outside your design.”
					</div>
					<div class="years-subtitle">
						Sushant Vohra at Teague, Seattle
					</div>
					<Transition name="slide-down">
						<div v-if="showInfoFive" class="years-info">
							“when you are looking for inspiration look for parallel experiences and emotions outside your
							design. eg: If your product is supposed to bring joy look at Disney for inspiration not other
							consumer products ”
						</div>
					</Transition>
				</div>
				<div class="desktop-expand years-expand-icon">
					<img @click="showInfoFive = !showInfoFive" :class="{ openInfo: showInfoFive }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
			<div class="mobile-expand">
				<div class="years-expand-icon">
					<img @click="showInfoFive = !showInfoFive" :class="{ openInfo: showInfoFive }" class="years-add"
						src="../assets/add.svg" alt="add">
				</div>
			</div>
		</div>
		<div class="folk-footer padded-container">
			<div class="folk-footer-content d-flex">
				<div class="footer-icon">
					<img src="@/assets/folk/wave.png" alt="wave">
				</div>
				<div class="footer-title">
					that’s all folks!
				</div>
			</div>
			<div class="common-footer">
				<div class="footer-nav d-flex">
					<div class="nav-links navlink1" @click="this.$router.push('/design')">
						design
					</div>
					<div class="separator">
						<img src="@/assets/separator.png" alt="separator">
					</div>
					<div class="nav-links navlink2" @click="this.$router.push('/folk')">
						folk
					</div>
					<div class="separator">
						<img src="@/assets/separator.png" alt="separator">
					</div>
					<div class="nav-links navlink3" @click="this.$router.push('/lore')">
						lore
					</div>
				</div>
				<div class="footer-info d-flex">
					<div>
						Copyright <img class="copyright" src="@/assets/copyright.png" alt="copyright"> 2023 BGRY.
					</div>
					<div>
						Made in Andhra Pradesh
					</div>
				</div>
			</div>
		</div>
		<Transition name="fade">
			<div @click="scrollToTop()" v-if="showScrollTop" class="scroll-top">
				<img class="scroll-top-img" src="@/assets/scrollup.png" alt="scroll up">
			</div>
		</Transition>
	</div>
</template>

<script>

export default {
	name: 'FolkView',
	components: {},
	data: function () {
		return {
			// showHardware: false,
			currentImageLink: '',
			showScrollTop: false,
			albumLinks: [
				{
					link: 'scuba',
					description: 'When im not designing I love swimming and free diving. Tulum, MX (2019)',
					num: 6,
				},
				{
					link: 'fam_old',
					description: 'My family :) Ooty, Tamil Nadu(2004)',
					num: 5,
				},
				{
					link: 'fam',
					description: 'My grandparents who are a driving force in my life (2016)',
					num: 4,
				},
				{
					link: 'food',
					description: 'A traditional Telugu meal made on Sankranthi festival(2020)',
					num: 3,
				},
				{
					link: 'AfterlightImage',
					description: 'AI generated portrait, Stable Diffusion(2023)',
					num: 2,
				},
				{
					link: 'profile',
					description: 'A picture of me Syracuse, NY',
					num: 1,
				},
			],
			showInfoOne: false,
			showInfoTwo: false,
			showInfoThree: false,
			showInfoFour: false,
			showInfoFive: false,
			parallaxElements: [],
			OneIndex: 0,
			TwoIndex: 0,
			marqueeOne: '',
			marqueeTw0: '',
			scrollSpeed: 10,

		}
	},
	mounted() {
		window.scrollTo({ top: 0, behavior: 'instant' });
		this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
		if (this.getScrollPercent() < 5) {
			document.addEventListener('scroll', this.handleScroll);
		} else {
			setTimeout(() => {
				document.addEventListener('scroll', this.handleScroll);
			}, 2000);
		}

		this.marqueeOne = document.getElementsByClassName('scrollone')[0];
		this.marqueeTwo = document.getElementsByClassName('scroll-two')[0];
		this.marqueeOne.style.transform = `translate(0px, 0px)`;
		this.marqueeTwo.style.transform = `translate(${-(this.marqueeTwo.clientWidth - window.innerWidth)}px, 0px)`;
		this.TwoIndex = this.marqueeTwo.clientWidth - window.innerWidth;
		setInterval(this.increaseValue, this.scrollSpeed);
	},
	beforeUnmount() {
		document.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		nextImg(move) {
			if (move) {
				this.albumLinks.unshift(this.albumLinks.pop());
			} else {
				this.albumLinks.push(this.albumLinks.shift());
			}
		},
		handleScroll(evt) {
			for (var i = 0; i < this.parallaxElements.length; i++) {
				var elem = this.parallaxElements[i]
				if (this.isElemVisible(elem)) {
					elem.style.opacity = '1'
					elem.style.transform = 'scale(1)'
					// this.parallaxElements.splice(i, 1) // only allow it to run once
				}
			}
			if (this.getScrollPercent() > 10) {
				this.showScrollTop = true;
			}

			if (this.getScrollPercent() < 1) {
				this.showScrollTop = false;
			}
		},
		getScrollPercent() {
			var h = document.documentElement,
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';
			return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
		},
		isElemVisible(el) {
			var rect = el.getBoundingClientRect()
			var elemTop = rect.top + 200 // 200 = buffer
			var elemBottom = rect.bottom
			return elemTop < window.innerHeight && elemBottom >= 0
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},

		scrollObserver() {
			const element = document.getElementsByClassName('folk-footer-content');
			const el = document.getElementsByClassName('folk-footer')[0];
			const footerinfo = document.getElementsByClassName('common-footer')[0];
			let count = 0;
			const observer = new IntersectionObserver((entries, observer) => {
				if (entries[0].isIntersecting) {
					// console.log('intersecting');
					document.addEventListener('wheel', function (evt) {
						count++;
						// console.log(count)
						if (count > 2) {
							if (evt.deltaY > 0) {
								// console.log('scroll down')
								footerinfo.classList.add('fixed-pos');
								el.classList.add('animate-to-center');
								window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
							} else if (evt.deltaY < 0) {
								footerinfo.classList.remove('fixed-pos');
								el.classList.remove('animate-to-center');
							}
						}
					});
				} else {
					document.removeEventListener('wheel', function () { });
				}
			}, { threshold: 1 });

			observer.observe(element[0]);
		},

		increaseValue() {
			this.OneIndex++;
			this.TwoIndex--;

			this.marqueeOne.style.transform = `translate(${-this.OneIndex}px, 0px)`;
			this.marqueeTwo.style.transform = `translate(${-this.TwoIndex}px, 0px)`;

			if (this.OneIndex == this.marqueeOne.clientWidth - 500) {
				this.OneIndex = 0;
			}

			if (this.TwoIndex == 0) {
				this.TwoIndex = this.marqueeTwo.clientWidth - window.innerWidth;
			}
		},

		increaseSpeed() {
			// this.scrollSpeed = 1;
			// const el = document.getElementsByClassName('folk-footer-content')[0];
			// // el.style.marginTop = '550px'
			// // el.style.marginBottom = '550px'
			// el.classList.add('fixed-pos');
			// el.classList.add('animate-to-center');
		}
	},
}
</script>
<style lang=less>
@import "@/styles/colors.less";

// .steve-enter-active,
// .steve-leave-active {
// 	transition: all 0.4s ease-in;
// }

// .steve-enter-from,
// .steve-leave-to {
// 	transform: translateY(-50px);
// 	opacity: 0;
// }

.steve-enter-active {
	animation: bounce-in 0.5s;
}

.steve-leave-active {
	animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
	0% {
		transform: translateY(-60px);
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}

	90% {
		opacity: 0.25;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

.steve-size {
	font-size: 52px !important;
	transition: all 0.5s ease-in;
}


.steve-hide-enter-active,
.steve-hide-leave-active {
	transition: all 0.5s ease-out;
}

.steve-hide-enter-from,
.steve-hide-leave-to {
	opacity: 0;
}

html {
	scroll-behavior: smooth;
}


@media only screen and (max-width: 389px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.folk-bg {
	background: @background5;

	.openInfo {
		transform: rotate(45deg);
	}
}

.folk-page {
	background: @background5;
	padding-top: 180px;

	@media only screen and (min-width: 992px) {
		padding-top: 250px;
	}

	.content-grid {
		display: block;

		/* Extra large devices (large laptops and desktops, 1200px and up) */
		@media only screen and (min-width: 992px) {
			display: grid;
			grid-template-columns: 3fr 7fr;
			margin-bottom: 100px;
		}
	}

	.label {
		display: flex;
		font-family: "Suisse International Mono";
		font-weight: 700;
		font-size: 11px;
		line-height: 13px;
		letter-spacing: 0.005em;
		color: #767471;
		margin-left: 1px;
		margin-bottom: 10px;

		@media only screen and (min-width: 992px) {
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.005em;
			margin-bottom: 0;
		}
	}

	.title-name {
		font-family: "Suisse International Condensed";
		font-weight: 700;
		text-transform: uppercase;
		color: #121212;
		text-align: left;
		font-size: 45px;
		line-height: 46px;
		letter-spacing: 0.005em;

		@media only screen and (max-width: 389px) {
			font-size: 40px;
			line-height: 39px;
		}

		@media only screen and (min-width: 768px) {}

		@media only screen and (min-width: 992px) {
			font-size: 76px;
			line-height: 74px;
		}

		@media only screen and (min-width: 1200px) {
			font-size: 84px;
			line-height: 82px;
		}

		@media only screen and (min-width: 1300px) {
			font-size: 96px;
			line-height: 94px;
		}

		@media only screen and (min-width: 1600px) {
			font-size: 118px;
			line-height: 116px;
			// width: 75%;
		}

		@media only screen and (min-width: 1700px) {
			font-size: 128px;
			line-height: 126px;
			// width: 75%;
		}
	}

	.social-links {
		display: none;

		@media only screen and (min-width: 992px) {
			display: block;
			margin-top: 70px;
			font-size: 20px;
			line-height: 26px;
			text-decoration-line: underline;
			text-transform: uppercase;
			text-align: left;

			.resume-link {
				color: #082ADB;

				&:visited {
					color: #082ADB;
				}
			}

			& a {
				color: #4E4C4C;

				&:visited {
					color: #4E4C4C;
				}
			}
		}
	}

	.mobile-social-links {
		display: flex;
		flex-wrap: wrap;
		margin-top: 70px;
		font-size: 10px;
		line-height: 13px;
		text-decoration-line: underline;
		text-transform: uppercase;
		text-align: left;
		justify-content: center;
		gap: 10px;

		@media only screen and (max-width: 300px) {
			font-size: 7px;
			line-height: 11px;
		}

		.resume-link {
			color: #082ADB;

			&:visited {
				color: #082ADB;
			}
		}

		& a {
			color: #4E4C4C;

			&:visited {
				color: #4E4C4C;
			}
		}

		@media only screen and (min-width: 992px) {
			display: none;
		}
	}

	.photo-album-container {
		display: flex;
		flex-direction: column-reverse;

		@media only screen and (min-width: 992px) {
			display: grid;
			height: 600px;
			margin-top: 120px;
		}
	}

	.photo-caption {
		font-family: "Suisse International Mono";
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.005em;
		color: #767471;
		margin-top: 350px;
		text-align: center;
		justify-content: center;
		align-items: center;

		@media only screen and (min-width: 992px) {
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.005em;
			text-align: left;
			margin-top: 0;
			align-items: initial;
		}
	}

	.pic-caption {
		width: 230px;
		height: 50px;
		margin-top: 20px;

		@media only screen and (min-width: 992px) {
			width: 142px;
			margin-top: 40px;
			height: 100px;
		}

		@media only screen and (min-width: 1200px) {
			width: 240px;
		}
	}

	.pic-nav {
		margin-top: 20px;
		justify-content: center;

		@media only screen and (min-width: 992px) {
			justify-content: initial;
			margin-top: 20px;
			align-items: center;
		}

		@media only screen and (min-width: 1200px) {
			margin-top: 0;
		}
	}

	.photo-album {
		@media only screen and (min-width: 992px) {
			&:hover {
				transform: scale(105%);
			}

			width: 600px;
			height: 500px;
		}

		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s cubic-bezier(1, 0, 0.25, 1);
	}

	.each-photo {
		left: -30px;
		top: 40px;
		text-align: center;
		width: 100vw;
		height: 300px;

		@media only screen and (min-width: 992px) {
			left: 20px;
			top: -45px;
			text-align: center;
			width: 550px;
			height: 550px;
		}

		// @media only screen and (min-width: 1200px) {
		// 	left: 0;
		// 	top: -50px;
		// 	text-align: center;
		// 	width: 700px;
		// 	height: 700px;
		// }
	}

	.p-img {
		max-width: 300px;
		max-height: 300px;
		vertical-align: middle;
		min-height: 50px;
		transition: all 0.3s cubic-bezier(1, 0, 0.25, 1);
		user-select: none;
		// transition: all 0.3s ease-in-out;

		@media only screen and (max-width: 389px) {
			max-width: 250px;
			max-height: 250px;
		}


		@media only screen and (min-width: 992px) {
			min-height: 50px;
			vertical-align: middle;
			max-height: 500px;
			max-width: 550px;
		}

		// @media only screen and (min-width: 1200px) {
		// 	min-height: 50px;
		// 	vertical-align: middle;
		// 	max-height: 700px;
		// 	max-width: 700px;
		// }
	}

	.helper {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.arrow-icon {
		height: 20px;
		width: 12px;
	}

	.what-i-do {
		padding: 100px 0;
		transform: translateY(10%);
		opacity: 0;
		transition: 0.8s all ease-out;

		@media only screen and (min-width: 992px) {
			padding: 150px 0;
		}
	}

	.work-content {
		width: 100%;
		margin-top: 20px;

		@media only screen and (min-width: 992px) {
			width: 750px;
			margin-left: auto;
			margin-right: 50px;
			margin-top: 0;
		}
	}

	.work-text {
		font-family: "Suisse International";
		font-weight: 500;
		letter-spacing: 0.005em;
		font-size: 24px;
		line-height: 31px;
		color: #121212;

		@media only screen and (min-width: 992px) {
			font-size: 44px;
			line-height: 56px;
		}
	}

	.work-sub-text {
		font-family: "Suisse International";
		font-weight: 400;
		font-size: 16px;
		line-height: 23px;
		letter-spacing: 0.005em;
		color: #000000;
		margin-top: 60px;

		@media only screen and (min-width: 992px) {
			font-size: 24px;
			line-height: 31px;
		}
	}

	.why-design {
		transform: translateY(10%);
		opacity: 0;
		transition: 0.8s all ease-out;
		margin-top: 80px;
		padding-top: 300px;

		@media only screen and (min-width: 992px) {
			margin-top: 230px;
		}
	}




	.middle-title {
		transform: translateY(10%);
		opacity: 0;
		transition: 0.8s all ease-out;
		font-family: "Suisse International Condensed";
		font-weight: 700;
		font-size: 45px;
		line-height: 44px;
		letter-spacing: -0.025em;
		text-transform: uppercase;
		color: #151313;
		text-align: left;

		@media only screen and (min-width: 992px) {
			font-size: 110px;
			line-height: 109px;
			width: 100%;
		}

		@media only screen and (min-width: 1200px) {
			font-size: 110px;
			line-height: 109px;
			width: 100%;
		}

		.folk-pen {
			display: inline-block;
			width: 50px;
			height: 25px;
			margin-bottom: 4px;

			@media only screen and (min-width: 992px) {
				width: 90px;
				height: 50px;
				margin-bottom: 10px;
			}


			@media only screen and (min-width: 1200px) {
				width: 100px;
				height: 60px;
				margin-bottom: 5px;
			}
		}
	}

	.folks-cont {
		margin-top: 100px;
	}

	.folkst-content {
		font-family: "Suisse International";
		font-weight: 400;
		font-size: 16px;
		line-height: 23px;
		color: #151313;
		margin-bottom: 30px;

		@media only screen and (min-width: 992px) {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 0.005em;
			width: 750px;
			margin-left: auto;
			margin-right: 50px;
			margin-bottom: 0;
		}

		.work-sub-text {
			margin-top: 0;
		}
	}
}

.years {
	transform: translateY(10%);
	opacity: 0;
	transition: 0.8s all ease-out;
	border-top: 0.5px solid #53514F;
	border-bottom: 0.5px solid #53514F;
	padding: 20px 27px;
	display: flex;
	justify-content: space-between;

	@media only screen and (min-width: 992px) {
		margin: 0 15px;
		// padding-top: 65px;
		// padding-bottom: 65px;
		padding: 65px 117px;
		display: block;
	}

	.desktop-expand {
		display: none !important;

		@media only screen and (min-width: 992px) {
			display: flex !important;
		}
	}

	.mobile-expand {
		display: block;

		@media only screen and (min-width: 992px) {
			display: none;
		}
	}

	.years-content-grid {
		display: flex;
		flex-direction: column;

		@media only screen and (min-width: 992px) {
			display: grid;
			grid-template-columns: 3fr 6fr 1fr;
		}
	}

	.years-label {
		display: flex;
		font-family: "Suisse International Mono";
		font-weight: 500;
		font-size: 10;
		line-height: 13px;
		letter-spacing: 0.005em;
		color: #767471;

		@media only screen and (min-width: 992px) {
			font-size: 24px;
			line-height: 31px;
		}
	}

	.year-content {
		margin-top: 20px;

		@media only screen and (min-width: 992px) {
			margin-top: 0;
		}

		.years-title {
			font-family: "Suisse International";
			font-weight: 400;
			font-size: 20px;
			line-height: 27px;
			letter-spacing: 0.005em;
			color: #151313;

			// margin-top: 15px;

			@media only screen and (min-width: 992px) {
				font-size: 48px;
				line-height: 62px;
			}
		}

		.years-subtitle {
			font-family: "Suisse International Mono";
			font-weight: 700;
			font-size: 10px;
			line-height: 13px;
			letter-spacing: 0.005em;
			color: #151313;
			margin-top: 12px;

			@media only screen and (min-width: 992px) {
				font-size: 16px;
				line-height: 19px;
				margin-top: 30px;
			}
		}

		.years-info {
			font-family: "Suisse International";
			font-weight: 400;
			font-size: 24px;
			line-height: 31px;
			color: #151313;
			margin-top: 30px;

		}
	}

	.years-expand-icon {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		transition: all 0.3s ease-in-out;
		justify-self: end;

		.years-add {
			width: 12px;
			height: 12px;
			background: #8D8B8733;
			padding: 6px;
			border-radius: 50%;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			@media only screen and (min-width: 992px) {
				width: 22px;
				height: 22px;
				padding: 8px;
			}
		}
	}
}

.border-top {
	border-top: 1px solid #53514F;
}

.border-bottom {
	border-bottom: none;
}

.scroll-marquee {
	font-family: "Suisse International Condensed";
	font-weight: 700;
	font-size: 80px;
	line-height: 78px;
	color: #151313;
	position: absolute;
	left: 0;
	width: 99vw;
	overflow: hidden;

	@media only screen and (min-width: 992px) {
		font-size: 130px;
		line-height: 128px;
	}

	.scrollone {
		// overflow-x: auto;
		// overflow-y: hidden;
		scrollbar-width: none;
		-ms-overflow-style: none;

		// transition: all 0.3s ease-out;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.scroll-two {
		// overflow-x: auto;
		// overflow-y: hidden;
		scrollbar-width: none;
		-ms-overflow-style: none;

		// transition: all 0.3s ease-out;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.scroll-icon {
		// width: 94px;
		height: 70px;
		width: 100%;
		margin: 0 10px;
		object-fit: contain;

		@media only screen and (min-width: 992px) {
			height: 110px;
			margin: 0 30px;
		}
	}
}

.animate-to-center {

	// transition: all 2s ease-in-out;
	.folk-footer-content {
		align-items: center;
		height: 100vh;
		transition: all 2s ease-in-out;
	}

	.footer-title {
		font-size: 150px;
		transition: all 2s ease-in-out;
	}
}

.folk-footer {
	margin-top: 75px;
	// transition: all 2s ease-in-out;

	@media only screen and (min-width: 992px) {
		margin-top: 150px;
	}

	.folk-footer-content {
		justify-content: center;
		transition: all 2s ease-in-out;
		// align-items: baseline;

		.footer-icon img {
			width: 34px;
			height: 32px;
			margin-right: 5px;

			@media only screen and (min-width: 768px) {
				width: 70px;
				height: 68px;
				margin-right: 15px;
			}

			@media only screen and (min-width: 992px) {
				width: 100px;
				height: 98px;
				margin-right: 15px;
			}

			@media only screen and (min-width: 1200px) {
				width: 108px;
				height: 106px;
			}
		}

		.footer-title {
			font-family: 'Suisse International Condensed';
			font-style: normal;
			font-weight: 700;
			font-size: 34px;
			line-height: 33px;
			text-transform: uppercase;
			// transition: all 2s ease-in-out;

			@media only screen and (min-width: 768px) {
				font-size: 70px;
				line-height: 69px;
			}

			@media only screen and (min-width: 992px) {
				font-size: 100px;
				line-height: 99px;
			}

			@media only screen and (min-width: 1200px) {
				font-size: 110px;
				line-height: 109px;
			}
		}
	}

	.common-footer {
		margin-top: 75px;
		// transition: all 2s ease-in-out;

		@media only screen and (min-width: 992px) {
			margin-top: 150px;
		}

		.footer-nav {
			justify-content: center;
			align-items: center;

			.nav-links {
				font-family: 'Suisse International';
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.005em;
				color: #8D8B87;
				border-radius: 34px;
				background: #D0C9BD;
				padding: 4px 0;
				width: 97px;
				user-select: none;
				cursor: pointer;

				@media only screen and (min-width: 992px) {
					font-size: 20px;
					line-height: 26px;
					width: 117px;
				}
			}

			.separator {
				margin: 0 21px;

				@media only screen and (min-width: 992px) {
					margin: 0 41px;
				}

				& img {
					width: 6px;
					height: 6px;
				}
			}
		}
	}

	.footer-info {
		margin-top: 50px;
		padding-bottom: 50px;
		font-family: 'Suisse International Mono';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.005em;
		color: #8D8B87;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		// transition: all 2s ease-in-out;

		@media only screen and (min-width: 992px) {
			flex-direction: row;
			font-size: 12px;
			line-height: 15px;
		}

		& div {
			padding: 0;

			@media only screen and (min-width: 992px) {
				padding: 0 1px;
			}
		}

		.copyright {
			width: 10px;
			height: 10px;
		}
	}

	.fixed-pos {
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		// transition: all 2s ease-in-out;
	}

	.total-center {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.full-height {
	height: 100vh;
}
</style>