<template>
    <div class="cloud-gaming case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Cloud
                </div>
                <div>
                    Gaming
                </div>
                <div>
                    Platform
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Cloud gaming platform
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Visualisation
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2020
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Evolving game play: A study on cloud-enhanced modular gaming platform and controllers.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                Modular design has seen limited success in the telecom sector, but in the gaming community,
                                it promises immense potential. A modular gaming controller, empowered by cloud computing,
                                offers gamers both customizability and future-proofing. The controller's components can be
                                upgraded as per evolving gamer preferences, while the cloud ensures high performance,
                                offloading processing demands. This combination guarantees consistent, reliable performance
                                and the freedom for personalization. This revolutionary approach offers gamers a tailored,
                                enriched experience, adapting to unique needs and harnessing advancements in cloud computing
                                and game development. A cloud-powered modular gaming controller is poised to redefine the
                                gaming landscape
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Phase One
                </div>
                <div class="overview-description mobile">
                    A modular handheld console <span class="color-text">system that offers gamers a hybrid configuration of
                        gaming accessories,</span> for
                    a better user experience.
                </div>
                <div class="overview-description desktop">
                    <div>
                        A modular handheld console
                    </div>
                    <div class="color-text">
                        system that offers gamers a
                    </div>
                    <div class="color-text">
                        hybrid configuration of
                    </div>
                    <div>
                        <span class="color-text">gaming accessories</span>, for a
                    </div>
                    <div>
                        better user experience.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 padded-container parallax-in">
            <div class="image-container">
                <img class="image1" src="../../assets/design/cloud-gaming/image1.png" alt="Fig 1">
            </div>
            <div class="image-caption">
                <div>
                    Fig 1:
                </div>
                <div class="mobile">
                    Simply snap in your phone and play controller-supported games, charge fast via USB-C. The portable size
                    makes premium cloud computing supported gaming, accessible instantly.
                </div>
                <div class="desktop">
                    <div>
                        Simply snap in your phone and play controller-
                    </div>
                    <div>
                        supported games, charge fast via USB-C. The
                    </div>
                    <div>
                        portable size makes premium cloud computing
                    </div>
                    <div>
                        supported gaming, accessible instantly.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="title">
                A cloud-powered modular gaming controller removes constraints, like limited processor upgradeability,
                enhancing the gaming experience and future-proofing the controller through advancements in cloud computing
                and game development.
            </div>
            <div class="sub-title title">
                Materials: PCR resins, HDPE (High-Density Polyethylene), Liquid silicone, Acrylic
            </div>
        </div>
        <div class="section-3 padded-container parallax-in">
            <div class="image-container">
                <img class="image2" src="../../assets/design/cloud-gaming/image2.png" alt="product image">
            </div>
        </div>
        <div class="section-4 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image3" src="../../assets/design/cloud-gaming/image3.gif" alt="Fig 2">
                    </div>
                    <div class="image-container">
                        <img class="image4" src="../../assets/design/cloud-gaming/image4.gif" alt="Fig 2">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 2:
                        </div>
                        <div class="mobile">
                            Research session and co creation with gamers
                        </div>
                        <div class="desktop">
                            <div>
                                Research session and co
                            </div>
                            <div>
                                creation with gamers
                            </div>
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo1 = !showInfo1;" class="readmore relative">
                            <span v-if="showInfo1">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo1" class="additional-info two-content-section">
                                <div class="para-spacer sub-text">
                                    In conversations with gamers, we found that current keyboard layouts are satisfactory
                                    but not optimal. They expressed a desire for customizable key mapping to better fit
                                    their unique gaming styles. Additionally, they indicated interest in extended features
                                    that could enhance the functionality of the product.
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 parallax-in">
            <div class="image-container">
                <img class="image5" src="../../assets/design/cloud-gaming/image5.png" alt="Fig 3-5">
            </div>
            <div class="padded-container image-caption">
                <div>
                    Fig 3-5:
                </div>
                <div class="mobile">
                    Controller design emphasizes ergonomic comfort, incorporating rounded aesthetics for a snug grip.
                </div>
                <div class="desktop">
                    <div>
                        Controller design emphasizes
                    </div>
                    <div>
                        ergonomic comfort, incorporating
                    </div>
                    <div>
                        rounded aesthetics for a snug grip.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 padded-container parallax-in">
            <div class="image-container">
                <img class="image6" src="../../assets/design/cloud-gaming/image6.png" alt="Fig 6">
            </div>
            <div class="image-caption">
                <div>
                    Fig 6:
                </div>
                <div class="mobile">
                    The internals are easily accessible from the back panel prioritising reparability
                </div>
                <div class="desktop">
                    <div>
                        The internals are easily accessible from
                    </div>
                    <div>
                        the back panel prioritising reparability
                    </div>
                </div>
            </div>
        </div>
        <div class="section-7 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image7" src="../../assets/design/cloud-gaming/image7.png" alt="Fig 7">
                    </div>
                    <div class="image-container">
                        <img class="image8" src="../../assets/design/cloud-gaming/image8.png" alt="Fig 8">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 7-8:
                        </div>
                        <div class="mobile">
                            Featuring a touch controller with flexible key mapping while the AR module supports augmented
                            gaming experiences.
                        </div>
                        <div class="desktop">
                            <div>
                                Featuring a touch controller with flexible key
                            </div>
                            <div>
                                mapping while the AR module supports augmented
                            </div>
                            <div>
                                gaming experiences.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-8 parallax-in">
            <div class="image-container">
                <img class="image9" src="../../assets/design/cloud-gaming/image9.png" alt="Fig 9">
            </div>
            <div class="padded-container image-caption">
                <div>
                    Fig 9:
                </div>
                <div class="mobile">
                    The module offerings extend beyond controllers to industry standard digital sound modules to give you
                    portable entertainment system. No more boring long journeys !
                </div>
                <div class="desktop">
                    <div>
                        The module offerings extend beyond controllers
                    </div>
                    <div>
                        to industry standard digital sound modules to
                    </div>
                    <div>
                        rgive you portable entertainment system. No
                    </div>
                    <div>
                        more boring long journeys !
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="image-container">
                <img class="image10" src="../../assets/design/cloud-gaming/image10.png" alt="Fig 10">
            </div>
            <div class="image-caption">
                <div>
                    Fig 10:
                </div>
                <div>
                    Ideation sketches
                </div>
            </div>
        </div>
        <div class="section-10 padded-container parallax-in">
            <div class="image-container">
                <img class="image11" src="../../assets/design/cloud-gaming/image11.png" alt="Fig 11">
            </div>
            <div class="image-caption">
                <div>
                    Fig 11:
                </div>
                <div class="mobile">
                    These hybrid modules allows gamers to switch out modules to tweak their handsets to their liking and
                    experience
                </div>
                <div class="desktop">
                    <div>
                        These hybrid modules allows gamers to
                    </div>
                    <div>
                        switch out modules to tweak their
                    </div>
                    <div>
                        handsets to their liking and experience
                    </div>
                </div>
            </div>
        </div>

        <div class="section-11 archive-section parallax-in">
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'CloudGaming',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },

        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.cloud-gaming {
    .landing-section-mobile {
        background: url('~@/assets/design/cloud-gaming/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/cloud-gaming/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #74B3E2;
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image1 {
            width: 100%;
        }
    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }
    }

    .section-3 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image2 {
            width: 100%;
        }
    }

    .section-4 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image-section {
            justify-content: space-between;
        }

        .image3,
        .image4 {
            height: 100%;
            width: 100%;
            @media only screen and (min-width: 1700px) {
                width: auto;
            }
            object-fit: cover;
        }
    }

    .section-5 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 359px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }

        .image5 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image6 {
            width: 100%;
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image7,
        .image8 {
            width: 100%;
        }
    }

    .section-8 {
        margin-top: 90px;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image9 {
            width: 100%;
        }
    }

    .section-9 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image10 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image11 {
            width: 100%;
        }
    }

    .section-11 {
        background: url('~@/assets/design/cloud-gaming/image12.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .common-footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        margin-top: 0;
    }
}
</style>