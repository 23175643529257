<template>
    <div class="kohler-compost case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    kholer
                </div>
                <div>
                    compost
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Kohler Compost
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) The Ask
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Lead Research & Strategy
                            </div>
                            <div>
                                Interaction Design
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2021
                            </div>
                        </div>
                        <div class="team">
                            <div>
                                Team:
                            </div>
                            <div>
                                Matthew Bencourt
                            </div>
                            <div>
                                Gokul Beeda
                            </div>
                            <div>
                                Nick Abbott
                            </div>
                        </div>
                        <div class="client">
                            <div>
                                Client:
                            </div>
                            <div>
                                Kohler Design
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Create an appliance-based composting platform that enables consumers to conveniently reduce food
                        waste
                        at home
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer">
                                Sprout collaborated with Kohler to create their first product offering in the composting
                                market.
                                The ask was to develop a research-backed product line that addressed market gaps and
                                established
                                Kohler as the market leader.
                                <div class="m-5-top">
                                    I led the Sprout team through design trends, market status, and user needs and was
                                    tasked
                                    with
                                    researching to find opportunity areas and gaps in the composting market. I further
                                    assisted
                                    in
                                    the industrial design process translating research insights into hardware and digital
                                    solutions.
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    <div>
                        Kohler Compost
                    </div>
                    <div>
                        platforms <span class="color-text">help</span>
                    </div>
                    <div class="color-text">
                        modern families
                    </div>
                    <div class="color-text">
                        conveniently
                    </div>
                    <div class="color-text">
                        reduce food
                    </div>
                    <div>
                        <span class="color-text">waste</span> from their
                    </div>
                    <div>
                        kitchens. Without
                    </div>
                    <div>
                        the tensions of
                    </div>
                    <div>
                        traditional
                    </div>
                    <div>
                        compost process.
                    </div>
                </div>
                <div class="overview-description desktop">
                    <div>
                        Kohler Compost platforms
                    </div>
                    <div class="color-text">
                        help modern families
                    </div>
                    <div class="color-text">
                        conveniently reduce food
                    </div>
                    <div>
                        <span class="color-text">waste</span> from their kitchens.
                    </div>
                    <div>
                        Without the tensions of
                    </div>
                    <div>
                        traditional compost process.
                    </div>
                </div>
            </div>
            <div class="section-2 parallax-in">
                <div class="resp-flex">
                    <div class="label">
                        (03) Problem
                    </div>
                    <div class="problem-content-section">
                        <div class="sub-text">
                            Wasted food is worse than you think and most of it comes from our kitchens . Greenhouse gas from
                            food waste does more damage to the planet than the entire global airline industry . We have to
                            stop dumping what we can’t eat into the trash
                        </div>
                    </div>
                </div>
                <div class="section-2-imgs d-flex">
                    <div class="image1-cont images-cont">
                        <img class="image1 images" src="../../assets/design/kohler-compost/image1.png" alt="landfill">
                        <div class="img-caption">
                            <div>
                                55.9%
                            </div>
                            <div>
                                goes to landfill*
                            </div>
                        </div>
                        <div class="caption desktop image-condition">
                            <div>
                                *Remaining 40% is managed in other
                            </div>
                            <div>
                                ways such as controlled combustion,
                            </div>
                            <div>
                                animal feed, and donation.
                            </div>
                        </div>
                    </div>
                    <div class="image2-cont images-cont">
                        <img class="image2 images" src="../../assets/design/kohler-compost/image2.png" alt="recycled">
                        <div class="img-caption">
                            <div>
                                4.1%
                            </div>
                            <div>
                                recycled
                            </div>
                        </div>
                    </div>
                </div>
                <div class="caption image-condition mobile">
                    *Remaining 40% is managed in other ways such as controlled combustion, animal feed, and donation.
                </div>
            </div>
            <div class="section-3 parallax-in">
                <div class="title mobile">
                    KOHLER compost is family of integrated kitchen appliances that process and condense your household’s
                    organic waste. The products are odor-free, pest-free solutions that are cleaner than your trash or
                    compost bin.
                </div>
                <div class="title desktop">
                    <div>
                        KOHLER compost is family of integrated kitchen appliances that
                    </div>
                    <div>
                        process and condense your household’s organic waste. The
                    </div>
                    <div>
                        products are odor-free, pest-free solutions that are cleaner than
                    </div>
                    <div>
                        your trash or compost bin.
                    </div>
                </div>
                <div class="image3-container">
                    <img class="image3" src="../../assets/design/kohler-compost/image3.png" alt="fig 1">
                    <div class="image-caption">
                        <div>
                            Fig 1:
                        </div>
                        <div class="mobile">
                            Kohler offer two product archetypes (L) under-sink unit and (R) standalone unit
                        </div>
                        <div class="desktop">
                            <div>
                                Kohler offer two product
                            </div>
                            <div>
                                archetypes (L) under-sink
                            </div>
                            <div>
                                unit and (R) standalone unit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-4 parallax-in">
            <div class="title padded-container">
                Standalone Unit
            </div>
            <div class="full-image">
                <img class="image4" src="../../assets/design/kohler-compost/image4.png" alt="standalone unit">
            </div>
            <div class="padded-container">
                <div class="resp-flex">
                    <div class="image-caption margintop-150">
                        <div>
                            Fig 2:
                        </div>
                        <div>
                            Standalone-Unit
                        </div>
                    </div>
                    <div class="title desktop margintop-150">
                        <div>
                            The standalone is built with continuous
                        </div>
                        <div>
                            compost cycle and mid-high volume food
                        </div>
                        <div>
                            waste for large spaces. The platform is
                        </div>
                        <div>
                            flexible to support small to large families.
                        </div>
                    </div>
                </div>

                <div class="title mobile mt-40">
                    <div>
                        The standalone is built with
                    </div>
                    <div>
                        continuous compost cycle and
                    </div>
                    <div>
                        mid-high volume food waste for
                    </div>
                    <div>
                        large spaces. The platform is
                    </div>
                    <div>
                        flexible to support small to large
                    </div>
                    <div>
                        families.
                    </div>
                </div>
                <div class="image-container mt-70">
                    <img class="image5" src="../../assets/design/kohler-compost/image5.png" alt="figure 3">
                    <div class="image-caption">
                        <div>
                            Fig 3:
                        </div>
                        <div class="mobile">
                            The product color material and finish uses a warm tone of stainless steel to blend the compost
                            unit into the kitchen landscape.
                        </div>
                        <div class="desktop">
                            <div>
                                The product color material and finish uses a
                            </div>
                            <div>
                                warm tone of stainless steel to blend the
                            </div>
                            <div>
                                compost unit into the kitchen landscape.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image6" src="../../assets/design/kohler-compost/image6.png" alt="figure 4">
                    </div>
                    <div class="image-container">
                        <img class="image7" src="../../assets/design/kohler-compost/image7.png" alt="figure 5">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            <div>
                                Fig 4-5:
                            </div>
                            <div>
                                (L) Compressed puck technology
                            </div>
                            <div>
                                (R) Hardware user interface
                            </div>
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo2 = !showInfo2;" class="readmore relative">
                            <span v-if="showInfo2">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo2 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo2" class="additional-info two-content-section">
                                <div class="para-spacer sub-text">
                                    Composting appliance compress the end product into a small
                                    puck. This capitalises on both convenience and space efficiency. The puck press
                                    maximizes volume reduction, which means you only have to empty the device once every 3
                                    months.
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 parallax-in">
            <div class="padded-container title">
                Under-sink Unit
            </div>
            <div class="full-image">
                <img class="image8" src="../../assets/design/kohler-compost/image8.png" alt="figure 6">
            </div>
            <div class="resp-flex padded-container">
                <div class=" image-caption margintop-150">
                    <div>
                        Fig 6:
                    </div>
                    <div>
                        form iteration of the “island concept”
                    </div>
                </div>
                <div class="title desktop margintop-150">
                    <div>
                        The drain integrated unit is built to
                    </div>
                    <div>
                        replace your garbage disposal and
                    </div>
                    <div>
                        support any volume of food waste
                    </div>
                    <div>
                        and family size.
                    </div>
                </div>
            </div>

        </div>
        <div class="section-7 padded-container parallax-in">
            <div class="title mobile">
                <div>
                    The drain integrated unit is built to
                </div>
                <div>
                    replace your garbage disposal and
                </div>
                <div>
                    support any volume of food waste
                </div>
                <div>
                    and family size.
                </div>
            </div>
            <div class="image-container mt-70">
                <img class="image9" src="../../assets/design/kohler-compost/image9.png" alt="figure 7">
                <div class="image-caption">
                    <div>
                        Fig 7:
                    </div>
                    <div class="mobile">
                        The product form and silhouette is optimised to use less space.
                    </div>
                    <div class="desktop">
                        <div>
                            The product form and
                        </div>
                        <div>
                            silhouette is optimised
                        </div>
                        <div>
                            to use less space.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-8 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image10" src="../../assets/design/kohler-compost/image10.png" alt="figure 8">
                    </div>
                    <div class="image-container">
                        <img class="image11" src="../../assets/design/kohler-compost/image11.png" alt="figure 9">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            <div>
                                Fig 8-9:
                            </div>
                            <div>
                                (L) Drain light ring
                            </div>
                            <div>
                                (R) Drain integration
                            </div>
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo3 = !showInfo3;" class="readmore relative">
                            <span v-if="showInfo3">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo3 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo3" class="additional-info two-content-section-2">
                                <div class="para-spacer sub-text">
                                    The drain integrated unit is accompanied with a light ring around the sink hole to
                                    convey the composter on and off mode. The product is plumbed into the sink, and it
                                    automatically separates food waste from drain water. A drain-integrated solution means
                                    you don’t have to change your routine.
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="image-container desktop">
                <img class="image25" src="../../assets/design/kohler-compost/image25.png" alt="figure 10-12">
            </div>
            <div class="image-container mobile">
                <img class="image12" src="../../assets/design/kohler-compost/image12.png" alt="figure 10-12">
                <img class="image13" src="../../assets/design/kohler-compost/image13.png" alt="figure 10-12">
                <img class="image14" src="../../assets/design/kohler-compost/image14.png" alt="figure 10-12">
            </div>
            <div class="image-caption">
                <div>
                    Fig 10-12:
                </div>
                <div class="mobile">
                    The island composter concept, unselected concept
                </div>
                <div class="desktop">
                    <div>
                        The island composter
                    </div>
                    <div>
                        concept, unselected concept
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-10 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image15" src="../../assets/design/kohler-compost/image15.png" alt="figure 13">
                    </div>
                    <div class="image-container">
                        <img class="image16" src="../../assets/design/kohler-compost/image16.png" alt="figure 14">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            <div>
                                Fig 13-14:
                            </div>
                            <div>
                                (L) Hardware UI
                            </div>
                            <div>
                                (R) Product vents
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-11 parallax-in">
            <div class="image-container">
                <img class="image17" src="../../assets/design/kohler-compost/image17.png" alt="figure 15">
            </div>
            <div class="image-caption">
                <div>
                    Fig 15:
                </div>
                <div>
                    The experience across app and hardware enables seamless interaction between hardware and digital
                    platforms, providing its user with the info to know the progress of their compost cycles.
                </div>
            </div>
        </div>
        <div class="image-container">
            <img class="image18 parallax-in" src="../../assets/design/kohler-compost/image18.png" alt="product image">
        </div>
        <div class="section-12 takeaways-section padded-container parallax-in">
            <div class="takeaways">
                Takeaways
            </div>
            <div class="takeaway-text mobile">
                <div>
                    I learned how to lead research and
                </div>
                <div>
                    find novel insights and execute
                </div>
                <div>
                    them to develop thoughtful
                </div>
                <div>
                    hardware that takes into account
                </div>
                <div>
                    the behaviour of different
                </div>
                <div>
                    household archetypes.
                </div>
            </div>
            <div class="takeaway-text desktop">
                <div>
                    I learned how to lead research and find novel
                </div>
                <div>
                    insights and execute them to develop thoughtful
                </div>
                <div>
                    hardware that takes into account the behaviour
                </div>
                <div>
                    of different household archetypes.
                </div>
            </div>
        </div>
        <div class="archive-section">
            <div class="archive-title-section">
                <div class="archive-title">
                    Archive
                </div>
            </div>
            <div class="archive-text padded-container mobile">
                <div>
                    Our team explored kitchen
                </div>
                <div>
                    habits via research questionnaires, and
                </div>
                <div>
                    tracked six families' food waste
                </div>
                <div>
                    habits for a week to understand
                </div>
                <div>
                    peoples behaviour. Using insights
                </div>
                <div>
                    from these activities we
                </div>
                <div>
                    established product guidelines and
                </div>
                <div>
                    four user archetypes with detailed
                </div>
                <div>
                    psychographics.
                </div>
            </div>
            <div class="archive-text padded-container desktop">
                <div>
                    Our team explored kitchen habits via research questionnaires, and
                </div>
                <div>
                    tracked six families' food waste habits for a week to understand
                </div>
                <div>
                    peoples behaviour. Using insights from these activities we
                </div>
                <div>
                    established product guidelines and four user archetypes with
                </div>
                <div>
                    detailed psychographics.
                </div>
            </div>
            <div class="carousel-section">
                <div class="padded-container">
                    <div class="archive-caption">
                        Research Insights
                    </div>
                </div>
                <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide">
                    <Slide @click="moveSlide" v-for="  (      slide, index      )   in         archiveImages        "
                        :key="index">
                        <img class="archive-images" :src="require(`../../assets/design/kohler-compost/${slide.src}.png`)"
                            :alt="`archive_${index}`">
                    </Slide>
                    <template #addons>
                        <!-- <Navigation /> -->
                        <!-- <Pagination /> -->
                    </template>
                </Carousel>
            </div>
            <div class="archive-sub margintop-100">
                <div class="image-container">
                    <div class="padded-container">
                        <div class="archive-caption mobile">
                            <div>
                                Product archetypes
                            </div>
                            <div>
                                explorations and testing
                            </div>
                        </div>
                        <div class="archive-caption desktop">
                            Product archetypes explorations and testing
                        </div>
                    </div>
                    <img class="image19 parallax-in" src="../../assets/design/kohler-compost/image19.png"
                        alt="product image">
                </div>
            </div>
            <div class="archive-sub2 margintop-100 parallax-in">
                <div class="image-container">
                    <img class="image20" src="../../assets/design/kohler-compost/image20.png" alt="product image">
                    <div class="archive-caption">
                        <div>
                            Fig 1:
                        </div>
                        <div class="mobile">
                            Initial sketch of drain integrated unit
                        </div>
                        <div class="desktop">
                            <div>
                                Initial sketch of drain
                            </div>
                            <div>
                                integrated unit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub3 padded-container margintop-100 parallax-in">
                <div class="image-container">
                    <img class="image21" src="../../assets/design/kohler-compost/image21.png" alt="product image">
                    <div class="archive-caption">
                        <div class="mobile">
                            Fig 2:
                        </div>
                        <div class="mobile">
                            ethnographic research: Family food waste habits and patterns
                        </div>
                        <div class="desktop image21-extrapadding">
                            <div>
                                Fig 2:
                            </div>
                            <div>
                                ethnographic research: Family food waste habits
                            </div>
                            <div>
                                and patterns
                            </div>
                        </div>
                    </div>
                </div>
                <div class="image-container margintop-100">
                    <img class="image22" src="../../assets/design/kohler-compost/image22.png" alt="product image">
                    <div class="archive-caption">
                        <div>
                            Fig 3:
                        </div>
                        <div>
                            Product architectures explorations
                        </div>
                    </div>
                </div>
                <div class="image-container margintop-100">
                    <img class="image23" src="../../assets/design/kohler-compost/image23.png" alt="product image">
                    <div class="archive-caption">
                        <div>
                            Fig 4:
                        </div>
                        <div>
                            Form language iterations and development
                        </div>
                    </div>
                </div>
                <div class="image-container margintop-100">
                    <img class="image24" src="../../assets/design/kohler-compost/image24.png" alt="product image">
                    <div class="archive-caption">
                        <div>
                            Fig 5:
                        </div>
                        <div class="mobile">
                            Hardware user interface design and placement explorations
                        </div>
                        <div class="desktop">
                            <div>
                                Hardware user interface design and placement
                            </div>
                            <div>
                                explorations
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-footer parallax-in">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
    name: 'KohlerCompost',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            archiveImages: [
                {
                    src: 'archive1'
                },
                {
                    src: 'archive2'
                },
                {
                    src: 'archive3'
                },
                {
                    src: 'archive4'
                }
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide() {
            this.currentSlide++;
        },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.kohler-compost {

    .landing-section-mobile {
        background: url('~@/assets/design/kohler-compost/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/kohler-compost/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    .section-2 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .resp-flex {
            justify-content: space-between;

            .problem-content-section {
                width: 100%;

                @media only screen and (min-width: 992px) {
                    width: 335.894px;
                }

                @media only screen and (min-width: 1200px) {
                    width: 433.753px;
                }

                @media only screen and (min-width: 1500px) {
                    width: 512.678px;
                }

                @media only screen and (min-width: 1700px) {
                    width: 584px;
                }

                @media only screen and (min-width: 2000px) {
                    width: 692.661px;
                }

                @media only screen and (min-width: 3839px) {
                    width: 1298.094px;
                }
            }
        }

        .image-condition {
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 218px;
            }

            @media only screen and (min-width: 1200px) {
                width: 281px;
            }

            @media only screen and (min-width: 1500px) {
                width: 332px;
            }

            @media only screen and (min-width: 1700px) {
                width: 379px;
            }

            @media only screen and (min-width: 2000px) {
                width: 449px;
            }

            @media only screen and (min-width: 3839px) {
                width: 842px;
            }
        }

        .section-2-imgs {
            justify-content: space-between;
            margin-top: 80px;

            .img-caption {
                font-family: 'Suisse International';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.005em;
                color: #151313;

                @media only screen and (min-width: 992px) {
                    font-size: 27px;
                    line-height: 33px;
                    letter-spacing: 0.138px;
                }

                @media only screen and (min-width: 1200px) {
                    font-size: 35px;
                    line-height: 40px;
                    letter-spacing: 0.178px;
                }

                @media only screen and (min-width: 1500px) {
                    font-size: 42px;
                    line-height: 48px;
                    letter-spacing: 0.211px;
                }

                @media only screen and (min-width: 1700px) {
                    font-size: 48px;
                    line-height: 53px;
                    letter-spacing: 0.24px;
                }

                @media only screen and (min-width: 2000px) {
                    font-size: 56px;
                    line-height: 62px;
                    letter-spacing: 0.285px;
                }

                @media only screen and (min-width: 3839px) {
                    font-size: 106px;
                    line-height: 110px;
                    letter-spacing: 0.533px;
                }
            }

            .images {
                width: 100%;
            }

            .images-cont {
                width: 42%;
            }

        }
    }

    .section-3 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px * 2;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px * 2;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px * 2;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 219px * 2;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px * 2;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px * 2;
        }

        .image3 {
            width: 100%;
            height: 100%;
            // height: 265px;
            margin-top: 0;

            @media only screen and (min-width: 992px) {
                margin-top: 55px;
                // height: 693.643px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 71px;
                // height: 895.73px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 86px;
                // height: 1058.715px;
            }

            @media only screen and (min-width: 1700px) {
                // height: 1206px;
                margin-top: 86px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 110px;
                // height: 1430.392px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 213px;
                // height: 2680.654px;
            }
        }
    }

    .section-4 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 359px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }

        .image4 {
            width: 100%;
            height: 100%;
        }

        .image5 {
            width: 100%;
            height: 100%;
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image6,
        .image7 {
            width: 100%;
            height: 100%;
            // height: 215px;
            object-fit: cover;
            object-position: bottom;

            // @media only screen and (min-width: 992px) {
            //     height: 337.619px;
            // }

            // @media only screen and (min-width: 1200px) {
            //     height: 435.981px;
            // }

            // @media only screen and (min-width: 1500px) {
            //     height: 515.312px;
            // }

            // @media only screen and (min-width: 1700px) {
            //     height: 587px;
            // }

            // @media only screen and (min-width: 2000px) {
            //     height: 696.219px;
            // }

            // @media only screen and (min-width: 3839px) {
            //     height: 1304.763px;
            // }
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 229px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image8 {
            width: 100%;
            height: 100%;
            // height: 250px;

            // @media only screen and (min-width: 992px) {
            //     height: 644.755px;
            // }

            // @media only screen and (min-width: 1200px) {
            //     height: 832.598px;
            // }

            // @media only screen and (min-width: 1500px) {
            //     height: 984.096px;
            // }

            // @media only screen and (min-width: 1700px) {
            //     height: 1121px;
            // }

            // @media only screen and (min-width: 2000px) {
            //     height: 1329.577px;
            // }

            // @media only screen and (min-width: 3839px) {
            //     height: 2491.719px;
            // }
        }
    }

    .section-7 {
        margin-top: 40px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 263px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image9 {
            width: 100%;
            height: 100%;
            // height: 216px;

            // @media only screen and (min-width: 992px) {
            //     height: 496.363px;
            // }

            // @media only screen and (min-width: 1200px) {
            //     height: 640.974px;
            // }

            // @media only screen and (min-width: 1500px) {
            //     height: 757.605px;
            // }

            // @media only screen and (min-width: 1700px) {
            //     height: 863px;
            // }

            // @media only screen and (min-width: 2000px) {
            //     height: 1023.573px;
            // }

            // @media only screen and (min-width: 3839px) {
            //     height: 1918.245px;
            // }

            // object-fit: contain;
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 167px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image10,
        .image11 {
            width: 100%;
            // height: 215px;
            height: 100%;
            object-fit: cover;
            object-position: bottom;

            @media only screen and (min-width: 992px) {
                height: 100%;
            }

            // @media only screen and (min-width: 1200px) {
            //     height: 365.422px;
            // }

            // @media only screen and (min-width: 1500px) {
            //     height: 431.914px;
            // }

            // @media only screen and (min-width: 1700px) {
            //     height: 492px;
            // }

            // @media only screen and (min-width: 2000px) {
            //     height: 583.543px;
            // }

            // @media only screen and (min-width: 3839px) {
            //     height: 1093.6px;
            // }
        }
    }

    .section-9 {
        margin-top: 90px;
        height: auto;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 167px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image12 {
            width: 100%;
        }

        .image13 {
            width: 100%;
        }

        .image14 {
            width: 100%;
        }

        .image25 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 283px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 383px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 580px;
        }

        .image15 {
            width: 100%;
            height: 100%;
            // height: 328px;
            object-fit: cover;
            object-position: bottom;

            // @media only screen and (min-width: 1700px) {
            //     height: 756px;
            // }
        }

        .image16 {
            width: 100%;
            height: 100%;
            // height: 346px;
            object-fit: cover;
            object-position: bottom;

            // @media only screen and (min-width: 1700px) {
            //     height: 756px;
            // }
        }
    }

    .section-11 {
        margin-top: 122px;
        padding: 0 72px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            padding: 0;
            max-width: 234px;
            margin: 180px auto 0 auto;
        }

        @media only screen and (min-width: 1200px) {
            max-width: 301px;
            margin: 232px auto 0 auto;
        }

        @media only screen and (min-width: 1500px) {
            max-width: 276px;
            margin: 180px auto 0 auto;
        }

        @media only screen and (min-width: 1700px) {
            max-width: 406px;
            margin: 237px auto 0 auto;
        }

        @media only screen and (min-width: 2000px) {
            max-width: 481px;
            margin: 371px auto 0 auto;
        }

        @media only screen and (min-width: 3839px) {
            max-width: 902.442px;
            margin: 695px auto 0 auto;
        }

        .image17 {
            width: 100%;
            height: 100%;
            // height: 376px;
            object-fit: contain;
            margin-bottom: 28px;

            @media only screen and (min-width: 992px) {
                // height: 473px;
            }

            @media only screen and (min-width: 1200px) {
                // height: 612.008px;
            }

            @media only screen and (min-width: 1500px) {
                // height: 723.368px;
                // height: 100%;
            }


            @media only screen and (min-width: 1700px) {
                // height: 824px;
            }
        }
    }

    .image18 {
        width: 100%;
        // height: 226px;
        height: 100%;
        margin-top: 100px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        // object-fit: cover;
        // object-position: bottom;
        // @media only screen and (min-width: 1700px) {
        //     height: 1043px;
        // }
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 283px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 383px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 580px;
        }
    }

    .archive-section {
        background: #121212;

        .archive-title-section {
            padding: 52px 0 35px 0;
            text-align: center;

            @media only screen and (min-width: 992px) {
                padding: 36px 0;
            }

            @media only screen and (min-width: 1200px) {
                padding: 46px 0;
            }

            @media only screen and (min-width: 1500px) {
                padding: 55px 0;
            }

            @media only screen and (min-width: 1700px) {
                padding: 63px 0;
            }

            @media only screen and (min-width: 2000px) {
                padding: 74px 0;
            }

            @media only screen and (min-width: 3839px) {
                padding: 140px 0;
            }

            .archive-title {
                font-family: 'Suisse International Condensed';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 47px;
                text-align: center;
                text-transform: uppercase;
                color: #F4F2EE;

                @media only screen and (min-width: 992px) {
                    font-size: 64px;
                    line-height: 98.7%;
                    letter-spacing: -1.582px;
                }

                @media only screen and (min-width: 1200px) {
                    font-size: 82px;
                    line-height: 98.7%;
                    letter-spacing: -2.043px;
                }

                @media only screen and (min-width: 1500px) {
                    font-size: 96px;
                    line-height: 98.7%;
                    letter-spacing: -2.414px;
                }

                @media only screen and (min-width: 1700px) {
                    font-size: 110px;
                    line-height: 98.7%;
                    letter-spacing: -2.75px;
                }

                @media only screen and (min-width: 2000px) {
                    font-size: 130px;
                    line-height: 98.7%;
                    letter-spacing: -3.262px;
                }

                @media only screen and (min-width: 3839px) {
                    font-size: 244px;
                    line-height: 98.7%;
                    letter-spacing: -6.113px;
                }
            }
        }

        .archive-text {
            font-family: 'Suisse International';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #FAF9F7;
            margin-top: 80px;

            @media only screen and (min-width: 992px) {
                font-size: 28px;
                line-height: 27px;
                letter-spacing: 0.138px;
                margin-top: 105px;
            }

            @media only screen and (min-width: 1200px) {
                font-size: 32px;
                line-height: 31px;
                letter-spacing: 0.178px;
                margin-top: 136px;
            }

            @media only screen and (min-width: 1280px) {
                font-size: 36px;
                line-height: 35px;
                letter-spacing: 0.178px;
                margin-top: 136px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 42px;
                line-height: 41px;
                letter-spacing: 0.211px;
                margin-top: 162px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 48px;
                line-height: 54px;
                letter-spacing: 0.24px;
                margin-top: 240px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 56px;
                line-height: 55px;
                letter-spacing: 0.285px;
                margin-top: 240px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 106px;
                line-height: 105px;
                letter-spacing: 0.533px;
                margin-top: 400px;
            }
        }

        .archive-caption {
            font-family: 'Suisse International Mono';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.005em;
            color: #A7A49F;
            text-align: left;
            margin-top: 8px;

            @media only screen and (min-width: 992px) {
                font-size: 11px;
                line-height: 143.7%;
                margin-top: 21px;
            }

            @media only screen and (min-width: 1200px) {
                font-size: 16px;
                line-height: 143.7%;
                margin-top: 27px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 9px;
                line-height: 143.7%;
                margin-top: 30px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 16px;
                line-height: 143.7%;
                margin-top: 30px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 16px;
                line-height: 143.7%;
                letter-spacing: 0.077px;
                margin-top: 43px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 28px;
                line-height: 143.7%;
                letter-spacing: 0.144px;
                margin-top: 82px;
            }
        }

        .archive-images {
            width: 100%;
            height: 100%;
            // height: 532px;
            object-fit: contain;
        }


        .carousel-section {
            margin-top: 70px;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 283px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 383px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 580px;
            }

            .carousel {
                margin-top: 24px;

                @media only screen and (min-width: 992px) {
                    margin-top: 30px;
                }

                @media only screen and (min-width: 1200px) {
                    margin-top: 40px;
                }

                @media only screen and (min-width: 1500px) {
                    margin-top: 50px;
                }

                @media only screen and (min-width: 1700px) {
                    margin-top: 63px;
                }

                @media only screen and (min-width: 2000px) {
                    margin-top: 93px;
                }

                @media only screen and (min-width: 3839px) {
                    margin-top: 200px;
                }
            }

            .carousel__slide {

                padding: 5px 15px;

                @media only screen and (min-width: 992px) {
                    padding: 5px 60px;
                }

            }

            .carousel__viewport {
                perspective: 2000px;
            }

            .carousel__track {
                transform-style: preserve-3d;
            }

            .carousel__slide--sliding {
                transition: 0.5s;
            }

            .carousel__slide {
                opacity: 0.9;
                // transform: rotateY(-20deg) scale(0.9);
            }

            .carousel__slide--active~.carousel__slide {
                // transform: rotateY(20deg) scale(1);
            }

            .carousel__slide--prev {
                opacity: 1;
                // transform: rotateY(-10deg) scale(0.95);
            }

            .carousel__slide--next {
                opacity: 1;
                // transform: rotateY(10deg) scale(0.95);
            }

            .carousel__slide--active {
                opacity: 1;
                // transform: rotateY(0) scale(1.1);
            }
        }

        .image19 {
            // height: 121px;
            width: 100%;
            height: 100%;
            margin-top: 22px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 1700px) {
                // height: 536px;
                margin-top: 42px;
            }
        }

        .archive-sub2 {
            padding: 0 68px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 1700px) {
                // padding: 0 453px;
                padding: 0;
                width: 614px;
                margin: auto;
            }

            .image20 {
                // height: 244px;
                height: 100%;
                width: 100%;
            }
        }

        .archive-sub3 {
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            .image-container {
                @media only screen and (min-width: 992px) {
                    text-align: center;
                    padding: 0 123px;
                }

                @media only screen and (min-width: 1200px) {
                    padding: 0 113px;
                }

                @media only screen and (min-width: 1500px) {
                    padding: 0 154px;
                }

                @media only screen and (min-width: 1700px) {
                    padding: 0 194px;
                }

                @media only screen and (min-width: 2000px) {
                    padding: 0 249px;
                }

                @media only screen and (min-width: 3839px) {
                    padding: 0 569px;
                }
            }

            .image21 {
                // height: 145px;
                height: 100%;
                width: 100%;
                padding: 0;

                @media only screen and (min-width: 1700px) {
                    // height: 489px;
                    // width: 983px;
                    height: 100%;
                    width: 100%;
                }
            }

            .image22 {
                // height: 185px;
                height: 100%;
                width: 100%;
                padding: 0;

                @media only screen and (min-width: 1700px) {
                    // height: 489px;
                    // width: 1109px;
                    height: 100%;
                    width: 100%;
                }
            }

            .image23 {
                // height: 185px;
                height: 100%;
                width: 100%;
                padding: 0;

                @media only screen and (min-width: 1700px) {
                    // height: 489px;
                    // width: 1109px;
                    height: 100%;
                    width: 100%;
                }
            }

            .image24 {
                // height: 185px;
                height: 100%;
                width: 100%;
                padding: 0;

                @media only screen and (min-width: 1700px) {
                    // height: 489px;
                    // width: 1109px;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}</style>