<template>
	<div class="padded-container landing-page relative all-cursor">

		<div class="landing-content">
			<Transition name="pop-up" appear>
				<div class="first-line">
					Beeda Gokul
				</div>
			</Transition>
			<Transition name="pop-up-slow" appear>
				<div class="content-lines second-line d-flex">
					<!-- @mouseleave="showTeluguContent(false)" -->
					<span @click="this.$router.push('/folk'); showTeluguContent(false)"
						@mouseover=" showTeluguContent(true) " @mouseleave=" showTeluguContent(false) "
						class="telugu-text">తెలుగు</span> born,
					<img v-if=" !showOverlayImg " class="content-line-img1 normal-telugu"
						src="@/assets/landing/telugu.png" alt="telugu letter">
					<img v-else class="content-line-img1 overlay-telugu" src="@/assets/landing/overlay-telugu.png"
						alt="telugu letter">
				</div>
			</Transition>
			<Transition name="pop-up-slower" appear>
				<div>
					<div class="content-lines third-line d-flex">
						<div class="ind-int">
							<div class="industrial">
								<span class="ind-text">
									Industrial
								</span>
							</div>
							<div class="interaction">
								<span class="int-text">
									Interaction
								</span>
							</div>
						</div>
						<div class="third-line-content">
							<!-- @mouseleave="showDesignContent(false)" -->
							<span @click=" this.$router.push('/design'); showDesignContent(false) "
								@mouseover=" showDesignContent(true) " @mouseleave=" showDesignContent(false) "
								class="designer-text">designer</span> &
						</div>
					</div>
					<div class="content-lines fourth-line d-flex">
						<!-- @mouseleave="showStoryContent(false)" -->
						<span @click=" this.$router.push('/lore'); showStoryContent(false) "
							@mouseover=" showStoryContent(true) " @mouseleave=" showStoryContent(false) "
							class="story-text">storyteller</span>
						<img v-if=" !showOverlayImg " class="content-line-img2 normal-pen" src="@/assets/landing/pen.png"
							alt="pen">
						<img v-else class="content-line-img2 overlay-pen" src="@/assets/landing/overlay-pen.png" alt="pen">
					</div>
				</div>
			</Transition>
		</div>
		<div class="telugu-content absolute">
			<Transition v-show=" showTelugu " name="pop">
				<img class="absolute telugu-img telugu1" src="@/assets/landing/telugu/place.png" alt="Place of Birth">
			</Transition>
			<Transition v-show=" showTelugu " name="pop">
				<img class="absolute telugu-img telugu2" src="@/assets/landing/telugu/food.png"
					alt="Traditional Telugu Food">
			</Transition>
			<Transition v-show=" showTelugu " name="pop">
				<img class="absolute telugu-img telugu3" src="@/assets/landing/telugu/telugu.png" alt="Telugu">
			</Transition>
			<Transition v-show=" showTelugu " name="pop">
				<img class="absolute telugu-img telugu4" src="@/assets/landing/telugu/rrr.png"
					alt="First Telugu Oscar Movie">
			</Transition>
		</div>
		<div class="design-content absolute">
			<Transition v-show=" showDesign " name="pop">
				<img class="absolute design-img design1" src="@/assets/landing/design/monitor.png" alt="Monitor">
			</Transition>
			<Transition v-show=" showDesign " name="pop">
				<img class="absolute design-img design2" src="@/assets/landing/design/ring.png" alt="Ring">
			</Transition>
			<Transition v-show=" showDesign " name="pop">
				<img class="absolute design-img design3" src="@/assets/landing/design/phone.png" alt="Phone App">
			</Transition>
			<Transition v-show=" showDesign " name="pop">
				<img class="absolute design-img design4" src="@/assets/landing/design/web.png" alt="Web App">
			</Transition>
		</div>
		<div class="story-content absolute">
			<Transition v-show=" showStory " name="pop">
				<img class="absolute story-img story1" src="@/assets/landing/story/story.png" alt="Design Podcast">
			</Transition>
		</div>
		<Transition>
			<div v-if=" showOverlay " class="screen-overlay absolute">

			</div>
		</Transition>

	</div>
</template> 

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import HoverLink from '@/components/HoverLink/HoverLink.vue';
export default {
	name: 'HomeView',
	components: {
		HelloWorld,
		HoverLink
	},
	data: function () {
		return {
			disabled: false,
			showPage: false,
			showTelugu: false,
			showOverlay: false,
			showDesign: false,
			showStory: false,
			showOverlayImg: false,
			displayWidth: 0,
			showAll: false,
		}
	},
	mounted() {
		this.showAll = true;
		this.calculateFullHeight();
		window.addEventListener('resize', this.calculateFullHeight);
		document.getElementsByClassName('logo-img-landing')[0].style.display = 'block';
		document.getElementsByClassName('logo-img')[0].style.display = 'none';
		document.getElementsByClassName('menu')[0].style.color = '#F1EEE8';
		let ele = document.getElementsByClassName('line');
		for (let i = 0; i < ele.length; i++) {
			ele[i].style.background = '#F1EEE8';
		}
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.calculateFullHeight);
		document.getElementsByClassName('logo-img-landing')[0].style.display = 'none';
		document.getElementsByClassName('logo-img')[0].style.display = 'block';
		document.getElementsByClassName('menu')[0].style.color = '#232121';
		let ele = document.getElementsByClassName('line');
		for (let i = 0; i < ele.length; i++) {
			ele[i].style.background = '#8D8B87';
		}
	},
	methods: {
		showTeluguContent(x) {
			if (this.displayWidth > 1200) {
				if (x) {
					this.showTelugu = true;
					this.showOverlay = true;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'hidden';
					document.getElementsByClassName('telugu-text')[0].style.position = 'relative';
					document.getElementsByClassName('telugu-text')[0].style.zIndex = '7';
					document.getElementsByClassName('telugu-text')[0].style.color = '#F7F5F2';
					this.blurContent(true, 'telugu');
				} else {
					this.showTelugu = false;
					this.showOverlay = false;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'auto';
					document.getElementsByClassName('telugu-text')[0].style.position = 'auto';
					document.getElementsByClassName('telugu-text')[0].style.zIndex = 'auto';
					this.blurContent(false, 'telugu');
				}
			}

		},
		showDesignContent(x) {
			if (this.displayWidth > 1200) {
				if (x) {
					this.showDesign = true;
					this.showOverlay = true;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'hidden';
					document.getElementsByClassName('designer-text')[0].style.position = 'relative';
					document.getElementsByClassName('designer-text')[0].style.zIndex = '7';
					document.getElementsByClassName('designer-text')[0].style.color = '#F7F5F2';
					this.blurContent(true, 'design');
				} else {
					this.showDesign = false;
					this.showOverlay = false;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'auto';
					document.getElementsByClassName('designer-text')[0].style.position = 'auto';
					document.getElementsByClassName('designer-text')[0].style.zIndex = 'auto';
					this.blurContent(false, 'design');
				}
			}

		},
		showStoryContent(x) {
			if (this.displayWidth > 1200) {
				if (x) {
					this.showStory = true;
					this.showOverlay = true;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'hidden';
					document.getElementsByClassName('story-text')[0].style.position = 'relative';
					document.getElementsByClassName('story-text')[0].style.zIndex = '7';
					document.getElementsByClassName('story-text')[0].style.color = '#F7F5F2';
					this.blurContent(true, 'story');
				} else {
					this.showStory = false;
					this.showOverlay = false;
					document.getElementsByClassName('landing-page')[0].style.overflow = 'auto';
					document.getElementsByClassName('story-text')[0].style.position = 'auto';
					document.getElementsByClassName('story-text')[0].style.zIndex = 'auto';
					this.blurContent(false, 'story');
				}
			}

		},
		blurContent(x, y) {
			if (x) {
				document.getElementsByClassName('second-line')[0].style.color = '#53514F';
				document.getElementsByClassName('third-line')[0].style.color = '#53514F';
				document.getElementsByClassName('fourth-line')[0].style.color = '#53514F';
				document.getElementsByClassName('industrial')[0].style.background = '#53514F';
				document.getElementsByClassName('industrial')[0].style.color = '#53514F';
				document.getElementsByClassName('interaction')[0].style.background = '#53514F';
				document.getElementsByClassName('interaction')[0].style.color = '#53514F';
				this.showOverlayImg = true;

				if (y == 'telugu') {
					document.getElementsByClassName('designer-text')[0].style.color = '#53514F';
					document.getElementsByClassName('story-text')[0].style.color = '#53514F';
				} else if (y == 'design') {
					document.getElementsByClassName('story-text')[0].style.color = '#53514F';
					document.getElementsByClassName('telugu-text')[0].style.color = '#53514F';
				} else if (y == 'story') {
					document.getElementsByClassName('designer-text')[0].style.color = '#53514F';
					document.getElementsByClassName('telugu-text')[0].style.color = '#53514F';
				}
			} else {
				document.getElementsByClassName('second-line')[0].style.color = '#F7F5F2';
				document.getElementsByClassName('third-line')[0].style.color = '#F7F5F2';
				document.getElementsByClassName('fourth-line')[0].style.color = '#F7F5F2';

				document.getElementsByClassName('industrial')[0].style.background = '#74B3E2';
				document.getElementsByClassName('industrial')[0].style.color = '#151313';
				document.getElementsByClassName('interaction')[0].style.background = '#E93A4C';
				document.getElementsByClassName('interaction')[0].style.color = '#151313';
				this.showOverlayImg = false;

				if (y == 'telugu') {
					document.getElementsByClassName('designer-text')[0].style.color = '#F7F5F2';
					document.getElementsByClassName('story-text')[0].style.color = '#F7F5F2';
				} else if (y == 'design') {
					document.getElementsByClassName('story-text')[0].style.color = '#F7F5F2';
					document.getElementsByClassName('telugu-text')[0].style.color = '#F7F5F2';
				} else if (y == 'story') {
					document.getElementsByClassName('designer-text')[0].style.color = '#F7F5F2';
					document.getElementsByClassName('telugu-text')[0].style.color = '#F7F5F2';
				}
			}
		},
		calculateFullHeight() {
			const availableHeight = window.innerHeight;
			const displayWidth = window.innerWidth;
			this.displayWidth = window.innerWidth;
			const myElement = document.getElementsByClassName("landing-page")[0];
			if (myElement) {
				if (displayWidth < 1200) {
					myElement.style.height = availableHeight + 'px';
				}
			}
		}
	}
}
</script>
<style lang=less>
// @keyframes pop-up {
//   0% {
//     transform: translateY(50px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
.pop-up-enter-active,
.pop-up-leave-active {
	transition: transform 0.8s, opacity 0.8s ease-in;
}

.pop-up-enter-from,
.pop-up-leave-to {
	transform: translateY(50px);
	opacity: 0;
}

.pop-up-slow-enter-active,
.pop-up-slow-leave-active {
	transition: transform 1s, opacity 1s ease-in;
}

.pop-up-slow-enter-from,
.pop-up-slow-leave-to {
	transform: translateY(70px);
	opacity: 0;
}

.pop-up-slower-enter-active,
.pop-up-slower-leave-active {
	transition: transform 1.2s, opacity 1.2s ease-in;
}

.pop-up-slower-enter-from,
.pop-up-slower-leave-to {
	transform: translateY(90px);
	opacity: 0;
}

// .pop-enter-active,
// .pop-leave-active {
// 	transition: all 0.3s ease-out;
// }

// .pop-enter-from,
// .pop-leave-to {
// 	opacity: 0;
// 	transform: scale(0.5);
// }
.landing-page {
	background: #000000;
	font-family: "Suisse International";
	font-weight: 700;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
	text-transform: uppercase;


	.landing-content {
		user-select: none;

		@media only screen and (min-width: 992px) {
			margin-left: 30px;
		}

		.first-line {
			text-transform: uppercase;
			color: #53514F;
			// font-size: 28px;
			// line-height: 75px;

			font-size: 36px;
			// line-height: 53px;
			text-align: left;
			margin-bottom: 38px;

			@media only screen and (max-width: 300px) {
				font-size: 24px;
				// line-height: 65px;
			}

			@media only screen and (min-width: 768px) {
				font-size: 54px;
				// line-height: 100px;
				margin-bottom: 58px;
			}

			@media only screen and (min-width: 992px) {
				font-size: 76px;
				// line-height: 140px;
			}

			@media only screen and (min-width: 1200px) {
				font-size: 84px;
				// line-height: 150px;
				margin-bottom: 88px;
			}

			@media only screen and (min-width: 1400px) {
				font-size: 96px;
				// line-height: 170px;
			}
		}

		.telugu-text {
			font-family: "Noto Sans Telugu";
			font-weight: 800;
			// font-size: 28px;
			// line-height: 60px;
			font-size: 40px;
			line-height: 60px;
			margin-right: 18px;
			cursor: pointer;

			@media only screen and (max-width: 300px) {
				font-size: 26px;
				line-height: 40px;
			}

			@media only screen and (min-width: 768px) {
				font-size: 54px;
				line-height: 80px;
			}

			@media only screen and (min-width: 992px) {
				font-size: 76px;
				line-height: 130px;
			}

			@media only screen and (min-width: 1200px) {
				font-size: 84px;
				line-height: 140px;
			}

			@media only screen and (min-width: 1400px) {
				font-size: 96px;
				line-height: 160px;
			}
		}

		.ind-int {
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: 5px;

			@media only screen and (min-width: 992px) {
				margin-right: 22px;
			}

			.industrial {
				background: #74B3E2;
				font-size: 9px;
				line-height: 13px;
				color: #151313;
				padding: 3px 9px;
				width: fit-content;

				@media only screen and (max-width: 300px) {
					font-size: 8px;
					line-height: 12px;
				}

				@media only screen and (min-width: 768px) {
					font-size: 9px;
					line-height: 13px;
				}

				@media only screen and (min-width: 992px) {
					font-size: 20px;
					line-height: 22px;
					padding: 7.5px 22.5px;
				}

				@media only screen and (min-width: 1200px) {
					font-size: 20px;
					line-height: 22px;
				}

				@media only screen and (min-width: 1400px) {
					font-size: 20px;
					line-height: 22px;
				}
			}

			.interaction {
				background: #E93A4C;
				font-size: 9px;
				line-height: 13px;
				color: #151313;
				padding: 3px 9px;
				width: fit-content;
				margin-top: 6px;

				@media only screen and (max-width: 300px) {
					font-size: 8px;
					line-height: 12px;
					margin-top: 4px;
				}

				@media only screen and (min-width: 768px) {
					font-size: 9px;
					line-height: 13px;
				}

				@media only screen and (min-width: 992px) {
					font-size: 20px;
					line-height: 22px;
					padding: 7.5px 22.5px;
				}

				@media only screen and (min-width: 1200px) {
					font-size: 20px;
					line-height: 22px;
				}

				@media only screen and (min-width: 1400px) {
					font-size: 20px;
					line-height: 22px;
				}
			}
		}

		.content-lines {
			color: #F7F5F2;
			// font-size: 28px;
			// line-height: 55px; //5

			font-size: 34px;
			line-height: 51px;
			text-align: left;

			@media only screen and (max-width: 300px) {
				font-size: 22px;
				line-height: 34px; //4
			}

			@media only screen and (min-width: 768px) {
				font-size: 54px;
				line-height: 70px; //10
			}

			@media only screen and (min-width: 992px) {
				font-size: 76px;
				line-height: 110px;
			}

			@media only screen and (min-width: 1200px) {
				font-size: 84px;
				line-height: 120px;
			}

			@media only screen and (min-width: 1400px) {
				font-size: 96px;
				line-height: 140px;
			}
		}

		.third-line {
			margin-top: -5px;

			@media only screen and (max-width: 300px) {
				margin-top: -4px;
			}

			@media only screen and (min-width: 768px) {
				margin-top: -20px;
			}

			@media only screen and (min-width: 992px) {
				margin-top: -35px;
			}

			@media only screen and (min-width: 1200px) {
				margin-top: -45px;
			}

			@media only screen and (min-width: 1400px) {
				margin-top: -45px;
			}
		}

		.fourth-line {
			margin-top: -5px;

			@media only screen and (max-width: 300px) {
				margin-top: -4px;
			}

			@media only screen and (min-width: 768px) {
				margin-top: -10px;
			}

			@media only screen and (min-width: 992px) {
				margin-top: -25px;
			}

			@media only screen and (min-width: 1200px) {
				margin-top: -30px;
			}

			@media only screen and (min-width: 1400px) {
				margin-top: -30px;
			}
		}

		.content-line-img1 {
			width: 35px;
			height: 35px;
			margin-left: 8px;
			margin-top: 5px;

			@media only screen and (max-width: 300px) {
				width: 30px;
				height: 30px;
				margin-top: 2px;
				margin-left: 5px;
			}

			// @media only screen and (max-width: 376px) {
			// 	width: 35px;
			// 	height: 35px;
			// 	margin-top: 8px;
			// 	margin-left: 5px;
			// }

			@media only screen and (min-width: 768px) {
				width: 45px;
				height: 45px;
				margin-top: 8px;
			}

			@media only screen and (min-width: 992px) {
				width: 60px;
				height: 60px;
				margin-top: 20px;
				margin-left: 18px;
			}

			@media only screen and (min-width: 1200px) {
				width: 70px;
				height: 70px;
				margin-top: 23px;
				margin-left: 27px;
			}

			@media only screen and (min-width: 1400px) {
				width: 86px;
				height: 86px;
				margin-top: 19px;
				margin-left: 27px;
			}
		}

		.content-line-img2 {
			width: 40px;
			height: 28px;
			margin-left: 8px;
			margin-top: 10px;

			@media only screen and (max-width: 300px) {
				width: 40px;
				height: 20px;
				margin-top: 5px;
			}

			@media only screen and (min-width: 768px) {
				width: 80px;
				height: 42px;
				margin-top: 12px;
				margin-left: 24px;
			}

			@media only screen and (min-width: 992px) {
				width: 130px;
				height: 65px;
				margin-top: 20px;
			}

			@media only screen and (min-width: 1200px) {
				width: 130px;
				height: 64px;
				margin-top: 24px;
			}

			@media only screen and (min-width: 1400px) {
				width: 130px;
				height: 78px;
				margin-top: 24px;
			}
		}
	}

	.pop-enter-active,
	.pop-leave-active {
		transition: all 0.3s ease-out;
	}

	.pop-enter-from,
	.pop-leave-to {
		opacity: 0;
		transform: scale(0.5);
	}

	.screen-overlay {
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.8);
		left: 50%;
		transform: translate(-50%, 0);
		overflow: hidden;
		z-index: 4;
	}

	.telugu-content {
		.telugu-img {
			width: 150px;
			object-fit: contain;
			position: absolute;
			z-index: 6;

			@media only screen and (min-width: 1400px) {
				width: 200px;
			}
		}

		.telugu1 {
			top: -40vh;
			right: 30vw;
			width: 200px;
		}

		.telugu2 {
			top: 18vh;
			right: 25vw;
			width: 200px;

			@media only screen and (min-width: 1400px) {
				width: 300px;
			}
		}

		.telugu3 {
			top: -13vh;
			right: -7vw;
		}

		.telugu4 {
			left: 30vw;
			bottom: 0;
		}
	}

	.designer-text {
		cursor: pointer;
	}

	.design-content {
		.design-img {
			width: 159px;
			object-fit: contain;
			position: absolute;
			z-index: 6;

			@media only screen and (min-width: 1400px) {
				width: 259px;
			}
		}

		.design1 {
			top: -116px;
			right: 25vw;
		}

		.design2 {
			bottom: 5vh;
			right: 0;
		}

		.design3 {
			bottom: 10vh;
			left: 18vw;
		}

		.design4 {
			top: 18vh;
			width: 200px;

			@media only screen and (min-width: 1400px) {
				width: 330px;
			}
		}
	}

	.story-content {
		.story-img {
			width: 159px;
			object-fit: contain;
			position: absolute;
			z-index: 6;

			@media only screen and (min-width: 1400px) {
				width: 259px;
			}
		}

		.story1 {
			bottom: -16vh;
			right: -45px;
		}
	}
}</style>