import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SlideShow from '../views/SlideShow.vue'
import FolkView from '../views/FolkView.vue'
import LoadingView from '../views/LoadingView.vue'
import LoreView from '../views/LoreView.vue'
import DesignView from '../views/DesignView.vue'
import KohlerCompost from '../views/CaseStudies/KohlerCompost.vue'
import GoogleARGlasses from '../views/CaseStudies/GoogleARGlasses.vue'
import AmbientComputing from '../views/CaseStudies/AmbientComputing.vue'
import NiravModi from '../views/CaseStudies/NiravModi.vue'
import Luna from '../views/CaseStudies/Luna.vue'
import CNNCase from '../views/CaseStudies/CNN.vue'
import Neva from '../views/CaseStudies/Neva.vue'
import CloudGaming from '../views/CaseStudies/CloudGaming.vue'
import Archived from '../views/CaseStudies/Archived.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/home/:tabsname',
  //   name: 'industrial',
  //   component: IndustrialView
  // },
  // {
  //   path: '/design',
  //   name: 'design',
  //   component: SlideShow
  // },
  {
    path: '/design',
    name: 'design',
    component: DesignView
  },
  {
    path: '/folk',
    name: 'folk',
    component: FolkView
  },
  {
    path: '/lore',
    name: 'lore',
    component: LoreView,
  },
  {
    path: '/design/kohler-compost',
    name: 'kohler',
    component: KohlerCompost,
    meta: {
      requiresPassword: true
    }
  },
  {
    path: '/design/google-ar',
    name: 'google',
    component: GoogleARGlasses
  },
  {
    path: '/design/ambient-computing',
    name: 'ambient-computing',
    component: AmbientComputing
  },
  {
    path: '/design/nirav-modi',
    name: 'nirav-modi',
    component: NiravModi
  },
  {
    path: '/design/luna',
    name: 'luna',
    component: Luna
  },
  {
    path: '/design/cnn',
    name: 'cnn',
    component: CNNCase
  },
  {
    path: '/design/neva',
    name: 'neva',
    component: Neva
  },
  {
    path: '/design/cloud-gaming',
    name: 'cloud-gaming',
    component: CloudGaming
  },
  {
    path: '/design/archived',
    name: 'archived',
    component: Archived
  },
  {
    path: '/error',
    name: 'Loading',
    component: LoadingView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresPassword) {
    const passwordEntered = sessionStorage.getItem('passwordEntered');

    if (passwordEntered) {
      next();
    } else {
      const enteredPassword = prompt('Enter the password:');
      if (enteredPassword === 'password') {
        sessionStorage.setItem('passwordEntered', 'true');
        next();
      } else {
        next(false); // Prevent navigation
      }
    }
  } else {
    next();
  }
});
export default router
