<template>
    <div class="ambient-computing case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Ambient
                </div>
                <div>
                    Computing
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Ambient Computing
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Interaction Design
                            </div>
                            <div>
                                Digital Prototyping
                            </div>
                            <div>
                                Visualisation
                            </div>
                        </div>
                        <div class="team">
                            <div>
                                Team:
                            </div>
                            <div>
                                Gokul Beeda
                            </div>
                            <div>
                                Nikhila Isukapalle
                            </div>
                            <div>
                                Apeksha Jain
                            </div>
                            <div>
                                Charmin Vemula
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2020
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Exploring interaction experiments for knowledge navigation in the age of ubiquitous/ambient
                        computing.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                During my master's degree, I conducted experiments exploring screen-less interactions. I
                                developed a speculative demo for an ambient computing experience with my team of three. The
                                final prototype featured an AR laser projection headband, enabling gestures, touch-based
                                inputs, and multimodal interactions with ambient interfaces. Users could build real-time 3D
                                models, access contextual UI elements for information retrieval, collaborate in groups, and
                                understand information sources.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    A lightweight AR <span class="color-text">headband that sits at the peripheral of your view allowing YOU
                        to access UBIQUITOUS
                        computing</span> interfaces ANYWHERE AND TIME.
                </div>
                <div class="overview-description desktop">
                    <div>
                        A lightweight AR <span class="color-text">headband</span>
                    </div>
                    <div class="color-text">
                        that sits at the peripheral of
                    </div>
                    <div class="color-text">
                        your view allowing YOU to
                    </div>
                    <div class="color-text">
                        access UBIQUITOUS computing
                    </div>
                    <div>
                        interfaces ANYWHERE AND TIME.
                    </div>
                </div>
            </div>
            <div class="section-1 parallax-in">
                <div class="title">
                    <div class="mobile">
                        How will people navigate knowledge (browse & create) in a screen-less world* ?
                    </div>
                    <div class="desktop">
                        <div>
                            How will people navigate knowledge
                        </div>
                        <div>
                            (browse & create) in a screen-less world* ?
                        </div>
                    </div>
                </div>
                <div class="caption image-condition">
                    <div class="mobile">
                        *Ambient Computing: Ability to access computing capabilities in a seamless & invisible way, making
                        visible stimuli subtle and less obtrusive.
                    </div>
                    <div class="desktop">
                        <div>
                            *Ambient Computing: Ability to access
                        </div>
                        <div>
                            computing capabilities in a seamless
                        </div>
                        <div>
                            & invisible way, making visible
                        </div>
                        <div>
                            stimuli subtle and less obtrusive.
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-2 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image1" src="../../assets/design/ambient-computing/image1.png"
                                alt="Fluid Interface">
                            <div class="additional-info">
                                <div class="d-flex">
                                    <div class="sub-text">
                                        1.
                                    </div>
                                    <div class="m-1-left sub-text">
                                        <div>
                                            Fluid Interfaces: Information and UI elements shown on
                                        </div>
                                        <div>
                                            interfaces are context (environment, user behaviour) based
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="image-container">
                            <img class="image2" src="../../assets/design/ambient-computing/image2.png"
                                alt="Ubiquitous systems">
                            <div class="additional-info">
                                <div class="d-flex">
                                    <div class="sub-text">
                                        2.
                                    </div>
                                    <div class="m-1-left sub-text">
                                        <div>
                                            Ubiquitous systems: Hardware(device) and OS
                                        </div>
                                        <div>
                                            (Interface) should be in the background and
                                        </div>
                                        <div>
                                            accessed from anywhere.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-3 parallax-in">
                <div class="image-container">
                    <img class="image3" src="../../assets/design/ambient-computing/image3.png" alt="Figure 1">
                </div>
                <div class="image-caption">
                    <div>
                        Fig 1:
                    </div>
                    <div class="mobile">
                        AR headset with laser projection and gesture tracking.
                    </div>
                    <div class="desktop">
                        <div>
                            AR headset with laser projection
                        </div>
                        <div>
                            and gesture tracking.
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-4 parallax-in">
                <div class="title">
                    The device uses AR laser projector and lidar to show interfaces on any surface and recognises a range of
                    interaction inputs.
                </div>
                <div class="sub-title title">
                    Materials: PCR resins, HDPE (High-Density Polyethylene), Liquid silicone, Acrylic
                </div>
            </div>
            <div class="section-5 parallax-in">
                <div class="image-container">
                    <img class="image4" src="../../assets/design/ambient-computing/image4.png" alt="Figure 2">
                </div>
                <div class="image-caption">
                    <div>
                        Fig 2:
                    </div>
                    <div class="mobile">
                        The curve continuous edges of the form makes the device hassle free and doesn't come in the way of
                        people hair-types.
                    </div>
                    <div class="desktop">
                        <div>
                            The curve continuous edges of the form
                        </div>
                        <div>
                            makes the device hassle free and doesn't
                        </div>
                        <div>
                            come in the way of people hair-types.
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-6 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image5" src="../../assets/design/ambient-computing/image5.png" alt="Figure 3">
                        </div>
                        <div class="image-container">
                            <img class="image6" src="../../assets/design/ambient-computing/image6.png" alt="Figure 4">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="image-caption">
                            <div>
                                <div>
                                    Fig 3-4:
                                </div>
                                <div class="mobile">
                                    The AR projector module snaps on to the headband and also simultaneously charges using
                                    the battery pack in the headband.
                                </div>
                                <div class="desktop">
                                    <div>
                                        The AR projector module snaps on to the
                                    </div>
                                    <div>
                                        headband and also simultaneously charges
                                    </div>
                                    <div>
                                        using the battery pack in the headband.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-section">
                            <div @click=" showInfo3 = !showInfo3;" class="readmore relative">
                                <span v-if="showInfo3">close</span><span v-else>read more</span><img
                                    :class="{ openInfo: showInfo3 }" class="add-sign absolute" src="../../assets/add.svg"
                                    alt="add">
                            </div>
                            <Transition name="slide-down">
                                <div v-show="showInfo3" class="additional-info two-content-section">
                                    <div class="para-spacer sub-text">
                                        The product silhouette design makes it flexible and accommodates a range of head
                                        sizes. This flexibility also allows the band to sit on your head even when wearing
                                        other head accessories.
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-7 parallax-in">
                <div class="image-container">
                    <!-- <img class="image7" src="../../assets/design/ambient-computing/image7.png" alt="Figure 5"> -->
                    <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                        class="video1">
                        <source src="../../assets/design/ambient-computing/video1.webm" type="video/webm" />
                    </video>
                    <div class="image-caption">
                        <div>
                            Video 1:
                        </div>
                        <div class="mobile">
                            We used AR projection & multimodal interaction for their unique flexibility to achieve an
                            ambient computing experience.
                        </div>
                        <div class="desktop">
                            <div>
                                We used AR projection & multimodal interaction for their
                            </div>
                            <div>
                                unique flexibility to achieve an ambient computing experience.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-8 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image8" src="../../assets/design/ambient-computing/image8.png" alt="Figure 6">
                        </div>
                        <div class="image-container">
                            <img class="image9" src="../../assets/design/ambient-computing/image9.png" alt="Figure 7">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="image-caption">
                            <div>
                                <div>
                                    Fig 5-6:
                                </div>
                                <div class="mobile">
                                    We also explored alternative form and CMF of the headband that speaks to existing XR &
                                    AR product archetypes and are familiar to use.
                                </div>
                                <div class="desktop">
                                    <div>
                                        We also explored alternative form and CMF of
                                    </div>
                                    <div>
                                        the headband that speaks to existing XR & AR
                                    </div>
                                    <div>
                                        product archetypes and are familiar to use.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-9 parallax-in">
                <div class="title">
                    Interaction Demo
                </div>
                <div class="image-container">
                    <!-- <img class="image10" src="../../assets/design/ambient-computing/image10.png" alt="interaction demo"> -->
                    <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                        class="video2">
                        <source src="../../assets/design/ambient-computing/video2.webm" type="video/webm" />
                    </video>
                </div>
            </div>
            <div class="section-10 parallax-in">
                <div class="title">
                    Our team tried to build a part of this experience for our demo, allowing participants to create
                    real-time 3D models with gesture control and access contextual UI elements & tools for scaling, sizing
                    profiles, and texture swaps.
                </div>
                <div class="image-container">
                    <!-- <img class="image11" src="../../assets/design/ambient-computing/image11.png" alt="interaction demo"> -->
                    <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                        class="video3">
                        <source src="../../assets/design/ambient-computing/video3.webm" type="video/webm" />
                    </video>
                </div>
            </div>
            <div class="section-11 parallax-in">
                <div class="image-container">
                    <img class="image12" src="../../assets/design/ambient-computing/image12.png" alt="Figure 8">
                    <div class="image-caption">
                        <div>
                            Fig 7:
                        </div>
                        <div>
                            First concept sketch of the AR Band
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-12 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image13" src="../../assets/design/ambient-computing/image13.png" alt="Figure 9">
                        </div>
                        <div class="image-container">
                            <img class="image14" src="../../assets/design/ambient-computing/image14.png" alt="Figure 10">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="sub-text">
                            <div class="mobile">
                                The initial concept was using a brain computer interface as an input method and a portable
                                battery pack to keep the headband lightweight. But the lack of understanding about the
                                interface capabilities and tested users not having a familiar mental model to use the
                                interface and input methods properly promoted us to abandon the concept.
                            </div>
                            <div class="desktop">
                                <div>
                                    The initial concept was using a brain computer interface as an input
                                </div>
                                <div>
                                    method and a portable battery pack to keep the headband lightweight.
                                </div>
                                <div>
                                    But the lack of understanding about the interface capabilities and
                                </div>
                                <div>
                                    tested users not having a familiar mental model to use the interface
                                </div>
                                <div>
                                    and input methods properly promoted us to abandon the concept.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-container">
            <img class="image15 parallax-in" src="../../assets/design/ambient-computing/image15.png" alt="Figure 11">
        </div>
        <div class="archive-section">
            <div class="archive-title-section parallax-in">
                <div class="archive-title">
                    Archive
                </div>
            </div>
            <div class="archive-text padded-container parallax-in">
                <div class="mobile">
                    We used AR projection and multimodal interaction for their unique flexibility to achieve an ambient
                    computing experience. The band design was tested to be flexible and sizeable enough to pack the required
                    tech. The form was also iterated to achieve maximum adaptability to most head shapes.
                </div>
                <div class="desktop">
                    <div>
                        We used AR projection and multimodal interaction for their unique
                    </div>
                    <div>
                        flexibility to achieve an ambient computing experience. The band
                    </div>
                    <div>
                        design was tested to be flexible and sizeable enough to pack the
                    </div>
                    <div>
                        required tech. The form was also iterated to achieve maximum
                    </div>
                    <div>
                        adaptability to most head shapes.
                    </div>
                </div>
            </div>
            <div class="archive-sub1 padded-container parallax-in">
                <div class="image-container">
                    <img class="image16" src="../../assets/design/ambient-computing/image16.png" alt="Archive Figure 1">
                    <div class="archive-caption">
                        <div>
                            Fig 1:
                        </div>
                        <div class="mobile">
                            The chosen concept was further refined considering product architecture, tech housing, and
                            design details of the frame.
                        </div>
                        <div class="desktop">
                            <div>
                                The chosen concept was further refined considering product
                            </div>
                            <div>
                                architecture, tech housing, and design details of the frame.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub2 padded-container parallax-in">
                <div class="image-container">
                    <img class="image17" src="../../assets/design/ambient-computing/image17.png" alt="Archive Figure 2">
                    <div class="archive-caption">
                        <div>
                            Fig 2:
                        </div>
                        <div>
                            Initial ideation sketches for the headband.
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub3 padded-container parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <!-- <img class="image18" src="../../assets/design/ambient-computing/image18.png"
                                alt="Archive Figure 3"> -->
                            <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                                class="video4">
                                <source src="../../assets/design/ambient-computing/video4.webm" type="video/webm" />
                            </video>
                        </div>
                        <div class="image-container">
                            <!-- <img class="image19" src="../../assets/design/ambient-computing/image19.png"
                                alt="Archive Figure 4"> -->
                            <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                                class="video5">
                                <source src="../../assets/design/ambient-computing/video5.webm" type="video/webm" />
                            </video>
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="archive-caption">
                            <div>
                                <div>
                                    Video 2:
                                </div>
                                <div class="mobile">
                                    Prototypes showing how BCI interaction would work in an AR environment
                                </div>
                                <div class="desktop">
                                    <div>
                                        Prototypes showing how BCI interaction
                                    </div>
                                    <div>
                                        would work in an AR environment
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub4 padded-container parallax-in">
                <div class="image-container">
                    <!-- <img class="image20" src="../../assets/design/ambient-computing/image20.png" alt="Archive Figure 5"> -->
                    <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                        class="video6">
                        <source src="../../assets/design/ambient-computing/video6.webm" type="video/webm" />
                    </video>
                    <div class="archive-caption">
                        <div>
                            Video 3:
                        </div>
                        <div>
                            Key-mapping prototype
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub5 padded-container parallax-in">
                <div class="image-container">
                    <!-- <img class="image21" src="../../assets/design/ambient-computing/image21.png" alt="Archive Figure 6"> -->
                    <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline
                        class="video7">
                        <source src="../../assets/design/ambient-computing/video7.webm" type="video/webm" />
                    </video>
                    <div class="archive-caption">
                        <div>
                            Video 4:
                        </div>
                        <div class="mobile">
                            Initial iteration of the AR interface using ChalkTalk GitHub repository
                        </div>
                        <div class="desktop">
                            <div>
                                Initial iteration of the AR interface
                            </div>
                            <div>
                                using ChalkTalk GitHub repository
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'AmbientComputing',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            slideImages1: [
                {
                    src: 'slide1',
                    text: 'silhouette from development and details exploration'
                },
                {
                    src: 'slide2',
                    text: 'landing on a design concept with a strong silhouette that works for most face types. The concept tries to use a black CMF to stay accessible for different occasions, with hints of color to indicate tech and interaction points'
                },
                {
                    src: 'slide3',
                    text: 'silhouette from development and details exploration'
                }
            ],
            slideImages2: [
                {
                    src: 'slide4',
                    text: 'CAD V1'
                },
                {
                    src: 'slide5',
                    text: 'CAD V2'
                },
                {
                    src: 'slide6',
                    text: ''
                },
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide1: 0,
            currentSlide2: 0,
            parallaxElements: [],
            showScrollTop: false,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },
        moveSlide2() {
            this.currentSlide2++;
        },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.ambient-computing {
    .landing-section-mobile {
        background: url('~@/assets/design/ambient-computing/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/ambient-computing/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    .section-1 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .caption {
            margin-top: 40px;
        }
    }

    .section-2 {
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        .image1 {
            width: 100%;
            // height: 420px;
        }

        .image2 {
            width: 100%;
            // height: 420px;
        }

        .image-section {
            gap: 20px;

            @media only screen and (min-width: 992px) {
                gap: 0;
                justify-content: space-between;
            }
        }

        .image-container {
            @media only screen and (min-width: 992px) {
                width: 42%;
            }
        }
    }

    .section-3 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image-container {
            text-align: center;
        }

        .image3 {
            width: 60%;
        }
    }

    .section-4 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 229px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image4 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image5,
        .image6 {
            width: 100%;
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image-container {
            @media only screen and (min-width: 992px) {
                width: 50%;
                margin: 0 auto;
            }
        }

        .video1 {
            width: 100%;
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image8,
        .image9 {
            width: 100%;
        }
    }

    .section-9 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .video2 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .video3 {
            width: 100%;
            margin-top: 90px;

            @media only screen and (min-width: 992px) {
                margin-top: 96px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 124px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 146px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 160px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 198px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 371px;
            }
        }
    }

    .section-11 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image12 {
            width: 100%;
        }

        .image-container {
            @media only screen and (min-width: 992px) {
                width: 50%;
                margin: 0 auto;
            }
        }
    }

    .section-12 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image13,
        .image14 {
            width: 100%;
        }
    }

    .image15 {
        width: 100%;
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .archive-section {

        .archive-sub1 {
            margin-top: 130px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 140px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 228px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 298px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 306px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 548px;
            }

            .image16 {
                width: 100%;
            }

            .image-container {
                @media only screen and (min-width: 992px) {
                    width: 80%;
                    margin: 0 auto;
                }
            }

            .archive-caption {
                margin: 42px auto 0 auto;

                @media only screen and (min-width: 992px) {
                    width: fit-content;
                    margin: 52px auto 0 auto;
                }

                @media only screen and (min-width: 1200px) {
                    margin: 52px auto 0 auto;
                }

                @media only screen and (min-width: 1500px) {
                    margin: 62px auto 0 auto;
                }

                @media only screen and (min-width: 1700px) {
                    margin: 70px auto 0 auto;
                }

                @media only screen and (min-width: 2000px) {
                    margin: 110px auto 0 auto;
                }

                @media only screen and (min-width: 3839px) {
                    margin: 213px auto 0 auto;
                }
            }
        }

        .archive-sub2 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }

            .image17 {
                width: 100%;
            }

            .image-container {
                @media only screen and (min-width: 992px) {
                    width: 50%;
                    margin: 0 auto;
                }
            }

            .archive-caption {
                margin: 42px auto 0 auto;

                @media only screen and (min-width: 992px) {
                    width: fit-content;
                    margin: 52px auto 0 auto;
                }

                @media only screen and (min-width: 1200px) {
                    margin: 52px auto 0 auto;
                }

                @media only screen and (min-width: 1500px) {
                    margin: 62px auto 0 auto;
                }

                @media only screen and (min-width: 1700px) {
                    margin: 70px auto 0 auto;
                }

                @media only screen and (min-width: 2000px) {
                    margin: 110px auto 0 auto;
                }

                @media only screen and (min-width: 3839px) {
                    margin: 213px auto 0 auto;
                }
            }
        }

        .archive-sub3 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }

            .video4,
            .video5 {
                width: 100%;
            }
        }

        .archive-sub4 {
            margin-top: 170px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 140px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 228px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 258px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 306px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 548px;
            }

            .video6 {
                width: 100%;
            }

            .image-container {
                @media only screen and (min-width: 992px) {
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }

        .archive-sub5 {
            margin-top: 130px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 140px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 228px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 298px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 306px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 548px;
            }

            .video7 {
                width: 100%;
            }

            .image-container {
                @media only screen and (min-width: 992px) {
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }

}
</style>