<template>
	<div class="header" :class="{'all-cursor':this.$route.path == '/', 'opaque-all':this.$route.path == '/folk'}">
		<div class="header-container d-flex">
			<div @click="this.$router.push('/'); checkMenu()" class="logo pointer">
				<Transition name="logo-fade" mode="out-in">
					<img v-if="!showBoldLogo" class="logo-img" src="../../assets/logo.svg" alt="logo">
					<img v-else class="logo-img" src="../../assets/logo-bold.svg" alt="logo">
				</Transition>
				<img class="logo-img logo-img-menu" src="../../assets/logo-menu.svg" alt="logo">
				<img class="logo-img logo-img-landing" src="../../assets/logo-white.svg" alt="logo">
			</div>
			<div class="menu d-flex desktop-menu">
				<div class="menu-item design">
					<span @click="this.$router.push('/design')" class="m-item">design</span>
				</div>
				<div class="menu-item folk">
					<span @click="this.$router.push('/folk')" class="m-item">folk</span>
				</div>
				<div class="menu-item lore">
					<span @click="this.$router.push('/lore')" class="m-item">lore</span>
				</div>
			</div>
			<div class="mobile-menu">
				<div @click="openMenu" class="hamburger-lines">
					<span class="line line1"></span>
					<span class="line line2"></span>
					<span class="line line3"></span>
				</div>
				<Transition name="slide-fade" mode="in-out">
					<div v-show="showMenu" class="mobile-menu-item">
						<div class="m-items">
							<div class="m-menu-item design">
								<span class="m-menu-item-n">_01</span>
								<span @click="this.$router.push('/design'); openMenu()" class="m-item">design</span>
							</div>
							<div class="m-menu-item folk">
								<span class="m-menu-item-n">_02</span>
								<span @click="this.$router.push('/folk'); openMenu()" class="m-item">folk</span>
							</div>
							<div class="m-menu-item lore">
								<span class="m-menu-item-n">_03</span>
								<span @click="this.$router.push('/lore'); openMenu()" class="m-item">lore</span>
							</div>
						</div>
						<div class="copy-info">
							<div>
								Copyright <img src="@/assets/copyright.png" alt="copyright"> 2023 BGRY.
							</div>
							<div>
								Made in Andhra Pradesh
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	components: {},
	data: function () {
		return {
			showMenu: false,
			showBoldLogo: false,
		}
	},
	mounted() {
		this.calculateMenuHeight();
		window.addEventListener('resize', this.calculateMenuHeight);
		document.addEventListener('scroll', this.handleLogoChange);
		let vm = this;
		window.addEventListener("orientationchange", function () {
            vm.calculateMenuHeight();
        }, false);

        let portrait = window.matchMedia("(orientation: portrait)");

        portrait.addEventListener("change", function (e) {
            vm.calculateMenuHeight();
        });

        screen.orientation.addEventListener("change", function(e) {
            vm.calculateMenuHeight();
        });
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.calculateMenuHeight);
		window.removeEventListener('orientationchange', this.calculateMenuHeight);
		window.removeEventListener('change', this.calculateMenuHeight)
		document.removeEventListener('scroll', this.handleLogoChange);
	},
	watch: {
		$route(to, from) {
			if (to.name == 'design') {
				this.showBoldLogo = false;
			}
		}
	},
	methods: {
		checkMenu() {
			if (this.showMenu == true) {
				this.openMenu();
			}
		},
		handleLogoChange() {
			// if (this.$route.name == 'folk' || this.$route.name == 'lore') {
			// 	if (this.getScrollPercent() > 5) {
			// 		this.showBoldLogo = true;
			// 	} else {
			// 		this.showBoldLogo = false;
			// 	}
			// }

			if(this.$route.name == 'home' || this.$route.name == 'design') {

			} else {
				if (this.getScrollPercent() > 5) {
					this.showBoldLogo = true;
				} else {
					this.showBoldLogo = false;
				}
			}
		},
		getScrollPercent() {
			var h = document.documentElement,
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';
			return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
		},
		openMenu() {
			if (window.innerWidth < 1200) {
				this.showMenu = !this.showMenu;
				let ele = document.getElementsByClassName('line');
				if (this.showMenu) {
					// document.getElementsByTagName('html')[0].style.background = '#000'

					document.getElementsByClassName('line1')[0].classList.add('line1-tran');
					document.getElementsByClassName('line2')[0].classList.add('line2-tran');
					document.getElementsByClassName('line3')[0].classList.add('line3-tran');
					document.getElementsByTagName('body')[0].style.overflow = 'hidden';
					// document.getElementsByTagName('body')[0].style.position = 'fixed';
					document.getElementsByClassName('logo-img-menu')[0].style.display = 'block';
					if (window.innerHeight < 450) {
						document.getElementsByClassName('mobile-menu-item')[0].classList.add('mobile-landscape-scroll');
					} else {
						document.getElementsByClassName('mobile-menu-item')[0].classList.remove('mobile-landscape-scroll');
					}
					if (this.$route.name == 'home') {
						document.getElementsByClassName('landing-page')[0].style.width = '93vw';
						document.getElementsByClassName('logo-img-landing')[0].style.display = 'none';
						// document.getElementsByClassName('logo-img')[0].style.display = 'block';
						document.getElementsByTagName('body')[0].style.position = 'fixed';
					} else {
						document.getElementsByClassName('logo-img')[0].style.display = 'none';
					}
					for (let i = 0; i < ele.length; i++) {
						ele[i].style.background = '#8D8B87';
					}
				} else {
					document.getElementsByTagName('html')[0].style.background = 'initial';
					document.getElementsByClassName('line1')[0].classList.remove('line1-tran');
					document.getElementsByClassName('line2')[0].classList.remove('line2-tran');
					document.getElementsByClassName('line3')[0].classList.remove('line3-tran');
					document.getElementsByTagName('body')[0].style.overflow = 'auto';
					document.getElementsByTagName('body')[0].style.position = 'initial';
					document.getElementsByClassName('logo-img-menu')[0].style.display = 'none';
					if (window.innerHeight < 450) {
						document.getElementsByClassName('mobile-menu-item')[0].classList.remove('mobile-landscape-scroll');
					}
					if (this.$route.name == 'home') {
						document.getElementsByClassName('landing-page')[0].style.width = 'initial';
						document.getElementsByClassName('logo-img-landing')[0].style.display = 'block';
						// document.getElementsByClassName('logo-img')[0].style.display = 'none';
					} else {
						document.getElementsByClassName('logo-img')[0].style.display = 'block';
					}
					for (let i = 0; i < ele.length; i++) {
						ele[i].style.background = '#1C1B1F';
						if (this.$route.name == 'home') {
							ele[i].style.background = '#F1EEE8';
						}
					}
				}
			}
		},
		calculateMenuHeight() {
			const availableHeight = window.innerHeight;
			const displayWidth = window.innerWidth;
			const myElement = document.getElementsByClassName("mobile-menu-item")[0];
			if (myElement) {
				if (displayWidth < 1200) {
					myElement.style.height = availableHeight + 'px';
				}
			}
		}
	}
}
</script>
<style lang=less>
.header {
	height: 46px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 2;

	/* Extra large devices (large laptops and desktops, 1200px and up) */
	@media only screen and (min-width: 1200px) {
		height: 102px;
	}

	.header-container {
		justify-content: space-between;
		padding: 25px 27px;
		align-items: center;

		@media only screen and (min-width: 992px) {
			padding: 25px 54px;
		}

		@media only screen and (min-width: 1200px) {
			padding: 25px 129px 25px 117px;
			// max-width: 1600px;
			// margin: 0 auto;
		}

		.logo {
			cursor: pointer;
			z-index: 5;

			.logo-img {
				width: 28px;
				height: 28px;

				@media only screen and (min-width: 1200px) {
					width: 38px;
					height: 38px;
				}
			}

			.logo-img-landing {
				display: none;
			}

			.logo-img-menu {
				display: none;
			}
		}

		.menu {
			display: none;

			/* Extra large devices (large laptops and desktops, 1200px and up) */
			@media only screen and (min-width: 1200px) {
				display: flex;
				font-family: 'Suisse International';
				font-weight: 500;
				color: #232121;
				font-size: 20px;
				line-height: 26px;
				text-align: right;
			}

			.menu-item {
				height: 26px;
				width: 117px;
			}

			.m-item {
				cursor: pointer;
			}

			.design,
			.folk {
				padding-right: 50px;
			}
		}

		.bm-burger-button {
			position: fixed;
			width: 18px;
			height: 12px;
			right: 36px !important;
			left: auto;
			top: 36px;
			cursor: pointer;
		}

		.bm-burger-bars {
			background-color: #1C1B1F;
		}

		.bm-menu {
			background-color: #151313;
		}
	}

	.mobile-menu {

		/* Extra large devices (large laptops and desktops, 1200px and up) */
		@media only screen and (min-width: 1200px) {
			display: none;
		}

		.m-items {
			display: flex;
			flex-direction: column;
			align-items: center;
			// justify-content: center;
			height: 100%;
			margin-top: 120px;
		}

		.mobile-landscape-scroll {
			overflow-y: auto;
			overflow-x: hidden;
		}

		.mobile-menu-item {
			width: 100vw;
			// height: 100vh;
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: #151313;
			z-index: 3;
			transition: all 0.3s ease-in;

			.m-menu-item {
				font-weight: 400;
				font-size: 48px;
				line-height: 46px;
				letter-spacing: 0.005em;
				color: #A7A49F;
				padding-top: 40px;
				padding-bottom: 40px;
				border: 0.5px solid #53514F;
				width: 100%;
				display: flex;
				text-transform: uppercase;
				justify-content: left;
				padding-left: 28px;

				@media only screen and (max-width: 300px) {
					font-size: 42px;
					line-height: 44px;
				}
			}

			.m-menu-item-n {
				font-family: 'Suisse International Mono';
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 26px;
				letter-spacing: 0.005em;
				color: #767471;
				margin-right: 18px;
				margin-left: 27px;

				@media only screen and (max-width: 300px) {
					margin-right: 12px;
					margin-left: 17px;
				}
			}
		}

		.copy-info {
			font-family: 'Suisse International Mono';
			font-style: normal;
			font-weight: 700;
			font-size: 11px;
			line-height: 13px;
			letter-spacing: 0.005em;
			color: #8D8B87;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: fixed;
			width: 100%;
			bottom: 20px;
			text-align: center;
			// transition: all 2s ease-in-out;

			@media only screen and (min-width: 992px) {
				flex-direction: row;
				font-size: 12px;
				line-height: 15px;
			}

			& div {
				padding: 0;

				@media only screen and (min-width: 992px) {
					padding: 0 1px;
				}
			}

			& img {
				width: 10px;
				height: 10px;
			}
		}
	}
}

.hamburger-lines {
	display: block;
	// height: 20px;
	height: 13px;
	width: 16px;
	position: absolute;
	top: 32px;
	right: 30px;
	z-index: 4;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media only screen and (min-width: 992px) {
		right: 54px;
	}
}

.hamburger-lines .line {
	display: block;
	height: 3px;
	width: 100%;
	// border-radius: 10px;
	background: #1C1B1F;
}

.overlay-color {
	background: #8D8B87;
}

.hamburger-lines .line1 {
	transform-origin: 0% 0%;
	transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
	transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
	transform-origin: 0% 100%;
	transition: transform 0.4s ease-in-out;
}

.line1-tran {
	transform: rotate(45deg);
}

.line2-tran {
	transform: scaleY(0);
}

.line3-tran {
	transform: rotate(-45deg);
}

.slide-fade-enter-active {
	transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
	transition: all 0.5s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translate(100%, 0);
	opacity: 0;
}

.logo-fade-enter-active {
	transition: all 0.3s ease-out;
}

.logo-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.logo-fade-enter-from,
.logo-fade-leave-to {
	transform: translateX(-10px);
	opacity: 0;
}
</style>