<template>
    <div class="archived case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Archived
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Archived
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Visualisation
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2021
                            </div>
                        </div>
                        <div class="team">
                            <div>
                                Client:
                            </div>
                            <div>
                                ARCHIVED
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        ARCHIVED is a leading educational resource and boutique fashion was seeking their first merch store
                        product.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                The Aluminium Clothing Hanger is the result of ARCHIVED commitment to reimagine everyday
                                items in the face of a market awash with short-lived, over-designed products. Our goal was
                                to create a premium, enduring hanger with a minimalist aesthetic, tasteful color palette,
                                and superior material quality. A hanger designed to weather the decades, embodying a
                                timeless appeal for all style preferences. During an intense 6 weeks of product development
                                process, the client and I closely collaborated on various design elements from CMF, forms
                                and functionality. Lot of attention was given to translating the brand value and design
                                language into the product.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    SIMPLE, STRONG, STYLISH. WATER JETTED <span class="color-text">Aluminium Hanger, carved from a single
                        sheet of metal,</span>
                    effortlessly blending durability with a sleek design for daily use.
                </div>
                <div class="overview-description desktop">
                    <div>
                        SIMPLE, STRONG, STYLISH. WATER
                    </div>
                    <div>
                        JETTED <span class="color-text">Aluminium Hanger,</span>
                    </div>
                    <div class="color-text">
                        carved from a single sheet of
                    </div>
                    <div>
                        <span class="color-text">metal,</span> effortlessly blending
                    </div>
                    <div>
                        durability with a sleek design
                    </div>
                    <div>
                        for daily use.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 padded-container parallax-in">
            <div class="image-container">
                <img class="image1" src="../../assets/design/archived/image1.png" alt="Product Image">
            </div>
            <div class="image-caption">
                <div class="mobile">
                    ARCHIVED is a leading educational resource and boutique surrounding archive fashion.
                </div>
                <div class="desktop">
                    <div>
                        ARCHIVED is a leading educational resource
                    </div>
                    <div>
                        and boutique surrounding archive fashion.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="title">
                <div class="mobile">
                    The clothing hanger was designed around the brief that it should be “magnet, minimal material, fluid,
                    functional, metal, silver.” The rounded edges and smooth contour lines of the hanger speak to these
                    qualities.
                </div>
                <div class="desktop">
                    <div>
                        The clothing hanger was designed around the brief that it should
                    </div>
                    <div>
                        be “magnet, minimal material, fluid, functional, metal, silver.” The
                    </div>
                    <div>
                        rounded edges and smooth contour lines of the hanger speak
                    </div>
                    <div>
                        to these qualities.
                    </div>
                </div>
            </div>
            <div class="sub-title title">
                Material: Water jetted aluminium sheets
            </div>
        </div>
        <div class="section-3 padded-container parallax-in">
            <div class="image-container">
                <img class="image2" src="../../assets/design/archived/image2.png" alt="Figure 1">
            </div>
            <div class="image-caption">
                <div>
                    Fig 1:
                </div>
                <div class="mobile">
                    The hanger brand language is heavily inspired by the minimalistic and brutalist values of ARCHIVED, that
                    are reflected in the exposed bolts and raw CMF.
                </div>
                <div class="desktop">
                    <div>
                        The hanger brand language is heavily
                    </div>
                    <div>
                        inspired by the minimalistic and brutalist
                    </div>
                    <div>
                        values of ARCHIVED, that are reflected in
                    </div>
                    <div>
                        the exposed bolts and raw CMF.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-4 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image3" src="../../assets/design/archived/image3.png" alt="Fig 2">
                    </div>
                    <div class="image-container">
                        <img class="image4" src="../../assets/design/archived/image4.png" alt="Fig 2">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 2:
                        </div>
                        <div class="mobile">
                            The aluminium panels have a bold archived logo etched using CNC milling
                        </div>
                        <div class="desktop">
                            <div>
                                The aluminium panels have a bold
                            </div>
                            <div>
                                archived logo etched using CNC milling
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="image-container">
                <img class="image5" src="../../assets/design/archived/image5.png" alt="Figure 3">
            </div>
            <div class="image-caption">
                <div>
                    Fig 3:
                </div>
                <div class="mobile">
                    Hanger form development exploring different ways to build a aluminium hanger
                </div>
                <div class="desktop">
                    <div>
                        Hanger form development exploring different
                    </div>
                    <div>
                        different ways to build a aluminium hanger
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 parallax-in">
            <Carousel :itemsToShow="1.2" :breakpoints="breakpoints" :wrapAround="true" :transition="300" v-model="currentSlide1">
                <Slide @click="moveSlide1" v-for="  (      slide, index      )   in         slideImages1        "
                    :key="index">
                    <img class="archive-images" :src="require(`../../assets/design/archived/${slide.src}.png`)"
                        :alt="`slide_${index}`">
                </Slide>
            </Carousel>
            <div class="carousel-caption padded-container">
                <div v-if="currentSlide1 < 5" class="image-caption">
                    {{ slideImages1[currentSlide1].text }}
                </div>
                <div v-else class="image-caption">
                    {{ slideImages1[0].text }}
                </div>
            </div>
        </div>
        <div class="section-7 padded-container parallax-in">
            <div class="image-container">
                <img class="image6" src="../../assets/design/archived/image6.png" alt="Product Image">
            </div>
            <div class="title">
                <div class="mobile">
                    The design process ensured a cost-effective manufacturing by using precise water jetting techniques,
                    carving all components from a single sheet of aluminium, reducing material waste.
                </div>
                <div class="desktop">
                    <div>
                        The design process ensured a cost-effective manufacturing by
                    </div>
                    <div>
                        using precise water jetting techniques, carving all components
                    </div>
                    <div>
                        from a single sheet of aluminium, reducing material waste.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-8 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image7" src="../../assets/design/archived/image7.png" alt="Fig 4">
                    </div>
                    <div class="image-container">
                        <img class="image8" src="../../assets/design/archived/image8.png" alt="Fig 5">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div class="mobile">
                            Fig 4-5:We 3D printed prototypes to test multiple ways to build and use different types of
                            hanger concepts. We took time to find the right signifier and designs that accommodate other
                            functions like hanging pants, string tops etc.
                        </div>
                        <div class="desktop">
                            <div>
                                Fig 4-5:We 3D printed prototypes to test multiple ways to build
                            </div>
                            <div>
                                and use different types of hanger concepts. We took time to find
                            </div>
                            <div>
                                the right signifier and designs that accommodate other functions
                            </div>
                            <div>
                                like hanging pants, string tops etc.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="image-container">
                <img class="image9" src="../../assets/design/archived/image9.png" alt="Figure 6">
            </div>
            <div class="image-caption">
                <div>
                    Fig 6:
                </div>
                <div>
                    Final Concept V2
                </div>
            </div>
        </div>
        <div class="section-10 padded-container parallax-in">
            <div class="image-container">
                <img class="image10" src="../../assets/design/archived/image10.png" alt="Product Image">
            </div>
        </div>
        <div class="section-11 archive-section parallax-in">
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'Archived',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            slideImages1: [
                {
                    src: 'slide1',
                    text: 'Lighting fixture, Source: Unknown'
                },
                {
                    src: 'slide2',
                    text: 'after multiple rounds of ideation and concept development the client inclined to personify the Grid Bench by Mario Tsai Studio. The inspiration aligned perfectly with archived brand values'
                },
                {
                    src: 'slide3',
                    text: 'The Grid Bench by Mario Tsai Studio.'
                },
                {
                    src: 'slide4',
                    text: 'Design Sessions with ARCHIVED'
                },
                {
                    src: 'slide5',
                    text: ''
                },
            ],
            currentSlide1: 0,
            breakpoints: {
                1200: {
                    itemsToShow: 1.95,
                },
                2000: {
                    itemsToShow: 2.95,
                },
                3800: {
                    itemsToShow: 3.95,
                },
            },
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },

        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.archived {
    .landing-section-mobile {
        background: url('~@/assets/design/archived/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/archived/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #788693;
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 55%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image1 {
            width: 100%;
        }
    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }
    }

    .section-3 {
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        .image-container {
            @media only screen and (min-width: 992px) {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .image2 {
            width: 100%;
        }
    }

    .section-4 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image3,
        .image4 {
            width: 100%;
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image5 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 150px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .archive-images {
            width: 90%;
            height: 100%;
            // width: 778.204px;
            // height: 554.74px;
            object-fit: contain;
        }

        .carousel-caption {
            margin: 20px 0 0 0;
            height: 80px;

            @media only screen and (min-width: 992px) {
                width: 449px; //1700px
                height: 200px;
                margin: 50px 243px 0 auto;
            }
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image6 {
            width: 100%;
            margin-top: 42px;
        }

        .title {
            @media only screen and (min-width: 992px) {
                margin-top: 52px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 52px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 62px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 72px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 110px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 213px;
            }
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image7,
        .image8 {
            width: 100%;
        }
    }

    .section-9 {
        margin-top: 42px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 52px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 52px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 62px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 72px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 110px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 213px;
        }

        .image9 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image10 {
            width: 100%;
        }
    }



    .section-11 {
        background: url('~@/assets/design/archived/image11.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .common-footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        margin-top: 0;
    }
}
</style>