<template>
    <div class="nirav-modi case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Nirav
                </div>
                <div>
                    Modi
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Nirav Modi
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Interaction Design
                            </div>
                            <div>
                                Research
                            </div>
                            <div>
                                Visual Design
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2023
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Renowned for his expectional craft, Nirav Modi's talent shines despite controversy. Arrested, but
                        admired.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                Nirav Modi is a Haute Diamanterie know for this his simple pieces with complex mechanism,
                                but was arrested for mismanaging in his funds. During his peak he built a leading
                                international luxury brand with stores and atelier's in NYC, Paris, Mumbai. Modi’s work and
                                designs were know for their unconventional, contemporary approach to diamond jewellery. For
                                example his elastic gold bangle.
                            </div>
                            <div class="sub-text m-4-top">
                                But his craftsmanship has inspired me to take up his brand and re interpret it.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    Nirav Modi's redesigned <span class="color-text">digital experience prioritises craft & appeals to Gen-Z
                        sophisticates. THE NEW
                        design offers superior service</span> with streamlined user experience, emphasising immediacy and
                    simplicity
                    over complexity.
                </div>
                <div class="overview-description desktop">
                    <div>
                        Nirav Modi's redesigned <span class="color-text">digital</span>
                    </div>
                    <div class="color-text">
                        experience prioritises craft &
                    </div>
                    <div class="color-text">
                        appeals to Gen-Z sophisticates.
                    </div>
                    <div>
                        THE NEW design offers superior
                    </div>
                    <div>
                        service with streamlined user
                    </div>
                    <div>
                        experience, emphasising
                    </div>
                    <div>
                        immediacy and simplicity over
                    </div>
                    <div>
                        complexity.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 parallax-in">
            <div class="image-container">
                <img class="image1" src="../../assets/design/nirav-modi/image1.png" alt="nirav modi">
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="title">
                The redesign focuses on a non-retail website format as luxury shoppers are typically well-informed about
                their favourite brands and prefer a straightforward experience. This approach assists them in assessing the
                product and providing pertinent details that facilitate an in-store purchase decision.
            </div>
        </div>
        <div class="section-3 carousel-section parallax-in">
            <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide1">
                <Slide @click="moveSlide1" v-for="  (      slide, index      )   in         slideImages1        "
                    :key="index">
                    <img class="archive-images" :src="require(`../../assets/design/nirav-modi/${slide.src}.png`)"
                        :alt="`slide_${index}`">
                </Slide>
            </Carousel>
            <div class="carousel-caption padded-container">
                <div v-if="currentSlide1 < 3" class="image-caption">
                    {{ slideImages1[currentSlide1].text }}
                </div>
                <div v-else class="image-caption">
                    {{ slideImages1[0].text }}
                </div>
            </div>
        </div>
        <div class="section-4 parallax-in">
            <div class="padded-container">
                <div class="title">
                    Digital Experience
                </div>
            </div>
            <div class="background-image">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video1">
                    <source src="../../assets/design/nirav-modi/NM01.webm" type="video/webm" />
                </video>
            </div>
            <div class="padded-container">
                <div class="image-caption">
                    <div>
                        Video 1:
                    </div>
                    <div class="mobile">
                        The main page of a luxury eCommerce shop is like the lobby of a real one. The brand’s philosophy
                        and
                        attention to detail should infuse the design of the homepage. Instead of a champagne glass, you
                        get
                        a “wow” feeling. Also, as you stand there, gasping, you should understand where to go next —
                        because
                        there are no assistants around.
                    </div>
                    <div class="desktop">
                        <div>
                            The main page of a luxury eCommerce shop is like the lobby of a real
                        </div>
                        <div>
                            one. The brand’s philosophy and attention to detail should infuse the
                        </div>
                        <div>
                            design of the homepage. Instead of a champagne glass, you get a “wow”
                        </div>
                        <div>
                            feeling. Also, as you stand there, gasping, you should understand
                        </div>
                        <div>
                            where to go next — because there are no assistants around.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="title">
                The website prioritises creating an immersive browsing experience, showcasing the intricate process behind
                the collections and highlighting the jewellery with refined animation.
            </div>
        </div>
        <div class="section-6 padded-container parallax-in">
            <div class="image-container">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video2">
                    <source src="../../assets/design/nirav-modi/NM02.webm" type="video/webm" />
                </video>
                <div class="image-caption">
                    <div>
                        Video 2:
                    </div>
                    <div class="mobile">
                        The brand's craftsmanship roots define its appeal, exemplifying the website as an immersive showcase
                        of Modi’s artistry.
                    </div>
                    <div class="desktop">
                        <div>
                            The brand's craftsmanship roots define
                        </div>
                        <div>
                            its appeal, exemplifying the website as
                        </div>
                        <div>
                            an immersive showcase of Modi’s artistry.
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video3">
                    <source src="../../assets/design/nirav-modi/NM03.webm" type="video/webm" />
                </video>
                <div class="image-caption">
                    <div>
                        Video 3:
                    </div>
                    <div class="mobile">
                        Each page incorporates functional elements tailored to its purpose, such as product measurements,
                        styling suggestions, and multiple angles.
                    </div>
                    <div class="desktop">
                        <div>
                            Each page incorporates functional elements tailored
                        </div>
                        <div>
                            to its purpose, such as product measurements,
                        </div>
                        <div>
                            styling suggestions, and multiple angles.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-7 parallax-in">
            <div class="image-container">
                <img class="image3" src="../../assets/design/nirav-modi/image3.png" alt="nirav modi">
            </div>
        </div>
        <div class="section-8 padded-container parallax-in">
            <div class="image-container">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video4">
                    <source src="../../assets/design/nirav-modi/NM04.webm" type="video/webm" />
                </video>
                <div class="image-caption">
                    <div>
                        Video 4:
                    </div>
                    <div class="mobile">
                        Inspired from the brands original design principles “want the diamonds to have the spotlight”. Only
                        showing the UI elements that are relevant for highlighting the jewellery..
                    </div>
                    <div class="desktop">
                        <div>
                            Inspired from the brands original design
                        </div>
                        <div>
                            principles “want the diamonds to have the
                        </div>
                        <div>
                            spotlight”. Only showing the UI elements that
                        </div>
                        <div>
                            are relevant for highlighting the jewellery..
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video5">
                    <source src="../../assets/design/nirav-modi/NM05.webm" type="video/webm" />
                </video>
                <div class="image-caption">
                    <div>
                        Video 5:
                    </div>
                    <div class="mobile">
                        providing product details that spark interest, and avoiding interruptions that risk cheapening the
                        brand.
                    </div>
                    <div class="desktop">
                        <div>
                            providing product details that spark
                        </div>
                        <div>
                            interest, and avoiding interruptions that
                        </div>
                        <div>
                            risk cheapening the brand.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="title">
                Visual System
            </div>
            <div class="image-container">
                <img class="image4" src="../../assets/design/nirav-modi/image4.png" alt="visual system">
            </div>
            <div class="three-imgs">
                <img class="image5" src="../../assets/design/nirav-modi/image5.png" alt="visual system">
                <img class="image6" src="../../assets/design/nirav-modi/image6.png" alt="visual system">
                <img class="image7" src="../../assets/design/nirav-modi/image7.png" alt="visual system">
            </div>
        </div>
        <div class="section-10 padded-container parallax-in">
            <div class="image-container">
                <video preload="auto" controls="false" autoplay muted loop webkit-playsinline playsinline class="video6">
                    <source src="../../assets/design/nirav-modi/NM06.webm" type="video/webm" />
                </video>
                <div class="image-caption">
                    <div>
                        Video 6:
                    </div>
                    <div class="mobile">
                        Delicate and restrained motion and visuals highlights & complements the brands graceful product
                        identity.
                    </div>
                    <div class="desktop">
                        <div>
                            Delicate and restrained motion and visuals highlights
                        </div>
                        <div>
                            & complements the brands graceful product
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-11 padded-container parallax-in">
            <div class="image-container">
                <img class="image8" src="../../assets/design/nirav-modi/image8.png" alt="visual system">
            </div>
            <div class="two-image-container m-4-top">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image9" src="../../assets/design/nirav-modi/image9.png" alt="visual system">
                    </div>
                    <div class="image-container">
                        <img class="image10" src="../../assets/design/nirav-modi/image10.png" alt="visual system">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div class="title">
                            Type Guidelines
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo3 = !showInfo3;" class="readmore relative">
                            <span v-if="showInfo3">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo3 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo3" class="additional-info two-content-section">
                                <div class="para-spacer sub-text">
                                    The header san serif replicates the feeling on a hand-pressed type which is a nod to the
                                    handcrafted jewels, and the body type is a contrast which has subtle curves that still
                                    retains sharp points and angles which are a nod to how diamonds are cut. Where
                                    mechanical shortcuts would have been at hand, the design opts instead for elaborately
                                    measured solutions. Strict design rules are followed in a handcrafted manner, liberating
                                    the typeface from past paradigms.
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-12 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image11" src="../../assets/design/nirav-modi/image11.png" alt="visual system">
                    </div>
                    <div class="image-container">
                        <img class="image12" src="../../assets/design/nirav-modi/image12.png" alt="visual system">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div class="title">
                            Color & Icons
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo3 = !showInfo3;" class="readmore relative">
                            <span v-if="showInfo3">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo3 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo3" class="additional-info two-content-section">
                                <div class="para-spacer sub-text">
                                    Updated colorway uses slightly statured colors so that they dont over power and pull
                                    attention away from the jewellery. Sharp symbols display corners with straight edges,
                                    for a crisp style that remains legible even at smaller scales. These icons pairs well
                                    with brands heavier typography and rounded edges.
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-13 parallax-in">
            <div class="image-container">
                <img class="image13" src="../../assets/design/nirav-modi/image13.png" alt="visual system">
            </div>
        </div>
        <div class="archive-section">
            <div class="archive-title-section">
                <div class="archive-title">
                    Archive
                </div>
            </div>
            <div class="archive-text padded-container">
                I carried out a thorough exploration to understand the brand's essence using a show-and-tell exercise,
                simultaneously gauging its positioning relative to other luxury brands. For example, Nirav Modi's brand
                shares minimal product information and does not offer online purchases, a strategy that differs from
                brands like Tiffany.
            </div>
            <div class="archive-section-1 padded-container parallax-in">
                <div class="image-container">
                    <img class="image14" src="../../assets/design/nirav-modi/image14.png" alt="Fig 1">
                    <div class="archive-caption">
                        <div>
                            Fig 1:
                        </div>
                        <div>
                            Nirav Modi brand positioning
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-section-2 padded-container parallax-in">
                <div class="three-imgs">
                    <img class="image15" src="../../assets/design/nirav-modi/image15.png"
                        alt="Visual language development ">
                    <img class="image16" src="../../assets/design/nirav-modi/image16.png"
                        alt="Visual language development ">
                    <img class="image17" src="../../assets/design/nirav-modi/image17.png"
                        alt="Visual language development ">
                </div>
                <div class="image-caption">
                    Visual language development
                </div>
            </div>
            <div class="archive-section-3 padded-container parallax-in">
                <div class="three-imgs">
                    <img class="image18" src="../../assets/design/nirav-modi/image18.png"
                        alt="Collection page UI iterations">
                    <img class="image19" src="../../assets/design/nirav-modi/image19.png"
                        alt="Collection page UI iterations">
                    <img class="image20" src="../../assets/design/nirav-modi/image20.png"
                        alt="Collection page UI iterations">
                </div>
                <div class="image-caption">
                    Collection page UI iterations
                </div>
            </div>
            <div class="archive-section-4 carousel-section parallax-in">
                <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide2">
                    <Slide @click="moveSlide2" v-for="  (      slide, index      )   in         slideImages2        "
                        :key="index">
                        <img class="archive-images" :src="require(`../../assets/design/nirav-modi/${slide.src}.png`)"
                            :alt="`slide_${index}`">
                    </Slide>
                </Carousel>
                <div class="carousel-caption padded-container">
                    <div v-if="currentSlide2 < 4" class="archive-caption">
                        {{ slideImages2[currentSlide2].text }}
                    </div>
                    <div v-else class="archive-caption">
                        {{ slideImages2[0].text }}
                    </div>
                </div>
            </div>
            <div class="archive-section-5 padded-container parallax-in">
                <div class="two-imgs">
                    <img class="image21" src="../../assets/design/nirav-modi/image21.png"
                        alt="Design collection page UI iterations">
                    <img class="image22" src="../../assets/design/nirav-modi/image22.png"
                        alt="Design collection page UI iterations">
                </div>
                <div class="archive-caption">
                    Design collection page UI iterations
                </div>
            </div>
            <div class="archive-section-6 carousel-section parallax-in">
                <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide3">
                    <Slide @click="moveSlide3" v-for="  (      slide, index      )   in         slideImages3        "
                        :key="index">
                        <img class="archive-images" :src="require(`../../assets/design/nirav-modi/${slide.src}.png`)"
                            :alt="`slide_${index}`">
                    </Slide>
                </Carousel>
                <div class="carousel-caption padded-container">
                    <div v-if="currentSlide3 < 3" class="archive-caption">
                        {{ slideImages3[currentSlide3].text }}
                    </div>
                    <div v-else class="archive-caption">
                        {{ slideImages3[0].text }}
                    </div>
                </div>
            </div>
            <div class="common-footer parallax-in">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'NiravModi',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            slideImages1: [
                {
                    src: 'slide1',
                    text: 'Customer archetype research and decision driver summary '
                },
                {
                    src: 'slide2',
                    text: 'Brand research and deep dive, Research 2022.'
                },
                {
                    src: 'slide3',
                    text: 'Brand design principles that inform the UI and interaction and design '
                }
            ],
            slideImages2: [
                {
                    src: 'slide4',
                    text: 'Direction 1'
                },
                {
                    src: 'slide5',
                    text: 'Direction 2'
                },
                {
                    src: 'slide6',
                    text: 'Direction 3'
                },
                {
                    src: 'slide7',
                    text: 'Direction 4'
                },
            ],
            slideImages3: [
                {
                    src: 'slide8',
                    text: 'Homepage Direction 1'
                },
                {
                    src: 'slide9',
                    text: 'Homepage Direction 2'
                },
                {
                    src: 'slide10',
                    text: 'Homepage Direction 3'
                },
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide1: 0,
            currentSlide2: 0,
            currentSlide3: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },
        moveSlide2() {
            this.currentSlide2++;
        },
        moveSlide3() {
            this.currentSlide3++;
        },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.nirav-modi {
    .landing-section-mobile {
        background: url('~@/assets/design/nirav-modi/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/nirav-modi/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #FF2D2D;
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image1 {
            width: 100%;
        }
    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }
    }

    .section-3 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .archive-images {
            width: 100%;
            object-fit: cover;
        }

        .carousel-caption {
            margin: 20px 0 0 0;
            height: 80px;

            @media only screen and (min-width: 992px) {
                width: 449px; //1700px
                height: 200px;
                margin: 50px 253px 0 auto;
            }
        }
    }

    .section-4 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .background-image {
            background: url('~@/assets/design/nirav-modi/image2.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 103vh;
            width: 100%;
            position: relative;
            display: block;

            .video1 {
                width: 60%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            width: 60%;
            margin-top: 125px;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 229px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .video2,
        .video3 {
            width: 100%;
        }

        .video3 {
            margin-top: 90px;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 229px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image3 {
            width: 100%;
        }
    }



    .section-8 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 206px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 342px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }

        .video4,
        .video5 {
            width: 100%;
        }

        .video5 {
            margin-top: 170px;

            @media only screen and (min-width: 992px) {
                margin-top: 206px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 266px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 315px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 359px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 420px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 790px;
            }
        }
    }

    .section-9 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image4 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 60%;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .video6 {
            width: 100%;
        }
    }

    .section-11 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image8,
        .image9,
        .image10 {
            width: 100%;
        }
    }

    .section-12 {
        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image11,
        .image12 {
            width: 100%;
        }
    }

    .section-13 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image13 {
            width: 100%;
        }
    }

    .archive-section {
        margin-top: -5px;

        .archive-section-1 {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 130px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 140px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 228px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 298px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 306px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 548px;
            }

            .image14 {
                width: 100%;
            }
        }
    }

    .archive-section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .archive-section-3 {
        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }
    }

    .archive-section-4 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .carousel-caption {
            margin: 20px 0 0 0;
            height: 80px;

            @media only screen and (min-width: 992px) {
                width: fit-content; //1700px
                height: 200px;
                margin: 50px 243px 0 auto;
            }
        }
    }

    .archive-section-5 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 359px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }
    }

    .archive-section-6 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 359px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }

        .carousel-caption {
            margin: 20px 0 0 0;
            height: 80px;

            @media only screen and (min-width: 992px) {
                width: fit-content; //1700px
                height: 200px;
                margin: 50px 243px 0 auto;
            }
        }
    }
}
</style>