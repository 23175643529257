<template>
    <div class="padded-container design-page header-height all-cursor">
        <div class="design-mobile">
            <div class="design-imgs-container" v-for="(item, index) in albumLinks" :key="index">
                <img @click="this.$router.push('/design/' + item.link)" class="design-imgs pointer"
                    :src="require(`../assets/design/icons/${item.src}.png`)" :alt="item.title">
            </div>
        </div>
        <div class="design-desktop d-flex">
            <div class="img-section">
                <div class="image-container">
                    <Transition name="slide-up">
                        <div v-if="currentIndex == 0">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[0].src}.png`)"
                                :alt="albumLinks[0].title">
                        </div>
                        <div v-else-if="currentIndex == 1">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[1].src}.png`)"
                                :alt="albumLinks[1].title">
                        </div>
                        <div v-else-if="currentIndex == 2">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[2].src}.png`)"
                                :alt="albumLinks[2].title">
                        </div>
                        <div v-else-if="currentIndex == 3">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[3].src}.png`)"
                                :alt="albumLinks[3].title">
                        </div>
                        <div v-else-if="currentIndex == 4">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[4].src}.png`)"
                                :alt="albumLinks[4].title">
                        </div>
                        <div v-else-if="currentIndex == 5">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[5].src}.png`)"
                                :alt="albumLinks[5].title">
                        </div>
                        <div v-else-if="currentIndex == 6">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[6].src}.png`)"
                                :alt="albumLinks[6].title">
                        </div>
                        <div v-else-if="currentIndex == 7">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[7].src}.png`)"
                                :alt="albumLinks[7].title">
                        </div>
                        <div v-else-if="currentIndex == 8">
                            <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[8].src}.png`)"
                                :alt="albumLinks[8].title">
                        </div>
                        <div v-else-if="currentIndex == 9">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[9].src}.png`)"
                            :alt="albumLinks[9].title">
                    </div>
                    <!-- <div v-else-if="currentIndex == 10">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[10].src}.png`)"
                            :alt="albumLinks[10].title">
                    </div> -->
                    </Transition>
                </div>
                <!-- <img class="preview-images" :src="require(`../assets/design/${albumLinks[currentIndex].src}.png`)"
                    :alt="albumLinks[currentIndex].title"> -->

                <!-- Temp 8 -->

                <!-- <div v-else-if="currentIndex == 8">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[8].src}.png`)"
                            :alt="albumLinks[8].title">
                    </div> -->
                <!-- <div v-else-if="currentIndex == 9">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[9].src}.png`)"
                            :alt="albumLinks[9].title">
                    </div> -->
                <!-- <div v-else-if="currentIndex == 10">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[10].src}.png`)"
                            :alt="albumLinks[10].title">
                    </div>
                    <div v-else-if="currentIndex == 11">
                        <img class="preview-images" :src="require(`../assets/design/${albumLinks[11].src}.png`)"
                            :alt="albumLinks[11].title">
                    </div>
                    <div v-else-if="currentIndex == 12">
                        <img class="preview-images" :src="require(`../assets/design/icons/${albumLinks[12].src}.png`)"
                            :alt="albumLinks[12].title">
                    </div> -->

            </div>
            <div class="title-section">
                <div @mouseover="getElement" :class="'title' + index" class="case-title"
                    v-for="(item, index) in   albumLinks  " :key="index">
                    <div :style="{ color: isHovering[index] ? getRandomColor() : '#393736' }" class="title-span"
                        :class="{ 'slide-left': isHovering[index], 'amb-comp': item.link == 'ambient-computing', 'pointer': item.hover }"
                        @click="routeCase(item)" @mouseover="setIndex(index); activeElement(index, true)"
                        @mouseleave=" activeElement(index, false)">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DesignView',
    components: {},
    data: function () {
        return {
            albumLinks: [
                {
                    src: 'cloud-gaming',
                    title: 'Cloud Gaming',
                    link: 'cloud-gaming',
                    hover: true
                },
                {
                    src: 'nirav-modi',
                    title: 'Nirav Modi',
                    link: 'nirav-modi',
                    hover: true
                },
                // {
                //     src: 'kohler',
                //     usrc: 'kohler_un',
                //     title: 'Kohler',
                //     link: 'kohler-compost'
                // },
                {
                    src: 'luna',
                    title: 'Luna',
                    link: 'luna',
                    hover: true
                },
                {
                    src: 'google-ar',
                    title: 'Google AR',
                    link: 'google-ar',
                    hover: true
                },
                {
                    src: 'ambient-computing',
                    title: 'AMBIENT COMPUTING',
                    link: 'ambient-computing',
                    hover: true
                },
                {
                    src: 'cnn',
                    title: 'CNN',
                    link: 'cnn',
                    hover: true
                },

                {
                    src: 'neva',
                    title: 'NEVA',
                    link: 'neva'
                },
                // {
                //     src: 'tuudr',
                //     usrc: 'tuudr_un',
                //     title: 'TUUDR',
                //     link: 'tuudr'
                // },
                {
                    src: 'archived',
                    title: 'ARCHIVED CO.',
                    link: 'archived',
                    hover: true
                },
                {
                    src: 'lg',
                    title: 'LG',
                    link: 'lg',
                    hover: false
                },
                {
                    src: 'stahl',
                    title: 'Stahl',
                    link: 'stahl',
                    hover: false
                },
                // {
                //     src: 'kohler2',
                //     usrc: 'kohler2_un',
                //     title: 'Kohler',
                //     link: 'kohler-faucet'
                // },

            ],
            currentIndex: -1,
            isHovering: []
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.setPreviewImagesWidth();
        window.addEventListener('resize', this.setPreviewImagesWidth);
    },
    methods: {
        setIndex(index) {
            this.currentIndex = index;
        },
        activeElement(index, x) {
            this.isHovering[index] = x;
        },
        routeCase(item) {
            if (item.link == 'lg') {
                
            } else if (item.link == 'stahl') {

            } else {
                this.$router.push('/design/' + item.link);
            }
        },
        getRandomColor() {
            const colors = ["#74B3E2", "#E93A4C", "#BCDB4A", "#9C835E", "#C82636", "#ECD848",
                "#285DD4", "#317269", "#F09DDD", "#DF4421", "#C1C7BF", "#EB5F2A", "#7452F5"];
            const randomColor = colors[Math.floor(Math.random() * colors.length)];
            return randomColor;
        },
        setPreviewImagesWidth() {
            let el = document.getElementsByClassName('img-section')[0];
            console.log(el.offsetWidth)
            let offset = el.offsetWidth;
            let imgEls = document.getElementsByClassName('image-container')[0];
            imgEls.style.width = offset - 100 + 'px';
            // imgEls.style.height = '100%';
        }
    },
}
</script>
<style lang=less>
.design-page {
    .design-mobile {
        @media only screen and (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media only screen and (min-width: 1200px) {
            display: none;
        }

        .design-imgs-container {
            @media only screen and (min-width: 992px) {
                width: 33%;
                display: flex;
                justify-content: center;
            }

            .design-imgs {
                width: 100%;
                margin-top: 60px;
                height: 315px;
                object-fit: contain;

                @media only screen and (min-width: 992px) {
                    width: 90%;
                }

            }
        }
    }

    .design-desktop {
        display: none;

        @media only screen and (min-width: 1200px) {
            display: flex;
            // float: left;
        }

        .img-section {
            width: 100%;
            position: relative;

            .preview-images {
                width: 30%;
                @media only screen and (min-width: 1200px) {
                    width: 300px;
                }

                @media only screen and (min-width: 1500px) {
                    width: 400px;
                }

                @media only screen and (min-width: 1700px) {
                    width: 500px;
                }

                @media only screen and (min-width: 2000px) {
                    width: 700px;
                }

                @media only screen and (min-width: 3839px) {
                    width: 1200px;
                }
                // height: 50%; // give hard values for transition
                object-fit: contain;
                position: fixed;
                top: 50%;
                left: 25%;
                transform: translate(-50%, -50%);
            }
        }

        .title-section {
            width: 100%;
            margin-top: 175px;

            @media only screen and (min-width: 992px) {
                margin-left: 5%;
            }

            @media only screen and (min-width: 1700px) {
                margin-left: 8%;
            }

            .case-title {
                font-family: 'Suisse International Condensed';
                font-style: normal;
                font-weight: 700;
                letter-spacing: -0.025em;
                text-transform: uppercase;
                color: #393736;
                margin-bottom: 30px;
                font-size: 48px;
                line-height: 47px;

                @media only screen and (min-width: 992px) {
                    font-size: 64px;
                    line-height: 98.7%;
                    letter-spacing: -1.582px;
                }

                @media only screen and (min-width: 1200px) {
                    font-size: 78px;
                    line-height: 98.7%;
                    letter-spacing: -2.043px;
                }

                @media only screen and (min-width: 1500px) {
                    font-size: 96px;
                    line-height: 98.7%;
                    letter-spacing: -2.414px;
                }

                @media only screen and (min-width: 1700px) {
                    font-size: 110px;
                    line-height: 98.7%;
                    letter-spacing: -2.75px;
                }

                @media only screen and (min-width: 2000px) {
                    font-size: 130px;
                    line-height: 98.7%;
                    letter-spacing: -3.262px;
                }

                @media only screen and (min-width: 3839px) {
                    font-size: 244px;
                    line-height: 98.7%;
                    letter-spacing: -6.113px;
                }

                .title-span {
                    // margin-left: 0;
                    transition: all 0.3s ease-in-out;
                }

                .amb-comp {
                    line-height: 90%;
                }
            }

            .slide-left {
                transition: all 0.3s ease-in-out;
                // transform: translateX(10px);
                margin-left: 30px;
            }
        }
    }

    .slide-up-enter-active,
    .slide-up-leave-active {
        transition: opacity, transform 0.3s ease-out;
        position: fixed;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
    }

    .slide-up-enter-from {
        opacity: 1;
        transform: translateY(30px);
    }

    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }
}</style>