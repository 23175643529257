<template>
    <div class="nova case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Luna
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Luna
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Interaction Design
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2022
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        An AI companion that actively aids your self-discovery and personal growth using small nudges and
                        natural language processing.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                Traditional journaling, often a solo monologue, may lack depth. Your introspection relies
                                entirely on your own guidance and understanding. With an AI aided approach, journaling
                                transforms into an immersive dialogue. Utilising advanced language processing, it
                                comprehends and intelligently responds to your thoughts making your journey of
                                self-discovery interactive and thoughtful. This method ensures deeper introspection, making
                                journaling a more profound and accessible experience.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    a personal <span class="color-text">growth companion for introspective individuals, transforming
                        monologue into guided dialogue,</span>
                    aiding deeper self-reflection and personal growth.
                </div>
                <div class="overview-description desktop">
                    <div>
                        a personal <span class="color-text">growth companion</span>
                    </div>
                    <div class="color-text">
                        for introspective individuals,
                    </div>
                    <div class="color-text">
                        transforming monologue into
                    </div>
                    <div>
                        <span class="color-text">guided dialogue,</span> aiding deeper
                    </div>
                    <div>
                        self-reflection and personal
                    </div>
                    <div>
                        growth.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-0 padded-container parallax-in">
            <div class="image-container">
                <img class="image0" src="../../assets/design/nova/image0.png" alt="BOOK">
                <div class="image-caption">
                    <div>
                        BOOK:
                    </div>
                    <div>
                        The concepts in this project were inspired from this book by Steven Pinker
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 padded-container parallax-in">
            <div class="image-container">
                <img class="image1" src="../../assets/design/nova/image1.png" alt="Fig 1: DIALOGUE">
                <div class="image-caption">
                    <div>
                        Fig 1: DIALOGUE
                    </div>
                    <div>
                        Actively participating in a dialogue can lead to better comprehension and retention of information,
                        introducing a fresh perspective, allowing for better reflection
                    </div>
                </div>
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="title">
                This AI companion learns your patterns and moods over time. Unlike regular journaling, it guides you and
                helps clarify your emotions. It offers steady support, leading to deeper introspection, even on days
                when you struggle to articulate your thoughts.
            </div>
        </div>
        <div class="section-3 padded-container parallax-in">
            <div class="image-container">
                <img class="image2" src="../../assets/design/nova/image2.png" alt="Fig 2: HOMESCREEN">
                <div class="image-caption">
                    <div>
                        Fig 2: HOMESCREEN
                    </div>
                    <div>
                        Home-screen holds your journal memories and gives context-aware prompts for journaling, offering
                        different starting points based on your day's experiences.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-4 padded-container parallax-in">
            <div class="image-container">
                <img class="image3" src="../../assets/design/nova/image3.png" alt="Fig 3: MEMORIES">
                <div class="image-caption">
                    <div>
                        Fig 3: MEMORIES
                    </div>
                    <div>
                        Your journal chat becomes a "memory", designed like a scrapbook page, capturing the feel of
                        traditional diary paper.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image4" src="../../assets/design/nova/image4.png" alt="Fig 4">
                    </div>
                    <div class="image-container">
                        <img class="image5" src="../../assets/design/nova/image5.png" alt="Fig 5">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 4-5:
                        </div>
                        <div>
                            People & Prompts
                        </div>
                    </div>
                    <div class="content-section">
                        <div @click=" showInfo1 = !showInfo1;" class="readmore relative">
                            <span v-if="showInfo1">close</span><span v-else>read more</span><img
                                :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg"
                                alt="add">
                        </div>
                        <Transition name="slide-down">
                            <div v-show="showInfo1" class="additional-info two-content-section-2">
                                <div class="para-spacer sub-text">
                                    Recognizing your social network, the app enriches your entries and draws from past
                                    chats to provide a layered, immersive experience. Prompts offer varied introspection
                                    starting points, while dialogues dig deeper. Together, they break down feelings and
                                    perceptions effectively
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 parallax-in">
            <div class="image-container">
                <img class="image6" src="../../assets/design/nova/image6.png" alt="hello">
            </div>
        </div>
        <div class="section-7 padded-container parallax-in">
            <div class="title">
                Experience screen-free journaling with a voice-interactive robot, mirroring the app's key features. It
                guides reflections, suggests prompts, and syncs sessions to your phone seamlessly.
            </div>
        </div>
        <div class="section-8 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image7" src="../../assets/design/nova/image7.png" alt="Fig 6">
                    </div>
                    <div class="image-container">
                        <img class="image8" src="../../assets/design/nova/image8.png" alt="Fig 7">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 6-7: HARDWARE GUI
                        </div>
                        <div class="mobile">
                            The device intuitively engages people with motion, while a context aware visual adds depth to voice dialogues.
                        </div>
                        <div class="desktop">
                            <div>
                                The device intuitively engages people with
                            </div>
                            <div>
                                motion, while a context aware visual adds
                            </div>
                            <div>
                                depth to voice dialogues.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="six-imgs mobile">
                <img class="image9" src="../../assets/design/nova/image9.png" alt="grid images">
                <img class="image10" src="../../assets/design/nova/image10.png" alt="grid images">
                <img class="image11" src="../../assets/design/nova/image11.png" alt="grid images">
                <img class="image12" src="../../assets/design/nova/image12.png" alt="grid images">
                <img class="image13" src="../../assets/design/nova/image13.png" alt="grid images">
                <img class="image14" src="../../assets/design/nova/image14.png" alt="grid images">
            </div>
            <div class="image-container desktop">
                <img class="image15" src="../../assets/design/nova/image15.png" alt="grid images">
            </div>
            <div class="image-container desktop">
                <img class="image16" src="../../assets/design/nova/image16.png" alt="grid images">
            </div>
        </div>
        <div class="section-10 padded-container parallax-in">
            <div class="image-container">
                <img class="image17" src="../../assets/design/nova/image17.png" alt="Fig 8: TEXT MESSAGE ACCESS">
                <div class="image-caption">
                    <div>
                        Fig 8: TEXT MESSAGE ACCESS
                    </div>
                    <div>
                        Experience the service seamlessly within your familiar text messaging environment, preserving your
                        existing communication habits.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-11 padded-container parallax-in">
            <div class="image-container">
                <img class="image18" src="../../assets/design/nova/image18.png" alt="Fig 9: TEXT MESSAGE PROMPTING">
                <div class="image-caption">
                    <div>
                        Fig 9: TEXT MESSAGE PROMPTING
                    </div>
                    <div>
                        Seamlessly integrated with iOS UI, the service provides a rich prompting experience, even without
                        using the dedicated app.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-12 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image19" src="../../assets/design/nova/image19.png" alt="end image">
                    </div>
                    <div class="image-container">
                        <img class="image20" src="../../assets/design/nova/image20.png" alt="end image">
                    </div>
                </div>
            </div>
        </div>
        <div class="common-footer padded-container parallax-in">
            <div class="footer-nav d-flex">
                <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                    design
                </div>
                <div class="separator">
                    <img src="@/assets/separator.png" alt="separator">
                </div>
                <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                    folk
                </div>
                <div class="separator">
                    <img src="@/assets/separator.png" alt="separator">
                </div>
                <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                    lore
                </div>
            </div>
            <div class="footer-info d-flex">
                <div>
                    Copyright <img class="copyright" src="@/assets/copyright.png" alt="copyright"> 2023 BGRY.
                </div>
                <div>
                    Made in Andhra Pradesh
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'Luna',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            slideImages1: [
                {
                    src: 'slide1',
                    text: 'Customer archetype research and decision driver summary '
                },
                {
                    src: 'slide2',
                    text: 'Brand research and deep dive, Research 2022.'
                },
                {
                    src: 'slide3',
                    text: 'Brand design principles that inform the UI and interaction and design '
                }
            ],
            slideImages2: [
                {
                    src: 'slide4',
                    text: 'Direction 1'
                },
                {
                    src: 'slide5',
                    text: 'Direction 2'
                },
                {
                    src: 'slide6',
                    text: 'Direction 3'
                },
                {
                    src: 'slide7',
                    text: 'Direction 4'
                },
            ],
            slideImages3: [
                {
                    src: 'slide8',
                    text: 'Homepage Direction 1'
                },
                {
                    src: 'slide9',
                    text: 'Homepage Direction 2'
                },
                {
                    src: 'slide10',
                    text: 'Homepage Direction 3'
                },
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide1: 0,
            currentSlide2: 0,
            currentSlide3: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },
        moveSlide2() {
            this.currentSlide2++;
        },
        moveSlide3() {
            this.currentSlide3++;
        },

        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.nova {
    .landing-section-mobile {
        background: url('~@/assets/design/nova/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/nova/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #C8F34C;
    }

    .section-0 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 30%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image0 {
            width: 100%;
        }
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image1 {
            width: 100%;
        }
    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .section-3 {
        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image2 {
            width: 100%;
        }
    }

    .section-4 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image3 {
            width: 100%;
        }
    }

    .section-5 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image4,
        .image5 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image6 {
            width: 100%;
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image7,
        .image8 {
            width: 100%;
        }
    }

    .section-9 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image15 {
            width: 100%;
        }

        .image16 {
            width: 100%;
            margin-top: 40px;
        }
    }

    .section-10 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image17 {
            width: 100%;
        }
    }

    .section-11 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 28%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image18 {
            width: 100%;
        }
    }

    .section-12 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        .image19,
        .image20 {
            width: 100%;
        }
    }
}
</style>