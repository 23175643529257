<template tag="span">
	<div @mouseover="hoverEvent = true" @mouseleave="hoverEvent = false" class="hover-link relative">
        {{text}}<Transition name="fade"><img class="hover-interaction absolute" v-if="typeText == 'Interaction' && hoverEvent" src="@/assets/Intercation.png" :alt="typeText">
		<img class="hover-industrial absolute" v-else-if="typeText == 'Industrial' && hoverEvent" src="@/assets/Industrial.png" :alt="typeText">
		<img class="absolute hover-telugu" v-else-if="typeText == 'Telugu' && hoverEvent" src="@/assets/telugu.png" :alt="typeText"></Transition>
	</div>
</template>

<script>
export default {
	name: 'HoverLink',
    props: {
		text: String,
		imgSrc: String,
		typeText: String
	},
	components: {},
	data: function () {
		return {
			hoverEvent: false,
		}
	},
}
</script>
<style lang=less>
@import "@/styles/colors.less";
.hover-link {
    display: inline;
	text-decoration: underline;
	cursor: pointer;
}
.hover-interaction, .hover-industrial {
	bottom: 90%;
	left: 0;
}
.hover-telugu {
	right: 15%;
	top: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
  