<template>
    <div class="neva case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Neva
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Neva
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Research & Strategy
                            </div>
                            <div>
                                Interaction Design
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2020 - 2021
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        What if there was a system to passively predict your likelihood of getting a chronic illness ?
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                Chronic diseases are easily preventable, and 80% of chronic conditions can be avoided by
                                adopting healthy lifestyle recommendations. Research shows, “Eighty percent of the
                                population wants to live in a better state of health but do not know how to pursue it. This
                                is due to minimal efforts put towards prevention strategies and research. As of 2020 18.5
                                million (age45-65) are in the risk of getting one or more chronic illness. How can we help
                                these people by predicting and preventing chronic illness before their onset.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    <span class="color-text">NON INVASIVE lifestyle medical DEVICES</span> that monitor 106 biomarkers
                    condition in your body to <span class="color-text">help you
                        understand your risk of developing a chronic illness.</span>
                </div>
                <div class="overview-description desktop">
                    <div class="color-text">
                        NON INVASIVE lifestyle medical
                    </div>
                    <div>
                        <span class="color-text">DEVICES</span> that monitor 106
                    </div>
                    <div>
                        biomarkers condition in your
                    </div>
                    <div>
                        body to <span class="color-text">help you understand</span>
                    </div>
                    <div class="color-text">
                        your risk of developing
                    </div>
                    <div class="color-text">
                        a chronic illness.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image1" src="../../assets/design/neva/image1.png"
                            alt="(Sarasohn-Kahn, 2019) & (Miller et al., 2019)">
                        <div class="sub-text">
                            (Sarasohn-Kahn, 2019) & (Miller et al., 2019)
                        </div>
                    </div>
                    <div class="image-container">
                        <img class="image2" src="../../assets/design/neva/image2.png"
                            alt="Data from CDC, WHO 2019 data sets & (Bodai et al., 2017).">
                        <div class="sub-text">
                            Data from CDC, WHO 2019 data sets & (Bodai et al., 2017).
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="title">
                NEVA is a longitudinal health monitoring system that assess both biological and environmental determinants
                of health and their interactions to predict, prevent and personalise healthcare.
            </div>
            <div class="sub-title title">
                Materials: PCR resins, Silicone
            </div>
            <div class="image-container">
                <img class="image3" src="../../assets/design/neva/image3.png" alt="product image">
            </div>
        </div>
        <div class="section-3 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image4" src="../../assets/design/neva/image4.png" alt="Figure 1">
                    </div>
                    <div class="image-container">
                        <img class="image5" src="../../assets/design/neva/image5.png" alt="Figure 2">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 1-2:
                        </div>
                        <div class="mobile">
                            Saliva Analyser The saliva analyser uses a swab test interaction and includes a slot with UV
                            light walls for cleaning the reusable swab.
                        </div>
                        <div class="desktop">
                            <div>
                                Saliva Analyser The saliva analyser uses a swab test
                            </div>
                            <div>
                                interaction and includes a slot with UV
                            </div>
                            <div>
                                light walls for cleaning the reusable swab.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-4 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image6" src="../../assets/design/neva/image6.png" alt="Figure 3">
                    </div>
                    <div class="image-container">
                        <img class="image7" src="../../assets/design/neva/image7.png" alt="Figure 4">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 3-4: Facial scanner
                        </div>
                        <div class="mobile">
                            The facial scanner's design resembles a bathroom mirror and doorbell camera, creating a
                            welcoming & unobtrusive experience.
                        </div>
                        <div class="desktop">
                            <div>
                                The facial scanner's design resembles a
                            </div>
                            <div>
                                bathroom mirror and doorbell camera, creating
                            </div>
                            <div>
                                a welcoming & unobtrusive experience.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image8" src="../../assets/design/neva/image8.png" alt="Figure 5">
                    </div>
                    <div class="image-container">
                        <img class="image9" src="../../assets/design/neva/image9.png" alt="Figure 6">
                    </div>
                </div>
                <div class="caption-section">
                    <div class="image-caption">
                        <div>
                            Fig 5-6: Urine and Stool Analyzer
                        </div>
                        <div class="mobile">
                            The analyzer design complements the landscape and aims to be discreet and non-invasive for user
                            comfort during their morning routine.
                        </div>
                        <div class="desktop">
                            <div>
                                The analyzer design complements the landscape and
                            </div>
                            <div>
                                aims to be discreet and non-invasive for user
                            </div>
                            <div>
                                comfort during their morning routine.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 padded-container parallax-in">
            <div class="title">
                Using the devices doesn't need any extra effort , all the device touchpoints are paired with your daily
                routine to make it a seamless part of you day. All the devices use non invasive methods to extract data.
                This helps remove the "surgical" or "medical" factor from the user experience.
            </div>
        </div>
        <div class="section-7 padded-container parallax-in">
            <div class="image-container">
                <img class="image10" src="../../assets/design/neva/image10.png" alt="Fig 7: USER JOURNEY">
                <div class="image-caption">
                    <div>
                        Fig 7: USER JOURNEY
                    </div>
                    <div class="mobile">
                        Passive tracking enables the user to avoid manual data entry and constant engagement with the app
                        and
                        product to get accurate results
                    </div>
                    <div class="desktop">
                        <div>
                            Passive tracking enables the user to avoid
                        </div>
                        <div>
                            manual data entry and constant engagement with
                        </div>
                        <div>
                            the app and product to get accurate results
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-8 padded-container parallax-in">
            <div class="image-container">
                <img class="image11" src="../../assets/design/neva/image11.png" alt="Fig 8: Urine and Stool Analyzer">
                <div class="image-caption">
                    <div>
                        Fig 8: URINE AND STOOL ANALYZER
                    </div>
                    <div class="mobile">
                        Saliva, Urine and Feces are complex biological by-products that are directly affected by our
                        lifestyle. Monitoring these biomarkers along with behaviour patterns like sleep, diet, and stress,
                        can reveal vital insights
                    </div>
                    <div class="desktop">
                        <div>
                            Saliva, Urine and Feces are complex biological
                        </div>
                        <div>
                            by-products that are directly affected by our
                        </div>
                        <div>
                            lifestyle. Monitoring these biomarkers along
                        </div>
                        <div>
                            with behaviour patterns like sleep, diet, and
                        </div>
                        <div>
                            stress, can reveal vital insights
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-9 padded-container parallax-in">
            <div class="image-container">
                <img class="image12" src="../../assets/design/neva/image12.png" alt="Fig 9: CONCEPT DEVELOPMENT">
                <div class="image-caption">
                    <div>
                        Fig 9: CONCEPT DEVELOPMENT
                    </div>
                    <div class="mobile">
                        The user interactions with the devices are boiled down to the essential interactions which enables a
                        hassle free user experience for the user, especially the non-tech savvy ones.
                    </div>
                    <div class="desktop">
                        <div>
                            The user interactions with the devices are
                        </div>
                        <div>
                            boiled down to the essential interactions which
                        </div>
                        <div>
                            enables a hassle free user experience for the
                        </div>
                        <div>
                            user, especially the non-tech savvy ones.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-10 parallax-in">
            <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide1">
                <Slide @click="moveSlide1" v-for="  (      slide, index      )   in         slideImages1        "
                    :key="index">
                    <img class="archive-images" :src="require(`../../assets/design/neva/${slide.src}.png`)"
                        :alt="`slide_${index}`">
                </Slide>
            </Carousel>
        </div>
        <div class="section-11 padded-container parallax-in">
            <div class="image-container">
                <img class="image13" src="../../assets/design/neva/image13.png" alt="product image">
            </div>
            <div class="two-image-container">
                <div class="image-section">
                    <div class="image-container">
                        <img class="image14" src="../../assets/design/neva/image14.png" alt="product image">
                    </div>
                    <div class="image-container">
                        <img class="image15" src="../../assets/design/neva/image15.png" alt="product image">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-12 padded-container parallax-in">
            <div class="image-container">
                <img class="image16" src="../../assets/design/neva/image16.png" alt="Fig 13">
            </div>
            <div class="image-caption">
                <div>
                    Fig 13:
                </div>
                <div>
                    MULTI-CAM SWITCHING: You can check device specific in-depth bio-marker conditions under the devices tabs
                </div>
            </div>
        </div>
        <div class="section-13 padded-container parallax-in">
            <div class="image-container">
                <img class="image17" src="../../assets/design/neva/image17.png" alt="Fig 14: HEALTH COACH">
            </div>
            <div class="image-caption">
                <div>
                    Fig 14: HEALTH COACH
                </div>
                <div class="mobile">
                    AI identifies your health risks. Certified health coaches pinpoint hurdles, then craft personalised
                    diet, exercise, and lifestyle plans to preempt risks
                </div>
                <div class="desktop">
                    <div>
                        AI identifies your health risks. Certified health
                    </div>
                    <div>
                        coaches pinpoint hurdles, then craft personalised
                    </div>
                    <div>
                        diet, exercise, and lifestyle plans to preempt risks
                    </div>
                </div>
            </div>
        </div>
        <div class="section-14 padded-container parallax-in">
            <div class="image-container">
                <img class="image18" src="../../assets/design/neva/image18.png" alt="Fig 15: Data comprehension">
            </div>
            <div class="image-caption">
                <div>
                    Fig 15: DATA COMPREHENSION
                </div>
                <div>
                    app dashboard presents complex health data simply. Effortlessly grasp key insights from multiple data
                    points via circular visuals.
                </div>
            </div>
        </div>
        <div class="section-15 padded-container parallax-in">
            <div class="image-container">
                <img class="image19" src="../../assets/design/neva/image19.png" alt="Fig 16: RED FLAGS">
            </div>
            <div class="image-caption">
                <div>
                    Fig 16: RED FLAGS
                </div>
                <div>
                    When NEVA spots worrisome patterns, you're promptly notified with actionable recommendations, paired
                    with instant access to a health coach
                </div>
            </div>
        </div>
        <div class="section-16 parallax-in">
            <div class="image-container">
                <img class="image20" src="../../assets/design/neva/image20.png" alt="product image">
            </div>
        </div>
        <div class="archive-section">
            <div class="archive-title-section">
                <div class="archive-title">
                    Archive
                </div>
            </div>
            <div class="archive-text padded-container">
                <div>
                    If we can successfully collect our daily routine data (work hours, stress levels, nutrition, physical
                    activity), health data (biochemistry of feces, urine, saliva, skin, and cardiovascular activity) and
                    monitor it for irregularities or bad clinical signals; we will be able to map out the correlation
                    between our state of health and lifestyle
                </div>
            </div>
            <div class="archive-section-1 padded-container parallax-in">
                <div class="image-container">
                    <img class="image21" src="../../assets/design/neva/image21.png" alt="Fig 1">
                </div>
                <div class="archive-caption">
                    <div>
                        Fig 1:
                    </div>
                    <div class="mobile">
                        The design phases explored product concepts to augment how peoples screening behaviour should be.
                        Each concept explored touch points available in the users daily life and household (kitchen,
                        bathroom & bedroom) that help collect novel data and patterns.
                    </div>
                    <div class="desktop">
                        <div>
                            The design phases explored product concepts to augment how peoples
                        </div>
                        <div>
                            screening behaviour should be. Each concept explored touch points
                        </div>
                        <div>
                            available in the users daily life and household (kitchen, bathroom
                        </div>
                        <div>
                            & bedroom) that help collect novel data and patterns.
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-section-2 padded-container parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image22" src="../../assets/design/neva/image22.png" alt="Figure 2">
                        </div>
                        <div class="image-container">
                            <img class="image23" src="../../assets/design/neva/image23.png" alt="Figure 2">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="archive-caption">
                            <div>
                                Fig 2:
                            </div>
                            <div class="mobile">
                                <div>
                                    Design Principle 1: As the products includes a stool analyzer that goes on your toilet,
                                    the
                                    users want the product to be discreet and not cause discomfort during their morning
                                    routine.
                                    Hence " Design to be discreet"
                                </div>
                                <div class="m-4-top">
                                    Design principle 2 ; As most of the user using the product
                                    are between the age of 30-60, I wanted to make the technology as seamless as possible.
                                    The
                                    on-boarding and navigation process was crucial , hence "Design to be easy to install
                                </div>
                            </div>
                            <div class="desktop">
                                <div>
                                    <div>
                                        Design Principle 1: As the products includes a stool analyzer that goes
                                    </div>
                                    <div>
                                        on your toilet, the users want the product to be discreet and not cause
                                    </div>
                                    <div>
                                        discomfort during their morning routine. Hence " Design to be discreet"
                                    </div>
                                </div>
                                <div class="m-4-top">
                                    <div>
                                        Design principle 2 ; As most of the user using the product are between
                                    </div>
                                    <div>
                                        the age of 30-60, I wanted to make the technology as seamless as
                                    </div>
                                    <div>
                                        possible. The on-boarding and navigation process was crucial , hence
                                    </div>
                                    <div>
                                        "Design to be easy to install"
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-section-3 padded-container parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image24" src="../../assets/design/neva/image24.png" alt="Figure 3">
                        </div>
                        <div class="image-container">
                            <img class="image25" src="../../assets/design/neva/image25.png" alt="Figure 3">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="archive-caption">
                            <div>
                                Fig 3:
                            </div>
                            <div>
                                Urine & Stool analyser product testing
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-section-4 padded-container parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image26" src="../../assets/design/neva/image26.png" alt="Figure 4">
                        </div>
                        <div class="image-container">
                            <img class="image27" src="../../assets/design/neva/image27.png" alt="Figure 4">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="archive-caption">
                            <div>
                                Fig 4:
                            </div>
                            <div>
                                Facial Scanner & Saliva analyser product testing
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-section-5 padded-container parallax-in">
                <div class="image-container">
                    <img class="image28" src="../../assets/design/neva/image28.png" alt="Figure 5">
                </div>
                <div class="archive-caption">
                    <div>
                        Fig 5:
                    </div>
                    <div>
                        Prototypes variations
                    </div>
                </div>
            </div>
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'Neva',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            slideImages1: [
                {
                    src: 'slide1',
                    text: ''
                },
                {
                    src: 'slide2',
                    text: ''
                },
                {
                    src: 'slide3',
                    text: ''
                },
                {
                    src: 'slide4',
                    text: ''
                },
                {
                    src: 'slide5',
                    text: ''
                },
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide1: 0,
            currentSlide2: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },
        moveSlide2() {
            this.currentSlide2++;
        },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.neva {
    .landing-section-mobile {
        background: url('~@/assets/design/neva/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/neva/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #64C181;
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image1 {
            width: 100%;
        }

        .image2 {
            width: 100%;
        }

        .image-section {
            gap: 20px;

            @media only screen and (min-width: 992px) {
                gap: 0;
                justify-content: space-between;
            }
        }

        .image-container {
            margin-top: 20px;

            @media only screen and (min-width: 992px) {
                width: 42%;
                margin-top: 0;
            }
        }

        .sub-text {
            color: #A7A49F;
        }
    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image3 {
            width: 100%;
        }
    }

    .section-3 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image4,
        .image5 {
            width: 100%;
        }
    }

    .section-4 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image6,
        .image7 {
            width: 100%;
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image8,
        .image9 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image10 {
            width: 100%;
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image11 {
            width: 100%;
        }

        .image-caption {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .section-9 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        .image12 {
            width: 100%;
        }
    }

    .section-10 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .archive-images {
            width: 96%;
            object-fit: contain;
        }
    }

    .section-11 {

        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        img {
            width: 100%;
        }

        .two-image-container {
            margin-top: 20px;

            @media only screen and (min-width: 992px) {
                margin-top: 25px;
            }
        }
    }

    .section-12 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image16 {
            width: 100%;
        }
    }

    .section-13 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        img {
            width: 100%;
        }
    }

    .section-14 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        img {
            width: 100%;
        }
    }

    .section-15 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        img {
            width: 100%;
        }
    }

    .section-16 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }

        img {
            width: 100%;
        }
    }

    .archive-section {
        margin-top: -5px;

        .archive-section-1 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }

            .image21 {
                width: 100%;
            }
        }

        .archive-section-2 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }

            .image22,
            .image23 {
                width: 100%;
            }
        }

        .archive-section-3 {
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;
            margin-top: 90px;

            @media only screen and (min-width: 992px) {
                margin-top: 96px;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 124px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 146px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 160px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 198px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 371px;
            }

            .image24,
            .image25 {
                width: 100%;
            }
        }

        .archive-section-4 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }

            .image26,
            .image27 {
                width: 100%;
            }
        }

        .archive-section-5 {
            margin-top: 90px;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin-top: 96px;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 124px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 146px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 160px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 198px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 371px;
            }

            .image28 {
                width: 100%;
            }
        }
    }
}</style>