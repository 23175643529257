<template>
  <div class="all-cursor">
    <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
    <div>
      <Header></Header>
    </div>
    <!-- <router-view v-slot="{ Component, route }">
      <transition name="slide-up" mode="out-in">
        <div :key="route.name">
          <Header></Header>
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view> -->
    <router-view />
  </div>
</template>

<script lang="js">
// @ is an alias to /src
import Header from '@/components/Header/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  created() {
    console.log(window.navigator.userAgent);
  }
}
</script>

<style lang="less">
@import "styles/utils.less";

body {
  font-family: "Suisse International";
  font-weight: 400;
  font-style: normal;
}


// working
.slide-up-enter-active {
  // transition: all 0.7s ease-out;
  transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
  // position: absolute; /* position the leaving content at the top of the container */
  // top: 0;
  // left: 0;
  // right: 0;
}

// .slide-up-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
//   // position: absolute; /* position the leaving content at the top of the container */
//   // top: 0;
//   // left: 0;
//   // right: 0;
// }

// .slide-up-enter-from {
//   transform: translateY(100%);
//   opacity: 1;
// }

// .slide-up-leave-to {
//   transform: translateY(-100%);
//   // z-index: 2000;
//   opacity: 1;
// }

// .slide-up-enter-active,
// .slide-up-leave-active {
//   transition: all 0.5s;
// }

// .slide-up-enter,
// .slide-up-leave-to {
//   transform: translateY(100%);
//   background-color: #000; /* add a solid background color */
//   z-index: 10000; /* make sure the solid color is on top of the previous content */
// }

// .slide-up-leave-active {
//   position: absolute; /* position the leaving content at the top of the container */
//   top: 0;
//   left: 0;
//   right: 0;
// }

// .slide-up-enter-active {
//   position: absolute; /* position the entering content at the bottom of the container */
//   top: 100%;
//   left: 0;
//   right: 0;
// }
</style>
