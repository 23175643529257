<template>
    <div class="google-ar case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Google
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Google
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) Brief
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Industrial Design
                            </div>
                            <div>
                                Research
                            </div>
                            <div>
                                Visualisation
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2021
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Exploring Google’s AR hardware designing around its existing resources and proprietary technology.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                This project explores Google XR Glasses and how Google's resources are uniquely positioned
                                to enhance mixed reality experiences for consumers. The concept aims to experiment with
                                possible product architecture and use case scenarios while aligning with Google's visual
                                brand language. The final result is a pair of smart glasses with a contemporary form
                                language that blends googles design language with powerful tech-designed to seamlessly
                                integrate and respond to the environment around you and enhance your day-to-day tasks.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Overview
                </div>
                <div class="overview-description mobile">
                    <div>
                        FASHION FORWARD
                    </div>
                    <div class="color-text">
                        AR HARDWARE THAT
                    </div>
                    <div class="color-text">
                        PEOPLE CAN wear
                    </div>
                    <div class="color-text">
                        WITH <span class="color-text">EVERYDAY</span>
                    </div>
                    <div>
                        <span class="color-text">CLOTHING,</span> a
                    </div>
                    <div>
                        stylish SILHOUETTE
                    </div>
                    <div>
                        with ambient computing
                    </div>
                    <div>
                        capabilities.
                    </div>
                </div>
                <div class="overview-description desktop">
                    <div>
                        FASHION FORWARD <span class="color-text">AR HARDWARE</span>
                    </div>
                    <div class="color-text">
                        THAT PEOPLE CAN wear WITH
                    </div>
                    <div>
                        <span class="color-text">EVERYDAY CLOTHING,</span> a stylish
                    </div>
                    <div>
                        SILHOUETTE with ambient
                    </div>
                    <div>
                        computing capabilities.
                    </div>
                </div>
            </div>
            <div class="section-1 parallax-in">
                <div class="image-container">
                    <img class="image1" src="../../assets/design/google-ar/image1.png" alt="image 1">
                </div>
            </div>
            <div class="section-2 parallax-in">
                <div class="resp-flex">
                    <div class="label">
                        (03) Research
                    </div>
                    <div class="sub-text desktop">
                        <div>
                            Over the years google has acquired and developed cutting edge
                        </div>
                        <div>
                            technologies that can enable state of the art augmented reality
                        </div>
                        <div>
                            experience. Blending these proprietary technologies can enable
                        </div>
                        <div>
                            novel AR interactions that are powered by a lightweight hardware.
                        </div>
                    </div>
                </div>

                <div class="sub-text mobile mt-40">
                    Over the years google has acquired and developed cutting edge technologies that can enable state of the
                    art augmented reality experience. Blending these proprietary technologies can enable novel AR
                    interactions that are powered by a lightweight hardware.
                </div>
            </div>
            <div class="section-3 parallax-in">
                <div class="title">
                    The industrial design language reflects Google's brand personality, infusing a sense of fashion and
                    approachability into the powerful AR hardware.
                </div>
                <div class="sub-title title">
                    Materials: Cellulose Acetate, Grilamid (high-quality thermoplastic)
                </div>
            </div>
            <div class="section-4 parallax-in">
                <div class="image-container">
                    <img class="image2" src="../../assets/design/google-ar/image2.png" alt="Figure1">
                    <div class="image-caption">
                        <div>
                            Fig 1:
                        </div>
                        <div class="mobile">
                            The glass design doesn't deviate much from a traditional spectacle design to bring in
                            familiarity to otherwise a cutting edge tech
                        </div>
                        <div class="desktop">
                            <div>
                                The glass design doesn't deviate
                            </div>
                            <div>
                                much from a traditional spectacle
                            </div>
                            <div>
                                design to bring in familiarity to
                            </div>
                            <div>
                                otherwise a cutting edge tech
                            </div>
                        </div>
                    </div>
                </div>
                <div class="image-container">
                    <img class="image3" src="../../assets/design/google-ar/image3.png" alt="Figure2">
                    <div class="image-caption">
                        <div>
                            Fig 2:
                        </div>
                        <div class="mobile">
                            Temperature, battery, & weight consideration played crucial roles in ensuring a comfortable and
                            functional device.
                        </div>
                        <div class="desktop">
                            <div>
                                Temperature, battery, & weight
                            </div>
                            <div>
                                consideration played crucial roles
                            </div>
                            <div>
                                in ensuring a comfortable and
                            </div>
                            <div>
                                functional device.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-5 parallax-in">
            <div class="full-image">
                <img class="image4" src="../../assets/design/google-ar/image4.png" alt="Figure 3">
            </div>
            <div class="padded-container">
                <div class="resp-flex">
                    <div class="image-caption margintop-150">
                        <div>
                            Fig 3:
                        </div>
                        <div>
                            Form & Fit
                        </div>
                    </div>
                    <div class="title desktop margintop-150">
                        <div>
                            The frame design takes into account
                        </div>
                        <div>
                            diverse facial features resulting in a
                        </div>
                        <div>
                            flexible form that complements
                        </div>
                        <div>
                            various face types.
                        </div>
                    </div>
                </div>

                <div class="title mobile mt-40">
                    The frame design takes into account diverse facial features and archetypes, resulting in a flexible form
                    that complements various face types.
                </div>
            </div>
        </div>
        <div class="padded-container">
            <div class="section-6 parallax-in">
                <div class="image-container">
                    <img class="image5" src="../../assets/design/google-ar/image5.png" alt="Figure1">
                    <div class="image-caption">
                        <div>
                            Fig 4:
                        </div>
                        <div class="mobile">
                            Soli’s chip in the glass camera unit uses miniature radar to detect large range of movement, and
                            translate them to a set of interaction between the glasses UI and the user.
                        </div>
                        <div class="desktop">
                            <div>
                                Soli’s chip in the glass camera unit uses
                            </div>
                            <div>
                                miniature radar to detect large range of
                            </div>
                            <div>
                                movement, and translate them to a set of
                            </div>
                            <div>
                                interaction between the glasses UI and the user.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-7 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image6" src="../../assets/design/google-ar/image6.png" alt="figure 5">
                        </div>
                        <div class="image-container">
                            <img class="image7" src="../../assets/design/google-ar/image7.png" alt="figure 6">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="image-caption">
                            <div>
                                <div>
                                    Fig 5-6:
                                </div>
                                <div>
                                    (L) Sensors
                                </div>
                                <div>
                                    (R) Heads Up Display
                                </div>
                            </div>
                        </div>
                        <div class="content-section">
                            <div @click=" showInfo2 = !showInfo2;" class="readmore relative">
                                <span v-if="showInfo2">close</span><span v-else>read more</span><img
                                    :class="{ openInfo: showInfo2 }" class="add-sign absolute" src="../../assets/add.svg"
                                    alt="add">
                            </div>
                            <Transition name="slide-down">
                                <div v-show="showInfo2" class="additional-info two-content-section">
                                    <div class="para-spacer sub-text">
                                        Sensors: The device is equipped with tracking sensors, Lidar, cameras which allow AR
                                        Core software to capture the environment and overlay AR content on the UI.

                                        <div class="m-4-top sub-text">
                                            Heads up display: to access all your platforms, this device can serve all the
                                            current day to day function but also is geared up to create mixed reality
                                            content.
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-8 parallax-in">
                <div class="two-image-container">
                    <div class="image-section">
                        <div class="image-container">
                            <img class="image8" src="../../assets/design/google-ar/image8.png" alt="figure 7">
                        </div>
                        <div class="image-container">
                            <img class="image9" src="../../assets/design/google-ar/image9.png" alt="figure 8">
                        </div>
                    </div>
                    <div class="caption-section">
                        <div class="image-caption">
                            <div>
                                <div>
                                    Fig 7-8:
                                </div>
                                <div>
                                    (L) Daily navigation
                                </div>
                                <div>
                                    (R) Video call capabilities
                                </div>
                            </div>
                        </div>
                        <div class="content-section">
                            <div @click=" showInfo3 = !showInfo3;" class="readmore relative">
                                <span v-if="showInfo3">close</span><span v-else>read more</span><img
                                    :class="{ openInfo: showInfo3 }" class="add-sign absolute" src="../../assets/add.svg"
                                    alt="add">
                            </div>
                            <Transition name="slide-down">
                                <div v-show="showInfo3" class="additional-info two-content-section">
                                    <div class="para-spacer sub-text">
                                        Instead of relying on individual computer processing power, all the required
                                        computing for the glasses is done on the Google cloud network. This process allows
                                        for streaming content off the Google servers with near-zero latency irrespective of
                                        your location and environment.
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-9 parallax-in">
                <div class="title">
                    Combining gentle motions, smooth contours, and understated outlines established a cohesive design
                    language, resulting in an adaptable and approachable product that feels familiar, embraces simplicity,
                    and reduces core elements to their essence.
                </div>
            </div>
            <div class="image-container">
                <img class="image10 parallax-in" src="../../assets/design/google-ar/image10.png" alt="figure 9">
            </div>
            <div class="image-container">
                <img class="image11 parallax-in" src="../../assets/design/google-ar/image11.png" alt="figure 10">
            </div>
        </div>
        <div class="image-container">
            <img class="image12 parallax-in" src="../../assets/design/google-ar/image12.png" alt="figure 11">
        </div>
        <div class="archive-section">
            <div class="archive-title-section">
                <div class="archive-title">
                    Archive
                </div>
            </div>
            <div class="archive-text padded-container">
                <div class="mobile">
                    The research process covered; market research, defining google VBL, and existing products architecture.
                    The research also considered many different ways of approaching Google XR capabilities.
                </div>
                <div class="desktop">
                    <div>
                        The research process covered; market research, defining google
                    </div>
                    <div>
                        VBL, and existing products architecture. The research also
                    </div>
                    <div>
                        considered many different ways of approaching Google XR
                    </div>
                    <div>
                        capabilities.
                    </div>
                </div>
            </div>
            <div class="image13-container padded-container parallax-in">
                <img class="image13" src="../../assets/design/google-ar/image13.png" alt="archive figure 1">
                <div class="archive-caption">
                    <div>
                        Fig 1:
                    </div>
                    <div>
                        Research deck
                    </div>
                </div>
            </div>
            <div class="archive-sub-2 carousel-section parallax-in">
                <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide1">
                    <Slide @click="moveSlide1" v-for="  (      slide, index      )   in         slideImages1        "
                        :key="index">
                        <img class="archive-images" :src="require(`../../assets/design/google-ar/${slide.src}.png`)"
                            :alt="`slide_${index}`">
                    </Slide>
                </Carousel>
                <div class="carousel-caption padded-container">
                    <div v-if="currentSlide1 < 3" class="archive-caption">
                        {{ slideImages1[currentSlide1].text }}
                    </div>
                    <div v-else class="archive-caption">
                        {{ slideImages1[0].text }}
                    </div>
                </div>
            </div>
            <div class="image14-container padded-container parallax-in">
                <img class="image14" src="../../assets/design/google-ar/image14.png" alt="archive figure 2">
                <div class="archive-caption">
                    <div>
                        Fig 2:
                    </div>
                    <div class="mobile">
                        The chosen concept was further refined considering product architecture, tech housing, and design
                        details of the frame.
                    </div>
                    <div class="desktop">
                        <div>
                            The chosen concept was further refined considering product
                        </div>
                        <div>
                            architecture, tech housing, and design details of the frame.
                        </div>
                    </div>
                </div>
            </div>
            <div class="archive-sub-3 carousel-section parallax-in">
                <Carousel :itemsToShow="1.5" :wrapAround="true" :transition="300" v-model="currentSlide2">
                    <Slide @click="moveSlide2" v-for="  (      slide, index      )   in         slideImages2        "
                        :key="index">
                        <img class="archive-images" :src="require(`../../assets/design/google-ar/${slide.src}.png`)"
                            :alt="`slide_${index}`">
                    </Slide>
                </Carousel>
                <div class="carousel-caption padded-container">
                    <div v-if="currentSlide2 < 3" class="archive-caption">
                        {{ slideImages2[currentSlide2].text }}
                    </div>
                    <div v-else class="archive-caption">
                        {{ slideImages2[0].text }}
                    </div>
                </div>
            </div>
            <div class="common-footer parallax-in">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'GoogleARGlasses',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            slideImages1: [
                {
                    src: 'slide1',
                    text: 'silhouette from development and details exploration'
                },
                {
                    src: 'slide2',
                    text: 'landing on a design concept with a strong silhouette that works for most face types. The concept tries to use a black CMF to stay accessible for different occasions, with hints of color to indicate tech and interaction points'
                },
                {
                    src: 'slide3',
                    text: 'silhouette from development and details exploration'
                }
            ],
            slideImages2: [
                {
                    src: 'slide4',
                    text: 'CAD V1'
                },
                {
                    src: 'slide5',
                    text: 'CAD V2'
                },
                {
                    src: 'slide6',
                    text: ''
                },
            ],
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
            currentSlide1: 0,
            currentSlide2: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },
        moveSlide1() {
            this.currentSlide1++;
        },
        moveSlide2() {
            this.currentSlide2++;
        },
        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.google-ar {
    .landing-section-mobile {
        background: url('~@/assets/design/google-ar/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/google-ar/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F7F5F2;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    .section-1 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }

        text-align: center;

        .image1 {
            width: 70%;
        }
    }

    .section-2 {
        margin-top: 42px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 52px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 52px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 62px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 72px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 110px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 213px;
        }
    }

    .section-3 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 359px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }
    }

    .section-4 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 206px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 266px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 315px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 342px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 420px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 790px;
        }

        .image2 {
            width: 100%;
        }

        .image3 {
            width: 100%;
            margin-top: 90px;

            @media only screen and (min-width: 992px) {
                margin-top: 125px;
            }

            @media only screen and (min-width: 1200px) {
                margin-top: 162px;
            }

            @media only screen and (min-width: 1500px) {
                margin-top: 190px;
            }

            @media only screen and (min-width: 1700px) {
                margin-top: 220px;
            }

            @media only screen and (min-width: 2000px) {
                margin-top: 250px;
            }

            @media only screen and (min-width: 3839px) {
                margin-top: 480px;
            }
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image4 {
            width: 100%;
        }
    }

    .section-6 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 150px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image5 {
            width: 100%;
        }
    }

    .section-7 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image6,
        .image7 {
            width: 100%;
        }
    }

    .section-8 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 194px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image8,
        .image9 {
            width: 100%;
            height: 100%;
        }
    }

    .section-9 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 194px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .image10 {
        width: 100%;
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 154px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .image11 {
        width: 100%;
        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }
    }

    .image12 {
        width: 100%;
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;

        @media only screen and (min-width: 992px) {
            margin-top: 125px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 180px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 194px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }
    }

    .archive-section {
        margin-top: -5px;

        .image13-container {
            text-align: center;
            margin: 90px auto 0 auto;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin: 125px auto 0 auto;
            }

            @media only screen and (min-width: 1200px) {
                margin: 162px auto 0 auto;
            }

            @media only screen and (min-width: 1500px) {
                margin: 190px auto 0 auto;
            }

            @media only screen and (min-width: 1700px) {
                margin: 229px auto 0 auto;
            }

            @media only screen and (min-width: 2000px) {
                margin: 250px auto 0 auto;
            }

            @media only screen and (min-width: 3839px) {
                margin: 480px auto 0 auto;
            }

            .archive-caption {

                margin: 20px auto 0 auto;

                @media only screen and (min-width: 992px) {
                    width: 50%;
                    margin: 24px auto 0 auto;
                }

                @media only screen and (min-width: 1200px) {
                    margin: 28px auto 0 auto;
                }

                @media only screen and (min-width: 1500px) {
                    margin: 36px auto 0 auto;
                }

                @media only screen and (min-width: 1700px) {
                    margin: 46px auto 0 auto;
                }

                @media only screen and (min-width: 2000px) {
                    margin: 66px auto 0 auto;
                }

                @media only screen and (min-width: 3839px) {
                    margin: 126px auto 0 auto;
                }
            }
        }

        .image13 {
            width: 100%;

            @media only screen and (min-width: 992px) {
                width: 50%
            }
        }

        .archive-sub-2 {
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;
            .archive-images {
                // height: 634px;
                width: 100%; //1700px


                @media only screen and (min-width: 992px) {
                    height: 634px;
                    object-fit: cover;
                }
            }

            .carousel-caption {
                margin: 20px 0 0 0;
                height: 80px;

                @media only screen and (min-width: 992px) {
                    width: 449px; //1700px
                    height: 200px;
                    margin: 50px 253px 0 auto;
                }
            }
        }

        .image14-container {
            text-align: center;
            margin: 90px auto 0 auto;
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;

            @media only screen and (min-width: 992px) {
                margin: 125px auto 0 auto;
            }

            @media only screen and (min-width: 1200px) {
                margin: 162px auto 0 auto;
            }

            @media only screen and (min-width: 1500px) {
                margin: 190px auto 0 auto;
            }

            @media only screen and (min-width: 1700px) {
                margin: 229px auto 0 auto;
            }

            @media only screen and (min-width: 2000px) {
                margin: 250px auto 0 auto;
            }

            @media only screen and (min-width: 3839px) {
                margin: 480px auto 0 auto;
            }

            .archive-caption {
                margin: 20px auto 0 auto;

                @media only screen and (min-width: 992px) {
                    width: 70%;
                    margin: 24px auto 0 auto;
                }

                @media only screen and (min-width: 1200px) {
                    margin: 28px auto 0 auto;
                }

                @media only screen and (min-width: 1500px) {
                    margin: 36px auto 0 auto;
                }

                @media only screen and (min-width: 1700px) {
                    margin: 46px auto 0 auto;
                }

                @media only screen and (min-width: 2000px) {
                    margin: 66px auto 0 auto;
                }

                @media only screen and (min-width: 3839px) {
                    margin: 126px auto 0 auto;
                }
            }

            .image14 {
                width: 100%;

                @media only screen and (min-width: 992px) {
                    width: 70%;
                }
            }
        }

        .archive-sub-3 {
            opacity: 0;
            transform: translateY(10%);
            transition: 0.8s all ease-out;
            .archive-images {
                // height: 664px; //1700px
                // object-fit: cover;
                // @media only screen and (min-width: 992px) {
                //     height: 664px;
                // }
                width: 100%;
            }

            .carousel-caption {
                margin: 20px 0 0 0;
                height: 80px;

                @media only screen and (min-width: 992px) {
                    width: 80px; //1700px
                    height: 200px;
                    margin: 50px 343px 0 auto;
                }
            }
        }
    }

}</style>