<template>
    <div class="cnn case-study">
        <div class="landing-section-mobile">
            <div class="page-title">
                <div>
                    Warner
                </div>
                <div>
                    Media
                </div>
            </div>
        </div>
        <div class="landing-section">
            <div class="page-title">
                Warner Media
            </div>
        </div>
        <div class="case-content padded-container">
            <div class="intro-section">
                <div class="intro-label">
                    <div class="label">
                        (01) The Ask
                    </div>
                    <div class="desktop-labels label">
                        <div class="role">
                            <div>
                                Role:
                            </div>
                            <div>
                                Lead Research
                            </div>
                            <div>
                                Interaction Design
                            </div>
                        </div>
                        <div class="year">
                            <div>
                                Year:
                            </div>
                            <div>
                                2023
                            </div>
                        </div>
                        <div class="team">
                            <div>
                                Team:
                            </div>
                            <div>
                                Damian Wolfgram
                            </div>
                            <div>
                                Nathan Portlock
                            </div>
                            <div>
                                Sharvil G
                            </div>
                            <div>
                                Charmin Vemula
                            </div>
                            <div>
                                Gokul Beeda
                            </div>
                        </div>
                        <div class="team">
                            <div>
                                Client:
                            </div>
                            <div>
                                Warner Media
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-text">
                    <div class="title">
                        Develop a playbook and experimental concepts that help CNN understand Gen-Z news expectations and
                        habits.
                    </div>
                    <div @click="showInfo1 = !showInfo1;" class="readmore relative">
                        <span v-if="showInfo1">close</span><span v-else>read more</span><img
                            :class="{ openInfo: showInfo1 }" class="add-sign absolute" src="../../assets/add.svg" alt="add">
                    </div>
                    <Transition name="slide-down">
                        <div v-show="showInfo1" class="additional-info">
                            <div class="para-spacer sub-text">
                                With CNN's audience aging and the increasing importance of the Digital Native demographic,
                                there is a significant opportunity to re think the news experience to get their attention
                                and meet their evolving news consumption needs. By understanding their behaviours, decision
                                drivers, and expectations, CNN wanted us to create an organised and engaging digital
                                experiments that appeals to both Digital Natives and traditional audiences, positioning CNN
                                as a credible and accessible news source.
                            </div>
                        </div>
                    </Transition>
                </div>

            </div>
            <div class="overview-section">
                <div class="label">
                    (02) Phase One
                </div>
                <div class="overview-description mobile">
                    EXTENSIVE RESEARCH PROCESS THAT HELPED CNN <span class="color-text">IDENTIFY EMERGING GEN-Z BEHAVIOURS
                        AND OPPORTUNITY AREAS</span> IN
                    THE DIGITAL MEDIA LANDSCAPE.
                </div>
                <div class="overview-description desktop">
                    <div>
                        EXTENSIVE RESEARCH PROCESS
                    </div>
                    <div>
                        THAT HELPED CNN <span class="color-text">IDENTIFY</span>
                    </div>
                    <div class="color-text">
                        EMERGING GEN-Z BEHAVIOURS AND
                    </div>
                    <div>
                        <span class="color-text">OPPORTUNITY AREAS</span> IN THE
                    </div>
                    <div>
                        DIGITAL MEDIA LANDSCAPE.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-1 padded-container parallax-in">
            <div class="label">
                (03) Methodology
            </div>
            <div class="image-container">
                <img class="image1" src="../../assets/design/cnn/image1.png" alt="Methodology">
            </div>
            <div class="left-content-section">
                <div class="sub-text ">
                    A pictorial representation about the relation between trends, grassroots change and domains(Retail,
                    Fashion, Healthcare etc..) This approach helped us identify emerging behaviours and also understand the
                    drivers of change that build a trend
                </div>
            </div>
        </div>
        <div class="section-2 padded-container parallax-in">
            <div class="label">
                (04) Phase Two
            </div>
            <div class="title">
                <div>
                    The Playbook -
                </div>
                <div>
                    holds strategies, methodologies, case study examples, and experience-based tips and guidance for every
                    part of Gen-Z news consumption behavior. It also has insights into the impact of the latest
                    technologies, new news delivery methods, and emerging behaviors gracing the scene.
                </div>
            </div>
        </div>
        <div class="section-3 padded-container parallax-in">
            <div class="image-container">
                <img class="image2" src="../../assets/design/cnn/image2.png" alt="Fig 1">
            </div>
            <div class="image-caption">
                <div>
                    Fig 1:
                </div>
                <div class="mobile">
                    The section on the news landscape in the playbook delves into key insights regarding how evolving trends
                    have transformed the making, distributing, and consuming of news among Gen-Z adults
                </div>
                <div class="desktop">
                    <div>
                        The section on the news landscape in the playbook delves into
                    </div>
                    <div>
                        key insights regarding how evolving trends have transformed the
                    </div>
                    <div>
                        making, distributing, and consuming of news among Gen-Z adults
                    </div>
                </div>
            </div>
        </div>
        <div class="section-4 padded-container parallax-in">
            <div class="title">
                <div>
                    Experiments-
                </div>
                <div>
                    Our challenge was to develop experimental concepts that retain elements of legacy media while leveraging
                    the future-forward ethos of Gen-Z. All the design concepts focused on core digital experiences that
                    augment the emergent behaviors Gen-Zs have.
                </div>
            </div>
        </div>
        <div class="section-5 padded-container parallax-in">
            <div class="image-container">
                <img class="image3" src="../../assets/design/cnn/image3.png" alt="Fig 2">
            </div>
            <div class="image-caption">
                <div>
                    Fig 2:
                </div>
                <div class="mobile">
                    We tested multiple “digital experience” concepts that focused on new interactions patterns and ways to
                    consume news on mobile and desktop.
                </div>
                <div class="desktop">
                    <div>
                        We tested multiple “digital experience” concepts
                    </div>
                    <div>
                        that focused on new interactions patterns and
                    </div>
                    <div>
                        ways to consume news on mobile and desktop.
                    </div>
                </div>
            </div>
        </div>
        <div class="section-6 archive-section parallax-in">
            <div class="common-footer">
                <div class="footer-nav d-flex">
                    <div class="nav-links navlink1" @click=" this.$router.push('/design')">
                        design
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink2" @click=" this.$router.push('/folk')">
                        folk
                    </div>
                    <div class="separator">
                        <img src="@/assets/separator.png" alt="separator">
                    </div>
                    <div class="nav-links navlink3" @click=" this.$router.push('/lore')">
                        lore
                    </div>
                </div>
                <div class="footer-info d-flex">
                    <div>
                        Copyright <img class="copyright" src="@/assets/design/copyright.png" alt="copyright"> 2023 BGRY.
                    </div>
                    <div>
                        Made in Andhra Pradesh
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: 'CNN',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data: function () {
        return {
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            displayWidth: 0,
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'instant' });
        this.displayWidth = window.innerWidth;
        this.showInfoHandler();
        window.addEventListener('resize', this.showInfoHandler);

        this.parallaxElements = Array.from(document.getElementsByClassName('parallax-in'));
        if (this.getScrollPercent() < 5) {
            document.addEventListener('scroll', this.handleScroll);
        } else {
            setTimeout(() => {
                document.addEventListener('scroll', this.handleScroll);
            }, 2000);
        }
    },
    methods: {
        showInfoHandler() {
            this.displayWidth = window.innerWidth;
            if (this.displayWidth > '992') {
                this.showInfo1 = true;
                this.showInfo2 = true;
                this.showInfo3 = true;
            } else {
                this.showInfo1 = false;
                this.showInfo2 = false;
                this.showInfo3 = false;
            }
        },

        handleScroll(evt) {
            for (var i = 0; i < this.parallaxElements.length; i++) {
                var elem = this.parallaxElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = '1'
                    elem.style.transform = 'scale(1)'
                    this.parallaxElements.splice(i, 1) // only allow it to run once
                }
            }
            if (this.getScrollPercent() > 10) {
                this.showScrollTop = true;
            }

            if (this.getScrollPercent() < 1) {
                this.showScrollTop = false;
            }
        },
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 200 // 200 = buffer
            var elemBottom = rect.bottom
            return elemTop < window.innerHeight && elemBottom >= 0
        },
        getScrollPercent() {
            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        },
    },
}
</script>
<style lang=less scoped>
@import "CaseStudiesCommon.less";

.cnn {
    .landing-section-mobile {
        background: url('~@/assets/design/cnn/banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 97.2%;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
        }
    }

    .landing-section {
        display: none;

        @media only screen and (min-width: 992px) {
            background: url('~@/assets/design/cnn/banner_large.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100vh;
            width: 100%;
            position: relative;
            display: block;
        }

        .page-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Suisse International Condensed';
            font-style: normal;
            font-weight: 700;
            font-size: 74px;
            line-height: 98.7%;
            letter-spacing: -1.869px;
            text-align: center;
            text-transform: uppercase;
            color: #F4F2EE;
            width: 100%;

            @media only screen and (min-width: 1200px) {
                font-size: 96px;
                line-height: 98.7%;
                letter-spacing: -2.413px;
            }

            @media only screen and (min-width: 1500px) {
                font-size: 114px;
            }

            @media only screen and (min-width: 1700px) {
                font-size: 130px;
            }

            @media only screen and (min-width: 2000px) {
                font-size: 154px;
            }

            @media only screen and (min-width: 3839px) {
                font-size: 288px;
            }
        }
    }

    & .overview-section .overview-description .color-text {
        color: #914E51;
    }

    .section-1 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 65%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image1 {
            width: 100%;
        }

        .left-content-section {
            width: fit-content;
            margin-left: auto;
            margin-top: 20px;

            @media only screen and (min-width: 992px) {
                width: 335.318px;
                margin-top: 40px;
            }

            @media only screen and (min-width: 1200px) {
                width: 433.011px;
            }

            @media only screen and (min-width: 1500px) {
                width: 625.924px;
            }

            @media only screen and (min-width: 1700px) {
                width: 557px;
            }

            @media only screen and (min-width: 2000px) {
                width: 845.663px;
            }

            @media only screen and (min-width: 3839px) {
                width: 1295.871px;
            }
        }

    }

    .section-2 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 96px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 124px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 146px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 160px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 198px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 371px;
        }
    }

    .section-3 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image2 {
            width: 100%;
        }
    }

    .section-4 {
        margin-top: 170px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 258px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }
    }

    .section-5 {
        margin-top: 90px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 125px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 162px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 220px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 250px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 480px;
        }

        .image3 {
            width: 100%;
        }
    }

    .section-6 {
        background: url('~@/assets/design/cnn/image4.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
        position: relative;

        margin-top: 130px;
        opacity: 0;
        transform: translateY(10%);
        transition: 0.8s all ease-out;
        @media only screen and (min-width: 992px) {
            margin-top: 140px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 190px;
        }

        @media only screen and (min-width: 1500px) {
            margin-top: 228px;
        }

        @media only screen and (min-width: 1700px) {
            margin-top: 298px;
        }

        @media only screen and (min-width: 2000px) {
            margin-top: 306px;
        }

        @media only screen and (min-width: 3839px) {
            margin-top: 548px;
        }
    }

    .common-footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        margin-top: 0;
    }
}
</style>