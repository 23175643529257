<template>
    <div class="under-container d-flex">
        <div>   
            <img class="bob-img" src="../assets/bob.png" alt="bob the builder">
        </div>
        <div class="under-title">
            <div class="under-text">
                UNDER CONSTRUCTION
            </div>
            <div class="under-subtext">
                Please reach out to me at beeda.gokul@gmail for my professional work sample.  You can download my 2021 portfolio here. 
            </div>
        </div>
    </div>
</template>

<script>

export default {
	name: 'LoadingView',
	components: {},
	data: function () {
		return {
			// showHardware: false,
		}
	},
	methods: {
		// nextImg(move) {
		// },
	},
}
</script>
<style lang=less>
@import "@/styles/colors.less"; 
.under-container {
    height: calc(100vh - 102px);
    width: 100%;
    justify-content: center;
    align-items: center;
    .under-title {
        margin-left: 72px;
    }
    .under-text {
        font-weight: 600;
        font-size: 70px;
        line-height: 92.2%;
        width: 450px;
        text-align: left;
    }
    .under-subtext {
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.005em;
        width: 450px;
        margin-top: 13px;
        text-align: left;
    }
    .bob-img {
        width: 340px;
        height: 400px;
    }
}
</style>